import { Box, IconButton } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import React, { useState } from "react";
import { AnimationControls, VariantLabels, motion } from "framer-motion";
import Button from "./Button";
import Modal from "./Modal";

export default function QuickDateSelector({ disabled = false, setstartDate, setendDate }) {
    const [show, setshow] = useState(false);

    const today = () => {
        const cdate = new Date();
        const year = cdate.getFullYear();
        const month = String(cdate.getMonth() + 1).padStart(2, "0");
        const day = String(cdate.getDate()).padStart(2, "0");
        const date = `${year}-${month}-${day}`;
        setstartDate(date);
        setendDate(date);
        setshow(false);
    };

    return (
        <>
            <IconButton
                color="primary"
                disabled={disabled}
                onClick={() => {
                    setshow(!show);
                }}
            >
                <MoreVertOutlinedIcon />
            </IconButton>
            <Modal title="Quick Date Selector" show={show} setshow={setshow} width="40rem" height="30rem">
                <div className="flex flex-col p-2">
                    <div className="flex flex-row">
                        <Button onClick={today}>Today</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
