import AdvancedTable from "../../app/AdvancedTable";
import Modal from "../../app/Modal";
import React from "react";
/**
 * Renders a modal for selecting filters from a list of options.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Whether the modal should be shown.
 * @param {string} props.selectedFilter - The name of the selected filter.
 * @param {function} props.setshow - Function to set the show state of the modal.
 * @param {Array<Object>} props.options - The list of options to choose from.
 * @param {Array<Object>} props.selectedOptions - The currently selected options.
 * @param {function} props.setSelectedOptions - Function to set the selected options.
 * @returns {JSX.Element|null} The FilterSelectionModal component.
 */
export default function FilterSelectionModal({
    show,
    setshow,
    options,
    selectedOptions,
    setSelectedOptions,
    selectedFilter,
}) {
    if (options.length === 0) {
        return null;
    }
    let columns = [];
    if (options[selectedFilter].length > 0) {
        columns = Object.keys(options[selectedFilter][0]).map((e) => {
            return { field: e, headerName: e, flex: 1, selectable: true };
        });
    }

    return (
        <Modal show={show} setshow={setshow} title={`Select Filters "${selectedFilter}"`}>
            <AdvancedTable
                rows={options[selectedFilter]}
                cols={columns}
                getRowId={(e) => {
                    if (options[selectedFilter].length > 0) {
                        return e[
                            Object.keys(options[selectedFilter][0])[
                                ["inv_type", "vch_type", "type"].includes(selectedFilter) ? 1 : 0
                            ]
                        ];
                    }
                    return 0;
                }}
                checkboxSelection={true}
                isRowSelectable={(e) => {
                    return true;
                }}
                rowSelectionModel={selectedOptions[selectedFilter]}
                onRowSelectionModelChange={(e) => {
                    setSelectedOptions({ ...selectedOptions, [selectedFilter]: e });
                }}
            />
        </Modal>
    );
}
