import React, { useState } from "react";
import Modal from "../../components/app/Modal";
import DataTable from "../../components/BackOffice/TrialBalance/dataTable";
import AccountsFilters from "../../components/BackOffice/TrialBalance/AccountsFilters";
import dayjs from "dayjs";
import DataGridModal from "../../components/app/DataGridModal";
import AccountProfile from "../../components/BackOffice/AccountProfile/AccountProfile";
import StatementOfAccount from "./StatementOfAccount";
import TextField from "../../components/app/TextField";
import Button from "../../components/app/Button";
import ReportModal from "../../components/PrintModal/ReportModal/ReportModal";
import { getJsonStorage } from "../../components/app/globalfunctions";
import { useMemo } from "react";
import { useLanguage } from "../../components/app/LanguageContext";
import OtherReportModal from "../../components/BackOffice/TrialBalance/OtherReportsModal";
import { useGridApiRef } from "@mui/x-data-grid";
const currentYear = new Date().getFullYear();

export default function TrailBalance(props) {
    const { lang } = useLanguage();
    const [filter1, setfilter1] = useState("");
    const [con1, setcon1] = useState("Start With");

    const [filter2, setfilter2] = useState("");
    const [con2, setcon2] = useState("Start With");

    const [filter3, setfilter3] = useState("");
    const [con3, setcon3] = useState("Start With");

    const [filter4, setfilter4] = useState("");
    const [con4, setcon4] = useState("Start With");

    const [tempAcc, settempAcc] = useState("");
    const [tempNum, settempNum] = useState("");

    const [startDate, setstartDate] = useState(dayjs(new Date(currentYear, 0, 1)).format("YYYY-MM-DD"));
    const [endDate, setendDate] = useState(dayjs(new Date(currentYear, 11, 31)).format("YYYY-MM-DD"));

    const [accountProfileModal, setaccountProfileModal] = useState(false);

    const lists = useMemo(() => getJsonStorage("settings").lists, []);
    const vchTypes = lists.vch_types;
    const branchList = lists.branches;
    const smanList = lists.sman;
    const jobList = lists.jobs;

    const [showDataGridModal, setshowDataGridModal] = useState(false);

    const [balances, setbalances] = useState([]);

    const [showPrintModal, setshowPrintModal] = useState(false);

    const [total, settotal] = useState({
        dbtotal1: 0,
        crtotal1: 0,
        balance1: 0,
        dbtotal2: 0,
        crtotal2: 0,
        balance2: 0,
    });

    const [rateCur, setrateCur] = useState({
        order: 2,
        code: "LBP",
        name: "Lebanease Pound",
        name2: "",
        symbol: "L.L",
        rate: 90000,
        primary: "",
        tax_rate: 15000,
        default_sale_price: 0,
    });
    const [priCur, setpriCur] = useState({
        order: 1,
        code: "USD",
        name: "United States Dollar",
        name2: "",
        symbol: "$",
        rate: 1,
        primary: "y",
        tax_rate: 0,
        default_sale_price: 0,
    });
    const [filters, setfilters] = useState({
        type: [],
        branch: [],
        sman: [],
        job: [],
    });

    const [allowEdit, setallowEdit] = useState(true);

    const gridApiRef = useGridApiRef();

    return (
        <>
            <Modal show={props.show} setshow={props.setshow} title={lang["TRIAL_BALANCE"]} height="100vh" width="100vw" borderRadius="0.2rem">
                <div className=" flex h-full w-full flex-row justify-center p-2">
                    <div className=" h-full  w-[25%]">
                        <AccountsFilters
                            settempNum={settempNum}
                            con1={con1}
                            setcon1={setcon1}
                            con2={con2}
                            setcon2={setcon2}
                            con3={con3}
                            setcon3={setcon3}
                            con4={con4}
                            setcon4={setcon4}
                            filter1={filter1}
                            setfilter1={setfilter1}
                            filter2={filter2}
                            setfilter2={setfilter2}
                            filter3={filter3}
                            setfilter3={setfilter3}
                            filter4={filter4}
                            setfilter4={setfilter4}
                            getData={getData}
                            startDate={startDate}
                            setstartDate={setstartDate}
                            endDate={endDate}
                            setendDate={setendDate}
                            resetFilters={resetFilters}
                            settempAcc={settempAcc}
                            setshowDataGridModal={setshowDataGridModal}
                            filters={filters}
                            setfilters={setfilters}
                            filterOptions={{
                                type: vchTypes,
                                branch: branchList,
                                sman: smanList,
                                job: jobList,
                            }}
                            allowEdit={allowEdit}
                            setallowEdit={setallowEdit}
                        />
                    </div>
                    <div className=" flex w-[75%] flex-col ">
                        <DataTable
                            rows={balances}
                            setaccountProfileModal={setaccountProfileModal}
                            settempAcc={settempAcc}
                            setShowStatementOfAccount={props.setShowStatementOfAccount}
                            rateCur={rateCur}
                            priCur={priCur}
                            gridApiRef={gridApiRef}
                        />
                        <div className=""></div>
                        <div className="my-2 flex h-[6rem] flex-row items-center  gap-8 ">
                            <div className="flex flex-col gap-3 ">
                                <div className="flex flex-row gap-4">
                                    <TextField
                                        size="small"
                                        label={`Debit Total ${priCur.code}`}
                                        value={total.dbtotal1.toLocaleString() + " " + priCur.symbol}
                                        id={"dbtotal"}
                                    />
                                    <TextField
                                        size="small"
                                        label={`Credit Total ${priCur.code}`}
                                        value={total.crtotal1.toLocaleString() + " " + priCur.symbol}
                                        id={"crtotal"}
                                    />
                                    <TextField
                                        size="small"
                                        label={`Balance ${priCur.code}`}
                                        value={total.balance1.toLocaleString() + " " + priCur.symbol}
                                        id={"crtotal"}
                                    />
                                </div>
                                <div className="flex flex-row gap-4">
                                    <TextField
                                        size="small"
                                        label={`Debit Total ${rateCur.code}`}
                                        value={total.dbtotal2.toLocaleString() + " " + rateCur.symbol}
                                        id={"dbtotal"}
                                    />
                                    <TextField
                                        size="small"
                                        label={`Credit Total ${rateCur.code}`}
                                        value={total.crtotal2.toLocaleString() + " " + rateCur.symbol}
                                        id={"crtotal"}
                                    />
                                    <TextField
                                        size="small"
                                        label={`Credit Total ${rateCur.code}`}
                                        value={total.balance2.toLocaleString() + " " + rateCur.symbol}
                                        id={"crtotal"}
                                    />
                                </div>
                            </div>
                            <div className="flex h-full flex-col justify-evenly">
                                <Button
                                    onClick={() => {
                                        setshowPrintModal(true);
                                    }}
                                    variant="outlined"
                                    sx={{ whiteSpace: "nowrap" }}
                                    disabled={balances.length === 0}
                                >
                                    Print Report
                                </Button>
                                <Button
                                    onClick={() => {
                                        gridApiRef.current.exportDataAsCsv();
                                    }}
                                    variant="outlined"
                                    sx={{ whiteSpace: "nowrap" }}
                                    disabled={balances.length === 0}
                                >
                                    Export As CSV
                                </Button>
                                {/* <OtherReportModal isDisabled={balances.length === 0} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {showDataGridModal ? (
                <DataGridModal
                    show={showDataGridModal}
                    setShow={setshowDataGridModal}
                    modalTitle={"Similar Account Id / Account Name 1 / Account Name 2 / Account Currency "}
                    searchPhrase={tempAcc}
                    quary={`id as 'Acc Id',name1 as "Name 1",name2 as "Name 2",tax_number as "Tax Number",\`cur\` as "Currency",category as "Category" FROM ${localStorage.getItem(
                        "dbname"
                    )}.accounts where ((\`id\` like '${tempAcc}%' ) or tax_number like '%${tempAcc}%' or (name1 like '%${tempAcc}' or name1 like '${tempAcc}%' or name1 like '%${tempAcc}%') or (name2 like '%${tempAcc}' or name2 like '${tempAcc}%' or name2 like '%${tempAcc}%') or (cur like '%${tempAcc}' or cur like '${tempAcc}%' or cur like '%${tempAcc}%') ) and active = 'Y' `}
                    postdata={props.postdata}
                    onEnterPress={(newvalue) => {
                        if (tempNum === 1) {
                            setfilter1(newvalue);
                        } else if (tempNum === 2) {
                            setfilter2(newvalue);
                        } else if (tempNum === 3) {
                            setfilter3(newvalue);
                        } else if (tempNum === 4) {
                            setfilter4(newvalue);
                        }
                    }}
                    onHideFunc={() => {}}
                />
            ) : null}
            {accountProfileModal ? (
                <AccountProfile
                    modalShow={accountProfileModal}
                    setModalShow={setaccountProfileModal}
                    postdata={props.postdata}
                    selectedAcc={{ row: { id: tempAcc } }}
                    onHideFunc={() => {}}
                />
            ) : null}

            {showPrintModal ? (
                <ReportModal
                    showPrintModal={showPrintModal}
                    setshowPrintModal={setshowPrintModal}
                    data={{
                        balances: balances,
                        total: total,
                        filters: {
                            fromDate: startDate.toString().split("T")[0],
                            toDate: endDate.toString().split("T")[0],
                            type: filters.type,
                            branch: filters.branch,
                            sman: filters.sman,
                            job: filters.job,
                        },
                        account_id: {
                            filter1: filter1,
                            con1: con1,
                            filter2: filter2,
                            con2: con2,
                            filter3: filter3,
                            con3: con3,
                            filter4: filter4,
                            con4: con4,
                        },
                        rateCur: rateCur,
                        priCur: priCur,
                    }}
                    reportId={"trial_balance"}
                    postdata={props.postdata}
                    onPrintFunc={() => {}}
                />
            ) : null}
        </>
    );
    function getData() {
        let url = "accounts/trial_balance/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setbalances(res.data.data.balances);
                settotal(res.data.data.total);
                setrateCur(res.data.data.rateCur);
                setpriCur(res.data.data.priCur);
                setallowEdit(false);
            }
        };
        let fdata = {
            startDate: startDate,
            endDate: endDate,
            account_id: [
                {
                    value: filter1,
                    con: con1,
                },
                {
                    value: filter2,
                    con: con2,
                },
                {
                    value: filter3,
                    con: con3,
                },
                {
                    value: filter4,
                    con: con4,
                },
            ],

            filters: filters,
        };
        props.postdata(url, cbnf, fdata, false);
    }

    function resetFilters() {
        setstartDate(dayjs(new Date(currentYear, 0, 1)).format("YYYY-MM-DD"));
        setendDate(dayjs(new Date(currentYear, 11, 31)).format("YYYY-MM-DD"));

        setfilter1("");
        setcon1("Start With");

        setfilter2("");
        setcon2("Start With");

        setfilter3("");
        setcon3("Start With");

        setfilter4("");
        setcon4("Start With");

        settotal({
            dbtotal1: 0,
            crtotal1: 0,
            balance1: 0,
            dbtotal2: 0,
            crtotal2: 0,
            balance2: 0,
        });
        setbalances([]);
        setfilters({
            type: [],
            branch: [],
            sman: [],
            job: [],
        });
        setallowEdit(true);
    }
}
