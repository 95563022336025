import { DataGrid } from "@mui/x-data-grid";
import React, { Component, useState, memo } from "react";
import TextField from "./TextField";
import rightarrow from "../../media/rightarrow.png";
import leftarrow from "../../media/leftarrow.png";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";

/**
 * A table component with advanced features.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {Array} props.rows - The rows to be displayed in the table.
 * @param {Array} props.cols - The columns to be displayed in the table.
 * @param {Function} props.getRowId - A function that returns the unique ID for each row.
 * @param {number} [props.columnHeaderHeight=45] - The height of the column headers.
 * @param {number} [props.rowHeight=35] - The height of each row.
 * @param {Function} [props.onFieldEdit=(selectedRow, newValue) => {}] - A function that is called when a field is edited.
 * @param {Function} [props.onCellDoubleClick=(e) => {}] - A function that is called when a cell is double-clicked.
 * @param {boolean} [props.disableColumnSelector=false] - Whether to disable the column selector.
 * @param {Function} [props.onRowUpdateError=(e) => {}] - A function that is called when there is an error updating a row.
 * @param {Function} [props.onCellKeyDown=(e, f) => {}] - A function that is called when a key is pressed down in a cell.
 * @param {Function} [props.onCellEditStart=() => {}] - A function that is called when a cell is edited.
 * @param {Function} [props.onCellClick=(e) => {}] - A function that is called when a cell is clicked.
 * @param {Function} [props.processRowUpdate=(e, selectedRow) => {}] - A function that is called when a row is updated.
 * @param {Function} [props.getRowClassName=(e) => {}] - A function that returns the class name for each row.
 * @param {Function} [props.onRowSelectionModelChange=(e) => {}] - A function that is called when the row selection model changes.
 * @param {Function} [props.isRowSelectable=(e) => {}] - A function that determines whether a row is selectable.
 * @param {boolean} [props.checkboxSelection=false] - Whether to enable checkbox selection.
 * @param {Array} [props.rowSelectionModel=[]] - The selected rows.
 * @param {boolean} [props.disableColumnMenu=true] - Whether to disable the column menu.
 * @param {number} [props.rowBuffer=100] - The number of rows to buffer.
 * @param {Object} [props.dbref] - A reference to the table's DOM node.
 * @param {boolean} [props.showFooter=false] - Whether to show the table footer.
 * @param {number} [props.selectedPage=1] - The currently selected page.
 * @param {Function} [props.setselectedPage=(selectedPage) => {}] - A function that sets the currently selected page.
 * @param {number} [props.rowCount] - The total number of rows.
 * @returns {JSX.Element} The AdvancedTable component.
 */
class AdvancedTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            selectedPage: 1,
        };
        this.dbref = React.createRef();
        this.gridApiRef = React.createRef();
    }

    setSelectedRow = (selectedRow) => {
        this.setState({ selectedRow });
    };
    setselectedPage = (selectedPage) => {
        this.setState({ selectedPage });
    };

    render() {
        const {
            rows,
            cols,
            getRowId,
            columnHeaderHeight = 45,
            rowHeight = 35,
            onFieldEdit = (selectedRow, newValue) => {},
            onCellDoubleClick = (e) => {},
            disableColumnSelector = false,
            onRowUpdateError = (e) => {},
            onCellKeyDown = (e, f) => {},
            onCellEditStart = () => {},
            onCellClick = (e) => {},
            processRowUpdate = (e, selectedRow) => {
                if (e[selectedRow.field] !== selectedRow.value) {
                    onFieldEdit(selectedRow, e[selectedRow.field]);
                }
                return e;
            },
            getRowClassName = (e) => {
                if (e.indexRelativeToCurrentPage % 2 !== 0) {
                    return "bg-[#0000001c]";
                }
            },
            onRowSelectionModelChange = (e) => {},
            isRowSelectable = (e) => {
                return false;
            },
            checkboxSelection = false,
            rowSelectionModel = [],
            disableColumnMenu = true,
            rowBuffer = 100,
            dbref = this.dbref,
            gridApiRef = this.gridApiRef,
            showFooter = false,
            selectedPage = this.state.selectedPage,
            setselectedPage = this.setselectedPage,
            rowCount,
            onRowClick = null,
            sx = {},
        } = this.props;

        if (dbref.current?.children[0]?.children[1]?.style?.visibility === "hidden") {
            setTimeout(() => {
                dbref.current.children[0].children[1].style.visibility = "visible";
            }, 50);
        }
        const fCount = rowCount ? rowCount : rows.length;
        console.log("table Re rendering");
        // return (
        //     <Box className="h-full w-full overflow-x-auto">
        //         <div className="h-[3rem] border-2 flex flex-row rounded-t border-gray-700">
        //             {cols.map((col) => {
        //                 return (
        //                     <div
        //                         style={{ width: col.width }}
        //                         className=" whitespace-nowrap overflow-hidden p-2 text-center border-r-2 border-gray-700"
        //                         key={col.field}
        //                     >
        //                         {col.headerName}
        //                     </div>
        //                 );
        //             })}
        //         </div>
        //         <div className="flex flex-col overflow-y-auto">
        //             {rows.slice(0, 100).map((row, ridx) => {
        //                 return (
        //                     <div className={`h-[2rem] border-[1px] border-gray-700 flex flex-row `} key={ridx}>
        //                         {cols.map((col, cidx) => {
        //                             return (
        //                                 <input
        //                                     style={{ width: col.width }}
        //                                     className=" whitespace-nowrap overflow-hidden p-2 text-center border-r-[1px] bg-zinc-800 text-[#c7c7c7] border-gray-700"
        //                                     key={cidx}
        //                                     onFocus={(e) => e.target.select()}
        //                                     defaultValue={row[col.field]}
        //                                     onKeyDownCapture={(e) => {
        //                                         console.log(e.target.selectionStart, e.target.selectionEnd);
        //                                     }}
        //                                 />
        //                             );
        //                         })}
        //                     </div>
        //                 );
        //             })}
        //         </div>
        //     </Box>
        // );
        return (
            <DataGrid
                sx={{
                    "& .MuiDataGrid-cell:focus": {
                        borderWidth: "1px !important",
                        borderColor: "#0d6efd !important",
                        outline: "3px auto #0d6efd",
                    },
                    "& .MuiDataGrid-withBorderColor": {
                        borderColor: "#333333", 
                    },
                    ...sx,
                }}
                rows={rowCount ? rows : rows.slice(selectedPage * 100 - 100, selectedPage * 100)}
                getRowClassName={(e) => getRowClassName(e)}
                columns={[
                    ...cols.map((col) => ({ ...col, sortable: col.sortable ?? false, editable: col.editable ?? false })),
                ].filter((e) => e.hidden !== true)}
                showColumnRightBorder
                checkboxSelection={checkboxSelection}
                columnHeaderHeight={columnHeaderHeight}
                rowHeight={rowHeight}
                ref={dbref}
                slotProps={{
                    cell: { id: "datagridcell" },
                    pagination: {
                        selectedPage: selectedPage,
                        setselectedPage: setselectedPage,
                        rowCount: rowCount || rows.length,
                        dbref: dbref,
                    },
                }}
                slots={{
                    pagination: CustomPagination,
                }}
                hideFooter={fCount <= 100 || showFooter}
                disableColumnMenu={disableColumnMenu}
                showColumnVerticalBorder
                showCellVerticalBorder
                onRowSelectionModelChange={(e) => onRowSelectionModelChange(e)}
                onCellEditStart={(e) => {
                    setTimeout(() => {
                        this.setSelectedRow(e);
                        onCellEditStart(e);
                    }, 100);
                }}
                onCellClick={(e) => {
                    setTimeout(() => {
                        this.setSelectedRow(e);
                        onCellClick(e);
                    }, 100);
                }}
                rowBuffer={rowBuffer}
                processRowUpdate={(e) => processRowUpdate(e, this.state.selectedRow)}
                onProcessRowUpdateError={onRowUpdateError}
                isRowSelectable={(e) => isRowSelectable(e)}
                getRowId={(e) => getRowId(e)}
                onCellDoubleClick={(e) => {
                    onCellDoubleClick(e);
                }}
                disableColumnSelector={disableColumnSelector}
                editMode="cell"
                experimentalFeatures={{ newEditingApi: true }}
                onCellKeyDown={onCellKeyDown}
                rowSelectionModel={rowSelectionModel}
                onRowClick={onRowClick}
                apiRef={gridApiRef}
            />
        );
    }
}
function CustomPagination({ selectedPage, setselectedPage, rowCount, dbref }) {
    const nopages = Math.ceil(rowCount / 100);
    const [temppage, settemppage] = useState(selectedPage);

    const confirmPage = (e) => {
        if (parseInt(e.target.value) !== parseInt(selectedPage)) {
            dbref.current.children[0].children[1].style.visibility = "hidden";
            const fpage = e.target.value <= nopages ? (e.target.value <= 0 ? 1 : e.target.value) : nopages;
            setselectedPage(fpage);
            settemppage(fpage);
        }
    };
    return (
        <div className="flex h-full w-full flex-row justify-between rounded p-1">
            <div className="flex flex-col justify-around gap-2">
                <div className="flex flex-row gap-2">
                    <div className=" font-semibold">Row Count:</div>
                    <div className=" ">{rowCount}</div>
                </div>
                <div className="flex flex-row gap-2">
                    <div className=" font-semibold">Page Size:</div>
                    <div className=" ">100</div>
                </div>
            </div>
            <div></div>
            <div className="flex flex-row items-center gap-5">
                <div className=" mx-2 flex flex-row items-center gap-2">
                    <div>Page :</div>
                    <div className="flex flex-row items-center gap-1">
                        <IconButton
                            style={{ width: "2.5rem", height: "2.5rem" }}
                            variant="text"
                            onClick={() => {
                                confirmPage({ target: { value: parseInt(selectedPage) - 1 } });
                                settemppage(parseInt(selectedPage) - 1);
                            }}
                        >
                            <img draggable={false} src={leftarrow} alt="" />
                        </IconButton>
                        <TextField
                            value={temppage}
                            onChange={(e) => {
                                settemppage(e.target.value);
                            }}
                            onBlur={confirmPage}
                            onKeyDownCapture={(e) => e.key === "Enter" && confirmPage(e)}
                            size="small"
                            sx={{ width: "6rem" }}
                            onFocus={(e) => e.target.select()}
                        />
                        <IconButton
                            style={{ width: "2.5rem", height: "2.5rem" }}
                            variant="text"
                            onClick={() => {
                                confirmPage({ target: { value: parseInt(selectedPage) + 1 } });
                                settemppage(parseInt(selectedPage) + 1);
                            }}
                        >
                            <img draggable={false} src={rightarrow} alt="" />
                        </IconButton>
                    </div>
                    <div>Of</div>
                    <div>{nopages}</div>
                </div>
            </div>
        </div>
    );
}

export default AdvancedTable;
