import React, { useState, useEffect } from "react";
import DataTable from "../../app/DataTable";
export default function UserPrivelegeSettings({ postdata }) {
    useEffect(() => {
        getData();
    }, []);
    const tableCols = [
        {
            field: "user",
            headerName: "User",
            editable: false,
            flex: 1,
        },
        {
            field: "route",
            headerName: "Route",
            editable: false,
            flex: 1,
        },
        {
            field: "name",
            headerName: "Name",
            editable: false,
            flex: 2.5,
        },
        {
            field: "is_allowed",
            headerName: "Is Allowed",
            editable: true,
            flex: 1,
        },
        {
            field: "filter",
            headerName: "Filter",
            editable: true,
            flex: 2,
        },
    ];

    const [data, setdata] = useState([]);

    return (
        <div className="flex h-full w-full  flex-col ">
            <div className="h-[80%] p-1">
                <DataTable
                    rows={data}
                    columns={tableCols}
                    getRowId={(e) => {
                        return e.user + e.route + e.name;
                    }}
                    onFieldEdit={editPrivilege}
                    hideFooter={false}
                />
            </div>
        </div>
    );
    function getData() {
        let url = "admins/select_user_privilege/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data);
            }
        };
        postdata(url, cbnf, {}, false);
    }
    function editPrivilege(selectedRow, newValue) {
        let url = "admins/update_user_privilege/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
            } else {
                getData();
            }
        };
        const fdata = {
            selectedRow: selectedRow,
            newValue: newValue,
        };
        postdata(url, cbnf, fdata, false);
    }
}
