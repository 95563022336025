import React, { useState } from "react";
import Button from "../../../app/Button";
import TextField from "../../../app/TextField";
import Modal from "../../../app/Modal";
import Box from "@mui/material/Box";
import Select from "../../../app/Select";

import XButton from "../../../app/XButton";

const editOptions = [
    { value: "type", label: "Type", isSelect: "Types" },
    { value: "set", label: "Set", isSelect: "Sets" },
    { value: "category", label: "Category", isSelect: "Categories" },
    { value: "unit", label: "Unit", isSelect: "Units" },
    { value: "brand", label: "Brand", isSelect: "Brands" },
    { value: "color", label: "Color", isSelect: "Colors" },
    { value: "family", label: "Family", isSelect: "Families" },
];

export default function QuickEditModal(props) {
    const [newValue, setnewValue] = useState(props.selectedRow.value);
    return (
        <>
            <Modal show={props.show} setshow={props.setShow} title="Quick Edit" width="35rem" height="15rem">
                <div className="flex h-full flex-col justify-between p-2">
                    <div className="flex flex-row items-center font-semibold">
                        <div className="m-2">Item Code:</div>
                        <div className="m-2">{props.selectedRow.row.itemcode}</div>
                    </div>
                    <div className="flex flex-row items-center justify-around">
                        <Select
                            value={props.selectedRow.field}
                            id="field"
                            label={"Field"}
                            onChange={(e) => {
                                props.setselectedRow((old) => {
                                    let temp = old;
                                    temp.field = e.target.value;
                                    return temp;
                                });
                                setnewValue(props.selectedRow.row[e.target.value]);
                            }}
                            options={editOptions}
                            menuValue={"value"}
                            menuLabels={["label"]}
                            size="large"
                            width={"40%"}
                        allowNone={false}
                        />

                        <div className=" w-[40%]">
                            {editOptions.map((el) => {
                                if (el.value === props.selectedRow.field) {
                                    if (el.isSelect) {
                                        return (
                                            <Select
                                                value={newValue || ""}
                                                label={el.isSelect}
                                                id="value"
                                                onChange={(e) => {
                                                    setnewValue(e.target.value);
                                                }}
                                                size="large"
                                                options={props.attributes[el.isSelect]}
                                                menuValue={"id"}
                                                width={"100%"}
                                                menuLabels={["id", "name"]}
                                                autoFocus={true}
                                            />
                                        );
                                    } else {
                                        return (
                                            <TextField
                                                key={el.value}
                                                autoFocus
                                                label="Value"
                                                value={newValue}
                                                onKeyDownCapture={(e) => {
                                                    if (e.key === "Enter") {
                                                        props.editItem(e.target.value);
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        props.editItem(e.target.value);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setnewValue(e.target.value);
                                                }}
                                            />
                                        );
                                    }
                                }
                                return null;
                            })}
                        </div>
                    </div>
                    <div className="flex flex-row justify-end">
                        <Button
                            onClick={() => {
                                props.editItem(newValue);
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
