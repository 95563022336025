import React, { useState, useRef } from "react";
import Button from "../../../app/Button";
import Modal from "../../../app/Modal";
import { AlertContext } from "../../../../providers/AlertProvider";
import LoadingSpinner from "../../../app/LoadingSpinner";

export default function GoodsSyncModal(props: TGoodsSyncModalProps) {
    const [showModal, setShowModal] = useState(false);

    const alertAPI = React.useContext(AlertContext);
    const [data, setData] = useState({
        id: 1,
        table: "goods",
        from: 4,
        to: null,
        last_sync_date: "",
        keys: "itemcode;",
    });
    const [loading, setLoading] = useState(false);
    return (
        <>
            <Button
                onClick={() => {
                    setShowModal(true);
                    getData();
                }}
            >
                Sync Goods
            </Button>
            <Modal
                show={showModal}
                setshow={() => {
                    setShowModal(false);
                }}
                title="Sync Goods"
                height="30vh"
                width="30vw"
            >
                <div className="flex h-full w-full flex-col items-center justify-center gap-5">
                    <div>
                        <div className=" text-xl">Last Sync Date:</div>
                        <div>{data.last_sync_date}</div>
                    </div>
                    <div>
                        <Button onClick={syncGoods}>Sync</Button>
                    </div>
                    <LoadingSpinner show={loading} />
                </div>
            </Modal>
        </>
    );

    function getData() {
        alertAPI.asyncPostData(
            "settings/get_sync",
            { table: "goods" },
            (res) => {
                if (res.data.info == "success") {
                    setData(res.data.data);
                }
            },
            false
        );
    }
    function syncGoods() {
        setLoading(true);
        alertAPI.asyncPostData(
            "settings/sync_data",
            { id: data.id },
            (res) => {
                if (res.data.info == "success") {
                    getData();
                    setLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            },
            true
        );
    }
}

type TGoodsSyncModalProps = any;
