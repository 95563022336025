import { Tabs as MuiTabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import React from "react";

export default function Tabs({ tabsList = [], value, setvalue, children, padding = "5px", boxBorder = "2px solid" }) {
    
    return (
        <div className="flex h-full w-full flex-col  ">
            <MuiTabs
                value={value}
                onChange={(f, e) => {
                    setvalue(e);
                }}
                variant="fullWidth"
                sx={{ backgroundColor: "tabs.primaryBG", borderRadius: "0.4rem" }}
            >
                {tabsList.map((el, idx) => {
                    return <Tab value={el.value} label={el.label} key={idx} />;
                })}
            </MuiTabs>
            <Box
                sx={{
                    height: "calc(100% - 69px)",
                    margin: "10px 7px",
                    backgroundColor: "tab.primaryBG",
                    border: boxBorder,
                    borderColor: "tab.borderColor",
                    borderRadius: "0.5rem",
                    p: padding,
                    color: "text.secondary",
                }}
            >
                {children}
            </Box>
        </div>
    );
}
