import { Button, ButtonBase, IconButton, TextField } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BuildTree from "./BuildTree";

import React from "react";
export default function MenuItemsView({
    data,
    setisSelected,
    handleConfigChange,
    editConfig,
    setselectedParent,
    setaddItemModal,
    setnewnum,
    setselectedElement,
    setremoveItemModal,
}) {
    return (
        <div className="flex h-full w-full flex-col items-center">
            <div className="flex w-full flex-row items-center gap-5">
                <div className="flex flex-row gap-2">
                    <IconButton
                        onClick={() => {
                            setisSelected(false);
                        }}
                    >
                        <ArrowBack className=" !text-[#c7c7c7]" />
                    </IconButton>
                </div>
                <div className="flex flex-row gap-2">
                    <div>Id:</div>
                    <div>{data.config.id}</div>
                </div>
                <div>
                    <TextField
                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                        inputProps={{ className: "px-2 py-1 ", sx: { color: "#c7c7c7 !important" } }}
                        size="small"
                        label={"Name"}
                        onChange={(e) => {
                            handleConfigChange("name", e.target.value);
                        }}
                        value={data.config.name}
                        onBlur={(e) => {
                            editConfig("name", e.target.value);
                        }}
                        id="confname"
                    />
                </div>
                <div>
                    <TextField
                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                        inputProps={{ className: "px-2 py-1 ", sx: { color: "#c7c7c7 !important" } }}
                        size="small"
                        label={"Column Count"}
                        value={data.config.column_count}
                        onChange={(e) => {
                            handleConfigChange("column_count", e.target.value);
                        }}
                        onBlur={(e) => {
                            editConfig("column_count", e.target.value);
                        }}
                        id="confcol"
                    />
                </div>
                <div>
                    <TextField
                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                        inputProps={{ className: "px-2 py-1 ", sx: { color: "#c7c7c7 !important" } }}
                        size="small"
                        label={"Row Count"}
                        value={data.config.row_count}
                        onChange={(e) => {
                            handleConfigChange("row_count", e.target.value);
                        }}
                        onBlur={(e) => {
                            editConfig("row_count", e.target.value);
                        }}
                        id="confrow"
                    />
                </div>
                <div>
                    <TextField
                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                        inputProps={{ className: "px-2 py-1 ", sx: { color: "#c7c7c7 !important" } }}
                        size="small"
                        label={"Font Size"}
                        value={data.config.font_size}
                        onChange={(e) => {
                            handleConfigChange("font_size", e.target.value);
                        }}
                        onBlur={(e) => {
                            editConfig("font_size", e.target.value);
                        }}
                        id="font_size"
                    />
                </div>
            </div>
            <div className="flex h-[69.5vh] w-full flex-col overflow-y-auto rounded bg-zinc-700">
                <div className="flex w-full flex-row items-center justify-center text-2xl">
                    <div>Menu Items:</div>
                    <IconButton
                        onClick={() => {
                            setselectedParent("menu");
                            setaddItemModal(true);
                            setnewnum(data.items.length + 1);
                        }}
                    >
                        <AddCircleOutlineOutlinedIcon className=" !text-[#6eff87]" />
                    </IconButton>
                </div>
                <div>
                    <BuildTree
                        children={data.items}
                        setselectedParent={setselectedParent}
                        setaddItemModal={setaddItemModal}
                        setnewnum={setnewnum}
                        setselectedElement={setselectedElement}
                        setremoveItemModal={setremoveItemModal}
                    />
                </div>
            </div>
        </div>
    );
}
