import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, ButtonBase, IconButton, TextField } from "@mui/material";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import React, { useState } from "react";

export default function BuildTree({
    children,
    setselectedParent,
    setaddItemModal,
    setnewnum,
    setselectedElement,
    setremoveItemModal,
}) {
    return (
        <>
            {children.map((el) => {
                return (
                    <div className=" ml-4 flex flex-col rounded p-2 ">
                        {el.itemcode === "" ? (
                            <Accordion style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}>
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <div className=" w-full font-semibold !text-[#c7c7c7]">
                                        <div className=" flex w-full flex-row items-center justify-between gap-2  text-lg ">
                                            <div className="flex flex-row items-center gap-2">
                                                <div className=" font-bold ">Sub-Menu-Title:</div>
                                                <div className=" font-semibold">{el.title}</div>
                                                <IconButton
                                                    onClick={() => {
                                                        setselectedParent(el.menu_id);
                                                        setaddItemModal(true);
                                                        setnewnum(el.children.length + 1);
                                                    }}
                                                >
                                                    <AddCircleOutlineOutlinedIcon className=" !text-[#6eff87]" />
                                                </IconButton>
                                            </div>
                                            <IconButton
                                                onClick={() => {
                                                    setselectedElement(el);
                                                    setremoveItemModal(true);
                                                }}
                                            >
                                                <RemoveCircleOutlineOutlinedIcon
                                                    id={el.title + el.num + el.parent}
                                                    className=" !text-[#ff6d6d] "
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div
                                        className="flex flex-col rounded-xl bg-gray-100 py-1"
                                        style={{ color: "#c7c7c7 !important", backgroundColor: "#474747" }}
                                    >
                                        <BuildTree
                                            children={el.children}
                                            setselectedParent={setselectedParent}
                                            setaddItemModal={setaddItemModal}
                                            setnewnum={setnewnum}
                                            setselectedElement={setselectedElement}
                                            setremoveItemModal={setremoveItemModal}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <div
                                className="flex h-[2.5rem] flex-row  items-center justify-between gap-4 rounded p-2 !text-[#c7c7c7] outline outline-gray-500 hover:outline-gray-400"
                                onMouseEnter={() => {
                                    document.getElementById(el.title + el.num + el.parent).style.visibility = "visible";
                                }}
                                onMouseLeave={() => {
                                    document.getElementById(el.title + el.num + el.parent).style.visibility = "hidden";
                                }}
                            >
                                <div className="flex flex-row items-center gap-8 ">
                                    <div className="flex flex-row gap-2">
                                        <div className="  font-semibold">Title:</div>
                                        <div className="">{el.title}</div>
                                    </div>
                                    <div className="flex flex-row gap-2">
                                        <div className="  font-semibold">Item Code:</div>
                                        <div className="">{el.itemcode}</div>
                                    </div>
                                </div>
                                <IconButton
                                    onClick={() => {
                                        setselectedElement(el);
                                        setremoveItemModal(true);
                                    }}
                                >
                                    <RemoveCircleOutlineOutlinedIcon
                                        id={el.title + el.num + el.parent}
                                        className=" !text-[#ff6d6d] "
                                        style={{ fontSize: "medium", visibility: "hidden" }}
                                    />
                                </IconButton>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
}
