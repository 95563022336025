import { Box } from "@mui/material";
import TextField from "../../app/TextField";
import React from "react";
export default function ItemData(props) {
    return (
        <Box
            className="flex h-full w-full flex-row justify-around gap-2 rounded p-2"
            sx={{ backgroundColor: "modal.secondaryBG" }}
        >
            <div className="flex h-full flex-col  items-center justify-around gap-2">
                <TextField
                    size="small"
                    label={`Stock Value By ${props.selectedBy}`}
                    value={props.data.stock_value.toLocaleString() + " " + props.selectedCur.symbol}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="OriginalNumber"
                />
                <TextField
                    size="small"
                    label={"Qty In From Purchase"}
                    value={props.data.purchase_qty}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="PI"
                />
                <TextField
                    size="small"
                    label={"Qty Out From Sales"}
                    value={props.data.sales_qty}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="SA"
                />
                <TextField
                    size="small"
                    label={"Qty In From Sales Return"}
                    value={props.data.sales_return_qty}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="SR"
                />
                <TextField
                    size="small"
                    label={"Qty Out From Purchase Return"}
                    value={props.data.purchase_return_qty}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="PR"
                />
                <TextField
                    size="small"
                    label={"Total Qty"}
                    value={props.data.total_qty}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="PR"
                />
            </div>
            <div className="flex h-full  flex-col items-center justify-around gap-2">
                <TextField
                    size="small"
                    label={`Profit By ${props.selectedBy} Sale`}
                    value={props.data.sale_profit.toLocaleString() + " " + props.selectedCur.symbol}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="OriginalNumber"
                />
                <TextField
                    size="small"
                    label={"Sum Of Purchase"}
                    value={props.data.total_purchase.toLocaleString() + " " + props.selectedCur.symbol}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="PI"
                />

                <TextField
                    size="small"
                    label={"Sum Of Sales"}
                    value={props.data.total_sales.toLocaleString() + " " + props.selectedCur.symbol}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="SA"
                />

                <TextField
                    size="small"
                    label={"Sum Of Sales Return"}
                    value={props.data.total_sales_return.toLocaleString() + " " + props.selectedCur.symbol}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="SR"
                />

                <TextField
                    size="small"
                    label={"Sum Of Purchase Return"}
                    value={props.data.total_purchase_return.toLocaleString() + " " + props.selectedCur.symbol}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="PR"
                />

                <TextField
                    size="small"
                    label={"Capital"}
                    value={props.data.capital.toLocaleString() + " " + props.selectedCur.symbol}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                        }
                    }}
                    id="totalPaid"
                />
            </div>
        </Box>
    );
}
