import parse, { attributesToProps, domToReact } from "html-react-parser";
import Box from "@mui/material/Box";
import AppTheme from "../../components/app/AppTheme";
import Datatable from "../../components/PrintModal/ReportModal/Datatable";
import React from "react";
export default function ReportLayout({ data, preview, config, structure }) {
    return <ReportContainer />;

    function replace(el) {
        const attr = attributesToProps(el.attribs);
        if (el.type === "text") {
            if (preview) {
                if (el.data.includes("{") && el.data.includes("}")) {
                    return <>{eval(`(${el.data.replace("{", "").replace(/}([^}]*)$/, "$1")})`)}</>;
                }
                if (el.data.includes("{{") && el.data.includes("}}")) {
                    return <>{eval(`(${el.data.replace("{{", "").replace("}}", "")}())`)}</>;
                }
            } else {
                return <>{el.data}</>;
            }
        } else {
            if (el.name === "box") {
                return <Box {...attr}>{domToReact(el.children, { replace: replace })}</Box>;
            } else if (el.name === "datatable") {
                return <DataTableElement />;
            } else if (el.name === "grid") {
                return <Table data={config["tables"][attr.id]} conWidth={attr.conwidth} />;
            } else if (el.name === "div") {
                return <div {...attr}>{domToReact(el.children, { replace: replace })}</div>;
            }
        }
    }

    function ReportContainer() {
        try {
            return (
                <AppTheme theme="light">
                    <div id="reportCon">{parse(structure, { replace: replace })}</div>
                </AppTheme>
            );
        } catch (err) {
            return <div>{err.toString()}</div>;
        }
    }

    function DataTableElement() {
        try {
            // return <Datatable rows={rows} cols={cols} rowId={rowId} />;
            return <Datatable {...config.dataTableProps} />;
        } catch (err) {
            return <div>{err.toString()}</div>;
        }
    }

    function Table({ conWidth = "400px", data = {} }) {
        if (data.rows.length === 0) {
            return <div></div>;
        }

        const gridContainerStyle = {
            display: "grid",
            gridTemplateColumns: `repeat(${data.cols.length}, 1fr)`,
            width: "100%",
        };

        const gridItemStyle = {
            border: "1px solid #000",
            padding: "3px 2px",
            textAlign: "center",
            maxWidth: `${(parseFloat(conWidth) / data.cols.length) * 2}` + "cm",
            textOverflow: "ellipsis",
            overflowY: "clip",
        };

        const headerStyle = {
            fontWeight: "bold",
            backgroundColor: "#f2f2f2",
        };
        return (
            <div style={gridContainerStyle}>
                <>
                    {data.cols.map((col, idx) => {
                        return (
                            <div key={idx} style={{ ...gridItemStyle, ...headerStyle }}>
                                {col["header"]}
                            </div>
                        );
                    })}
                    {data.rows.map((row, ridx) => {
                        return (
                            <>
                                {data.cols.map((col, cidx) => {
                                    let value = "";
                                    let style = {};
                                    try {
                                        value = col["format"] !== undefined ? col.format(row) : row[col["field"]];
                                        if (col["isImg"]) {
                                            value = <img className=" h-auto min-w-[1cm] " src={value} />;
                                        }
                                        if (col["isLink"]) {
                                            value = (
                                                <a target="_blank" href={value}>
                                                    {value}
                                                </a>
                                            );
                                        }
                                        if (col["style"] !== undefined) {
                                            style = col["style"];
                                        }
                                    } catch (err) {
                                        console.log(err);
                                        value = row[col["field"]];
                                    }
                                    return (
                                        <div key={`${ridx}${cidx}`} style={{ ...gridItemStyle, ...style }}>
                                            {value}
                                        </div>
                                    );
                                })}
                            </>
                        );
                    })}
                </>
            </div>
        );
    }
}
