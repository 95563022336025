import React, { useState } from "react";
import TextField from "../../../app/TextField";
import Box from "@mui/material/Box";
import XButton from "../../../app/XButton";
import Button from "../../../app/Button";
import Modal from "../../../app/Modal";
import { useLanguage } from "../../../app/LanguageContext";

export default function CreateAttributesModal(props) {
    const handleClose = () => props.setShow(false);
    const [newValue, setNewValue] = useState("");
    const {lang} = useLanguage()
    return (
        <>
            <Modal
                show={props.show}
                setshow={(e, r) => {
                    props.setShow(false);
                }}
                width="30vw"
                height="15em"
                title={lang['CREATE_NEW_ATTRIBUTE']}
                id={"ItemAttributesModal"}
            >
                {" "}
                <div className="flex h-full flex-col ">
                    <div className="flex h-full flex-col justify-between p-2">
                        <div className="flex flex-row justify-between">
                            <TextField disabled value={props.tableName} label="Table Name" variant="outlined" />
                            <TextField
                                label={lang['NEW_ATTRIBUTE']}
                                variant="outlined"
                                autoFocus
                                value={newValue}
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                        createAttribute();
                                    }
                                }}
                                onChange={(e) => {
                                    setNewValue(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex flex-row justify-end gap-3">
                            <Button variant="outlined" theme="error" className="mr-5" onClick={handleClose}>
                            {lang['CANCEL']}
                            </Button>
                            <Button variant="outlined" onClick={createAttribute}>
                            {lang['APPLY']}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
    function createAttribute() {
        let url = "goods/create_item_attribute/";
        const callbackFn = (res) => {
            if (res.data.info === "success") {
                handleClose();
            }
            props.fetchTable();
        };

        props.postdata(url, callbackFn, { tablename: props.tableName, value: newValue }, false);
    }
}
