import React from "react";
import Modal from "../../../app/Modal";
import DataTable from "../../../app/DataTable";
import Button from "../../../app/Button";
import MultiSelect from "../../../app/MultiSelect";
import TextField from "../../../app/TextField";
import Select from "../../../app/Select";
import ConfirmationModal from "../../../app/ConfirmationModal";
import { useAlert } from "../../../../providers/AlertProvider";
import { Delete } from "@mui/icons-material";

export default function MultiEditModal(props: TMultiEditModalProps) {
    const [show, setshow] = React.useState(false);
    const itemcodeRef = React.useRef<any>({ value: "" });
    const formulaRef = React.useRef<any>({ value: "" });
    const [selectedField, setselectedField] = React.useState("sale1");
    const [loading, setloading] = React.useState(false);
    const [disableApplyData, setdisableApplyData] = React.useState(false);

    const alert = useAlert();

    const [data, setdata] = React.useState([]);

    const lists = JSON.parse(localStorage.getItem("settings")).lists;

    const [filters, setfilters] = React.useState({
        category: { values: [], options: lists["categories"], label: "Categories" },
        set: { values: [], options: lists["sets"], label: "Sets" },
        brand: { values: [], options: lists["brands"], label: "Brands" },
        color: { values: [], options: lists["colors"], label: "Colors" },
        unit: { values: [], options: lists["units"], label: "Units" },
        type: { values: [], options: lists["goods_types"], label: "Types" },
        family: { values: [], options: lists["families"], label: "Families" },
    });

    const isEditDisabled = data.length === 0;

    return (
        <>
            <Button
                onClick={() => {
                    setshow(true);
                }}
            >
                Multi Edit
            </Button>
            <Modal show={show} setshow={setshow} title="Multi Edit" width="95vw" height="95vh">
                <div className="flex h-full w-full flex-col gap-1 p-2">
                    <div className="flex h-[100%] w-full flex-row gap-1">
                        <div className="flex h-full w-[80%] flex-row">
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <DataTable
                                    rows={data}
                                    columns={[
                                        {
                                            field: "itemcode",
                                            headerName: "Itemcode",
                                            flex: 1,
                                        },
                                        {
                                            field: "itemname1",
                                            headerName: "Item Name",
                                            flex: 1,
                                        },
                                        {
                                            field: selectedField,
                                            headerName: "Old Value" + ` (${selectedField})`,
                                            flex: 1,
                                        },
                                        {
                                            field: "formula",
                                            headerName: "Formula",
                                            flex: 1,
                                            editable: true,
                                        },
                                        {
                                            field: "del",
                                            headerName: "Del",
                                            flex: 0.1,
                                            renderCell: (params) => {
                                                return (
                                                    <ConfirmationModal
                                                        Icon={Delete}
                                                        handleConfirm={() => {
                                                            setdata((old) => {
                                                                return old.filter((e) => {
                                                                    return e.itemcode !== params.row.itemcode;
                                                                });
                                                            });
                                                        }}
                                                        color={"error"}
                                                        size="small"
                                                        title={"Delete Item ?"}
                                                        message={'Are You Sure You Want To Delete "' + params.row.itemcode + '" ?'}
                                                        showOnHover={true}
                                                    />
                                                );
                                            },
                                        },
                                    ]}
                                    getRowId={(e) => {
                                        return e.itemcode;
                                    }}
                                    processRowUpdate={(e) => {
                                        setdata((old) => {
                                            const temp = [...old];
                                            const idx = old.findIndex((f) => {
                                                return f.itemcode === e.itemcode;
                                            });
                                            temp[idx] = e;

                                            return temp;
                                        });
                                        return e;
                                    }}
                                />
                            )}
                        </div>
                        <div className="flex h-full w-[20%] flex-col justify-between">
                            <div
                                className=" w-full items-center justify-center gap-1"
                                style={{ display: "grid", gridTemplateRows: "repeat(7, 1fr)", gridTemplateColumns: "repeat(1, 1fr)" }}
                            >
                                <TextField tfRef={itemcodeRef} label="Similer Item Code" style={{ flex: 1 }} size="small" />
                                {Object.keys(filters).map((key) => {
                                    return (
                                        <div style={{ flex: 1 }} key={key}>
                                            <MultiSelect
                                                value={filters[key].values}
                                                setvalues={(newVal) => {
                                                    setfilters((prev) => {
                                                        return {
                                                            ...prev,
                                                            [key]: {
                                                                ...prev[key],
                                                                values: newVal,
                                                            },
                                                        };
                                                    });
                                                }}
                                                options={filters[key].options}
                                                label={filters[key].label}
                                                menuValue="id"
                                                menuLabels={["id", "name", "desc"]}
                                                size="small"
                                            />
                                        </div>
                                    );
                                })}
                                <Button theme={"error"} sx={{ flex: 1 }} onClick={resetFilters}>
                                    Reset Filters
                                </Button>
                                <Button theme={"success"} sx={{ flex: 1 }} onClick={getData}>
                                    Get Data
                                </Button>
                            </div>
                            <div
                                className="w-full  justify-center gap-1"
                                style={{ display: "grid", gridTemplateRows: "repeat(4, 1fr)", gridTemplateColumns: "repeat(1, 1fr)" }}
                            >
                                <Select
                                    sx={{ flex: 1 }}
                                    value={selectedField}
                                    onChange={(e) => {
                                        setselectedField(e.target.value);
                                    }}
                                    allowNone={false}
                                    options={[
                                        { field: "sale1", label: "Sale 1" },
                                        { field: "sale2", label: "Sale 2" },
                                        { field: "sale3", label: "Sale 3" },
                                        { field: "sale4", label: "Sale 4" },
                                    ]}
                                    menuValue="field"
                                    menuLabels={["label"]}
                                    label="Field"
                                    width="100%"
                                    disabled={isEditDisabled}
                                />
                                <TextField disabled={isEditDisabled} label="Formula" style={{ flex: 1 }} size="small" tfRef={formulaRef} />
                                <Button disabled={isEditDisabled} theme={"success"} onClick={applyFormula}>
                                    Apply Formula To All Items
                                </Button>
                                <ConfirmationModal
                                    handleConfirm={() => {
                                        updateData();
                                    }}
                                    color={"success"}
                                    size="small"
                                    title={"Apply Changes ?"}
                                    message={"Apply Current changes?"}
                                    disabled={disableApplyData || isEditDisabled}
                                >
                                    Apply Changes
                                </ConfirmationModal>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
    function getData() {
        setloading(true);
        const tfilters = Object.keys(filters).reduce((prev, curr) => {
            return {
                ...prev,
                [curr]: filters[curr].values,
            };
        }, {});
        const url = "goods/get_items2/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data.goods);
                setloading(false);
            }
        };

        alert.postdata(url, cbnf, { filters: { ...tfilters, itemcode: itemcodeRef.current.value } }, true);
    }
    function updateData() {
        setdisableApplyData(true);
        const url = "goods/multi_edit_items/";
        const cbnf = (res) => {
            setdisableApplyData(false);
            if (res.data.info === "success") {
                location.reload();
            }
        };

        alert.postdata(url, cbnf, { items: data, field: selectedField }, true);
    }
    function applyFormula() {
        if (formulaRef.current.value === "") return;
        setdata((old) => {
            return old.map((e) => {
                return {
                    ...e,
                    formula: formulaRef.current.value,
                };
            });
        });
    }
    function resetFilters() {
        setfilters({
            category: { values: [], options: lists["categories"], label: "Categories" },
            set: { values: [], options: lists["sets"], label: "Sets" },
            brand: { values: [], options: lists["brands"], label: "Brands" },
            color: { values: [], options: lists["colors"], label: "Colors" },
            unit: { values: [], options: lists["units"], label: "Units" },
            type: { values: [], options: lists["goods_types"], label: "Types" },
            family: { values: [], options: lists["families"], label: "Families" },
        });
        itemcodeRef.current.value = "";
    }
}
type TMultiEditModalProps = any;
