import Button from "@mui/material/Button";
import React from "react";
export default function OthersTab(props) {
    return (
        <div className="flex h-[35vh] flex-row rounded  p-2 ">
            <div className="flex flex-col">
                <Button
                    sx={{ marginX: 2 }}
                    variant="outlined"
                    color="error"
                    id={"deletItem"}
                    onClick={() => {
                        deleteItem();
                    }}
                >
                    Delete Item
                </Button>
                {/* <div>Edit Item Code</div>
                <div>default in out</div> */}
            </div>
        </div>
    );
    function deleteItem() {
        let url = "goods/delete_item/";

        const cbnf = (res) => {
            if (res.data.info === "success") {
                props.setModalShow(false);
            }
        };
        props.postdata(url, cbnf, { itemcode: props.maincode }, false);
    }
}
