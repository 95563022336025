import leftArrow from "../../../media/LeftArrow2.png";
import React,{ useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import QuickAccessModal from "./QuickAccessModal";

export default function DashBoard(props) {
    const [isVisible, setIsVisible] = useState(false);

    const [showQuickaccess, setshowQuickaccess] = useState(false);

    const rotateImg = {
        rotate: 180,
    };

    const img = {
        rotate: 0,
    };

    const showCon = {
        height: "650px",
        width: "250px",
        top: "calc(45vh - 310px)",
        justifyContent: "center",
        borderRadius: "9999999px",
    };
    const hideCon = {
        height: "30px",
        width: "30px",
        top: "45vh",
        transitionEnd: {
            justifyContent: "start",
            borderRadius: "0px",
        },
    };
    let adname = "";
    try {
        adname =
            JSON.parse(window.localStorage.getItem("admin")).name.toString().toUpperCase() === undefined
                ? ""
                : JSON.parse(window.localStorage.getItem("admin")).name.toString().toUpperCase();
    } catch {
        adname = "";
    }
    return (
        <>
            {!props.showDashboard ? null : (
                <motion.div
                    className=" absolute right-0 top-[45vh] z-[10000] flex h-[30px] w-[30px] items-center overflow-hidden rounded-full "
                    // style={{
                    //     height: isVisible ? "700px" : "40px",
                    //     width: isVisible ? "300px" : "40px",
                    // }}
                    animate={isVisible ? showCon : hideCon}
                    children={
                        <div>
                            <div className=" flex   h-full w-full items-center justify-center  overflow-hidden rounded-3xl  ">
                                <div className=" flex h-full w-full flex-row  items-center rounded-3xl ">
                                    <div
                                        className=" glass  h-[30px] w-[30px] cursor-pointer select-none !rounded-r-full "
                                        onClick={(e) => {
                                            setIsVisible((old) => {
                                                return !old;
                                            });
                                        }}
                                    >
                                        <motion.div
                                            className=" "
                                            animate={isVisible ? rotateImg : img}
                                            children={
                                                <img
                                                    draggable={false}
                                                    src={leftArrow}
                                                    alt=""
                                                    className="mx-auto  mt-[2px] h-[24px]  "
                                                />
                                            }
                                        />
                                        <div className=" translate-z-[1rem] h-[2rem] w-[20px] translate-y-[-2rem]"></div>
                                    </div>
                                    <div className="glassCon  my-4 mr-3 flex h-[600px] w-[200px] flex-col items-stretch  ">
                                        <div className=" mx-auto my-3 flex w-[90%] flex-col items-center justify-center border-b-2 pb-2 font-semibold ">
                                            <div>
                                                {adname}
                                                's
                                            </div>
                                            <div>Dash Board</div>
                                        </div>
                                        <div className=" flex h-full  flex-col items-stretch  gap-7">
                                            <Button sx={{ m: 2 }} variant="outlined" onClick={() => {}}>
                                                <div className=" my-2 text-xl">Messages</div>
                                            </Button>
                                            <Button sx={{ m: 2 }} variant="outlined" onClick={() => {}}>
                                                <div className=" my-2 text-xl">Notifications</div>
                                            </Button>
                                            <Button
                                                sx={{ m: 2 }}
                                                variant="outlined"
                                                onClick={() => {
                                                    setshowQuickaccess(true);
                                                    setIsVisible(false);
                                                }}
                                            >
                                                <div className=" my-2 whitespace-nowrap text-xl">Quick Access</div>
                                            </Button>
                                            <Button
                                                sx={{ m: 2 }}
                                                variant="outlined"
                                                onClick={() => {
                                                    window.location.pathname = "/";
                                                }}
                                            >
                                                <div className=" my-2 whitespace-nowrap text-xl">Logout</div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            )}
            <QuickAccessModal show={showQuickaccess} setshow={setshowQuickaccess} postdata={props.postdata} />
        </>
    );
}
