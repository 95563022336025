import React, { useState, useEffect } from "react";
import DataTable from "../../components/BackOffice/StatementOfItem/dataTable";
import Modal from "../../components/app/Modal";
import ItemData from "../../components/BackOffice/StatementOfItem/ItemData";
import dayjs from "dayjs";
import OneItem from "../../components/BackOffice/StatementOfItem/OneItem";
import ItemCard from "../../components/BackOffice/Goods/itemCard/main/ItemCard";
import { columns } from "./Goods";
import DataGridModal from "../../components/app/DataGridModal";
import ReportModal from "../../components/PrintModal/ReportModal/ReportModal";
import { useLanguage } from "../../components/app/LanguageContext";

const currentYear = new Date().getUTCFullYear();

export default function StatementOfItem({ postdata, show, setTempHide, setshow, tempHide, defaultItemCode }) {
    const { lang } = useLanguage();
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 116) {
                event.preventDefault();
                getData();
            } else if (event.key === "F8") {
                event.preventDefault();
                clearData();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const [inout, setinout] = useState([]);
    const [data, setdata] = useState({
        cost: 0,
        sale: 0,
        stock_value: 0,
        sales_profit: 0,
        symbol: "",
        purchase_qty: 0,
        sales_qty: 0,
        purchase_return_qty: 0,
        sales_return_qty: 0,
        total_qty: 0,
        purchase_total: 0,
        sales_total: 0,
        purchase_return_total: 0,
        sales_return_total: 0,
        invoices_total: 0,
    });

    const [itemdata, setitemdata] = useState({
        itemcode: "",
        originalnumber: "",
        itemname1: "",
        itemname2: "",
        tax: 0,
        sale1: 0,
        sale2: 0,
        sale3: 0,
        sale4: 0,
        tax_included: "",
        disc1: 0,
        disc2: 0,
        disc3: 0,
        disc4: 0,
        qty_in: 1,
        qty_out: 1,
        category: "",
        set: "",
        brand: "",
        supplier: "",
        size: "",
        color: "",
        unit: "",
        type: "",
        family: "",
        note: "",
        maincode: "",
        date_created: "",
        last_modified: "",
    });

    const [itemcode, setitemcode] = useState(defaultItemCode);

    const [selectedCur, setselectedCur] = useState({
        order: 1,
        code: "USD",
        name: "United States Dollar",
        name2: "",
        symbol: "$",
        rate: 1,
        primary: "y",
        tax_rate: 0,
    });
    const lists = JSON.parse(localStorage.getItem("settings")).lists;
    const branches = [
        {
            num: "All",
            name: "",
        },
        lists.branches,
    ];
    const smanList = [{ name: "All", description: "" }, lists.sman];
    const jobList = [{ name: "All", description: "" }, lists.jobs];
    const currencyList = lists.currency;

    const [showDataGridModal, setshowDataGridModal] = useState(false);

    const [itemCardModal, setItemCardModal] = useState(false);

    const [selectedJob, setselectedJob] = useState("All");
    const [selectedSman, setselectedSman] = useState("All");
    const [selectedBranch, setselectedBranch] = useState("All");

    const [selectedCost, setselectedCost] = useState("Average");
    const [selectedPrice, setselectedPrice] = useState("Average");
    const [selectedStockValue, setselectedStockValue] = useState("Average");
    const [selectedSaleProfit, setselectedSaleProfit] = useState("Average");

    const [startDate, setstartDate] = useState(dayjs(Date.UTC(currentYear, 0, 1)).format("YYYY-MM-DD"));
    const [endDate, setendDate] = useState(dayjs(Date.UTC(currentYear, 11, 31)).format("YYYY-MM-DD"));

    const [tempItemCode, settempItemCode] = useState("");

    const [allowEdit, setallowEdit] = useState(true);
    const [showPrintModal, setshowPrintModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    return (
        <>
            <Modal
                show={show}
                setshow={setshow}
                width="100vw"
                height="100vh"
                borderRadius="0.2rem"
                title={lang["STATEMENT_OF_ITEM"]}
                keepMounted={true}
                modalSx={{ visibility: tempHide ? "hidden" : "visible", display: tempHide ? "none" : "block" }}
            >
                <div className=" flex h-full w-full flex-col">
                    <OneItem
                        data={data}
                        itemdata={itemdata}
                        getData={getData}
                        itemcode={itemcode}
                        setitemcode={setitemcode}
                        selectedCur={selectedCur}
                        startDate={startDate}
                        endDate={endDate}
                        setstartDate={setstartDate}
                        setendDate={setendDate}
                        currentYear={currentYear}
                        selectedBranch={selectedBranch}
                        setselectedBranch={setselectedBranch}
                        selectedJob={selectedJob}
                        setselectedJob={setselectedJob}
                        selectedSman={selectedSman}
                        setselectedSman={setselectedSman}
                        smanList={smanList}
                        jobList={jobList}
                        branches={branches}
                        currencyList={currencyList}
                        setselectedCur={setselectedCur}
                        setselectedCost={setselectedCost}
                        setselectedPrice={setselectedPrice}
                        setselectedStockValue={setselectedStockValue}
                        setselectedSaleProfit={setselectedSaleProfit}
                        selectedCost={selectedCost}
                        selectedPrice={selectedPrice}
                        selectedStockValue={selectedStockValue}
                        selectedSaleProfit={selectedSaleProfit}
                        allowEdit={allowEdit}
                        clearData={clearData}
                        setshowPrintModal={setshowPrintModal}
                        isLoading={isLoading}
                    />

                    <div
                        className="flex  w-full flex-row border-t-2 border-gray-500 "
                        style={{ height: "calc(100% - 18.7rem)" }}
                    >
                        <div className="m-2 h-[95%] w-[60%]">
                            <DataTable
                                rows={inout}
                                setItemCardModal={setItemCardModal}
                                settempItemCode={settempItemCode}
                                selectedCur={selectedCur}
                                setTempHide={setTempHide}
                            />
                        </div>
                        <div className=" flex w-[39%] flex-row items-center  justify-center ">
                            <ItemData
                                selectedCost={selectedCost}
                                selectedPrice={selectedPrice}
                                selectedStockValue={selectedStockValue}
                                selectedSaleProfit={selectedSaleProfit}
                                selectedBranch={selectedBranch}
                                selectedCur={selectedCur}
                                branches={branches}
                                currencyList={currencyList}
                                data={data}
                                getData={getData}
                                setselectedCur={setselectedCur}
                                setselectedBranch={setselectedBranch}
                                selectedJob={selectedJob}
                                setselectedJob={setselectedJob}
                                selectedSman={selectedSman}
                                setselectedSman={setselectedSman}
                                smanList={smanList}
                                jobList={jobList}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            {itemCardModal ? (
                <ItemCard
                    modalShow={itemCardModal}
                    setModalShow={setItemCardModal}
                    postdata={postdata}
                    itemcode={tempItemCode}
                    columns={columns}
                    onHideFunc={() => {}}
                />
            ) : null}
            {showDataGridModal ? (
                <DataGridModal
                    show={showDataGridModal}
                    setShow={setshowDataGridModal}
                    modalTitle={"Similar Item Code / Original Number / Item Name 1 / Item Name 2 "}
                    searchPhrase={itemcode}
                    quary={`itemcode as 'Item Code',originalnumber as "Original Number",itemname1 as "Item Name",\`set\` as "Set",category as "Category",sale1 as "Sale 1",sale2 as "Sale 2",sale3 as "Sale 3",sale4 as "sale 4" FROM ${localStorage.getItem(
                        "dbname"
                    )}.goods where (itemcode like '%${itemcode}' or itemcode like '${itemcode}%' or itemcode like '%${itemcode}%') or (originalnumber like '%${itemcode}' or originalnumber like '${itemcode}%' or originalnumber like '%${itemcode}%') or (itemname1 like '%${itemcode}' or itemname1 like '${itemcode}%' or itemname1 like '%${itemcode}%') or (itemname2 like '%${itemcode}' or itemname2 like '${itemcode}%' or itemname2 like '%${itemcode}%')`}
                    postdata={postdata}
                    onEnterPress={(val) => {
                        getData(val);
                        setitemcode(val);
                    }}
                />
            ) : null}
            {showPrintModal ? (
                <ReportModal
                    showPrintModal={showPrintModal}
                    setshowPrintModal={setshowPrintModal}
                    data={{
                        inout: inout,
                        data: data,
                        itemdata: itemdata,
                        selectedJob: selectedJob,
                        selectedSman: selectedSman,
                        selectedBranch: selectedBranch,
                        selectedCur: selectedCur,
                        selectedCost: selectedCost,
                        selectedPrice: selectedPrice,
                        selectedStockValue: selectedStockValue,
                        selectedSaleProfit: selectedSaleProfit,
                        startDate: startDate,
                        endDate: endDate,
                    }}
                    reportId={"statement_of_item"}
                    postdata={postdata}
                    onPrintFunc={() => {}}
                />
            ) : null}
        </>
    );
    function getData(tempcode) {
        setisLoading(true);
        let url = "goods/statement_of_item/";
        const cbnf = (res) => {
            if (res.data.msg === "Item Not Found") {
                settempItemCode();
                setshowDataGridModal(true);
            } else if (res.data.info === "success") {
                setinout(res.data.data.inout);
                setdata(res.data.data.data);
                setitemcode(res.data.data.itemdata.itemcode);
                setitemdata(res.data.data.itemdata);

                window.localStorage.setItem("itemcode", res.data.data.itemdata.itemcode);
                setallowEdit(false);
            } else {
                clearData();
            }
            setisLoading(false);
        };

        const fdata = {
            itemcode: tempcode ? tempcode : itemcode,
            currency: selectedCur.code,
            startdate: startDate,
            enddate: endDate,
            job: selectedJob,
            sman: selectedSman,
            branch: selectedBranch,
            selectedCost: selectedCost,
            selectedPrice: selectedPrice,
            selectedStockValue: selectedStockValue,
            selectedSaleProfit: selectedSaleProfit,
        };

        postdata(url, cbnf, fdata, false);
    }

    function clearData() {
        setinout([]);
        setdata({
            cost: 0,
            sale: 0,
            stock_value: 0,
            sales_profit: 0,
            symbol: "",
            purchase_qty: 0,
            sales_qty: 0,
            purchase_return_qty: 0,
            sales_return_qty: 0,
            total_qty: 0,
            purchase_total: 0,
            sales_total: 0,
            purchase_return_total: 0,
            sales_return_total: 0,
            invoices_total: 0,
        });

        setitemdata({
            itemcode: "",
            originalnumber: "",
            itemname1: "",
            itemname2: "",
            tax: 0,
            sale1: 0,
            sale2: 0,
            sale3: 0,
            sale4: 0,
            tax_included: "",
            disc1: 0,
            disc2: 0,
            disc3: 0,
            disc4: 0,
            qty_in: 1,
            qty_out: 1,
            category: "",
            set: "",
            brand: "",
            supplier: "",
            size: "",
            color: "",
            unit: "",
            type: "1",
            family: "",
            note: "",
            maincode: "",
            date_created: "",
            last_modified: "",
        });
        setallowEdit(true);
    }
}
