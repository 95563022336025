import React, { useEffect, useState } from "react";
import TextField from "../../../app/TextField";
import rightarrow from "../../../../media/rightarrow.png";
import leftarrow from "../../../../media/leftarrow.png";
import IconButton from "@mui/material/IconButton";
import Modal from "../../../app/Modal";
import Button from "../../../app/Button";

export default function ScaleConfig(props) {
    const [config, setconfig] = useState([]);

    const [selectedIdx, setselectedIdx] = useState(0);

    const [configName, setconfigName] = useState("");

    const [scaleConfigModal, setscaleConfigModal] = useState(false);

    useEffect(() => {
        getScaleConfig();
    }, []);

    return (
        <>
            <Button
                id="ScaleConfigButton"
                onClick={() => {
                    setscaleConfigModal(true);
                }}
            >
                Scale Config
            </Button>
            <Modal show={scaleConfigModal} setshow={setscaleConfigModal} title="Scale Config" height="30rem" width="40rem">
                <div className="flex h-full flex-col ">
                    <div className="flex h-[30rem] flex-col ">
                        <div className="flex flex-row  items-center justify-center">
                            <IconButton
                                style={{ width: "3.3rem", height: "3.3rem" }}
                                variant="text"
                                onClick={() => {
                                    if (selectedIdx !== 0) {
                                        setselectedIdx((e) => {
                                            return e - 1;
                                        });
                                    }
                                }}
                            >
                                <img draggable={false} src={leftarrow} alt="" />
                            </IconButton>
                            <div className=" mx-2 text-lg font-semibold">{selectedIdx}</div>
                            <IconButton
                                style={{ width: "3.3rem", height: "3.3rem" }}
                                variant="text"
                                onClick={() => {
                                    if (config.length - 1 !== selectedIdx) {
                                        setselectedIdx((e) => {
                                            return e + 1;
                                        });
                                    }
                                }}
                            >
                                <img draggable={false} src={rightarrow} alt="" />
                            </IconButton>
                        </div>
                        <div className="flex h-full flex-col">
                            {config.map((conf, idx) => {
                                if (idx === selectedIdx) {
                                    if (conf.isNewConfig) {
                                        return (
                                            <div className="flex h-full flex-col items-center justify-center">
                                                <TextField
                                                    label={"Configuration Name"}
                                                    value={configName}
                                                    onChange={(e) => {
                                                        setconfigName(e.target.value);
                                                    }}
                                                />
                                                <Button
                                                    sx={{ margin: 3 }}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        addConfig();
                                                    }}
                                                >
                                                    Create New Configuration
                                                </Button>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="flex h-full flex-col items-center justify-center gap-3">
                                                <TextField
                                                    label={"Configuration Name"}
                                                    value={conf.name}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            document.getElementById("BracodeSize").focus();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        editConfig(conf.id, "name", e.target.value);
                                                    }}
                                                />

                                                <TextField
                                                    label={"Scale Number"}
                                                    id="ScaleDigit"
                                                    value={conf.check_digit}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            document.getElementById("ScaleDigitPosition").focus();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        editConfig(conf.id, "check_digit", e.target.value);
                                                    }}
                                                    type="number"
                                                />

                                                <TextField
                                                    id="ItemCodeSize"
                                                    label={"Item Code Size"}
                                                    value={conf.item_size}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            document.getElementById("ItemCodePosition").focus();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        editConfig(conf.id, "item_size", e.target.value);
                                                    }}
                                                    type="number"
                                                />

                                                <TextField
                                                    label={"Weight Size"}
                                                    id="WeightSize"
                                                    value={conf.weight_size}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            document.getElementById("WeightPosition").focus();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        editConfig(conf.id, "weight_size", e.target.value);
                                                    }}
                                                    type="number"
                                                />
                                            </div>
                                        );
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
    function getScaleConfig() {
        let url = "goods/scale_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setconfig(() => {
                    let temp = [...res.data.data];
                    temp.push({
                        isNewConfig: true,
                    });
                    return temp;
                });
            }
        };

        props.postdata(url, cbnf, {}, false);
    }

    function addConfig() {
        let url = "goods/add_scale_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                getScaleConfig();
            }
        };

        props.postdata(url, cbnf, { name: configName }, true);
    }

    function editConfig(id, field, value) {
        let url = "goods/edit_scale_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                getScaleConfig();
            }
        };

        let fdata = { id: id, field: field, value: value };

        props.postdata(url, cbnf, fdata, false);
    }
}
