import Button from "../../../app/Button";
import Modal from "../../../app/Modal";
import TextField2 from "../../../app/TextField2";
import React, { useState, useContext } from "react";
import DataTableContainer, { TDataTableApi } from "./DataTableContainer";
import { AlertContext } from "../../../../providers/AlertProvider";
import AddDatabase from "./AddDatabase";

export default function MultidataConfigurationModal(props: TMultidataConfigurationModalProps) {
    const alertAPI = useContext(AlertContext);
    const [show, setShow] = useState(false);

    const dataGridApiRef = React.useRef<TDataTableApi>(null);
    return (
        <>
            <Button
                onClick={() => {
                    setShow(true);
                    selectDatabases();
                }}
            >
                Multi Data Config
            </Button>
            <Modal show={show} setshow={setShow} title="Multi Data Config">
                <div className="flex h-full w-full flex-col gap-2 p-2">
                    <div className="h-[80%] w-full">
                        <DataTableContainer dataGridApiRef={dataGridApiRef} editDatabase={editDatabase} />
                    </div>
                    <div className="flex flex-row gap-2">
                        <Button
                            onClick={() => {
                                const data = dataGridApiRef.current.gridApiRef.current.getSelectedRows().values().next().value as TMultiData;

                                validateDatabase(data.id);
                            }}
                        >
                            Check Selected DB
                        </Button>
                        {/* <Button onClick={() => {}}> Remove Selected</Button> */}
                    </div>
                    <AddDatabase validateDatabase={validateDatabase} addDatabase={addDatabase} />
                </div>
            </Modal>
        </>
    );

    function validateDatabase(id) {
        const fdata = {
            id: id,
        };
        return alertAPI.asyncPostData("settings/validate_database/", fdata, (res) => {}, true);
    }
    function addDatabase(host, port, username, dbpass, dbname) {
        const fdata = {
            host: host,
            port: port,
            user: username,
            dbpass: dbpass,
            dbname: dbname,
        };
        return alertAPI.asyncPostData(
            "settings/add_otherdatabase",
            fdata,
            (res) => {
                selectDatabases();
            },
            true
        );
    }
    function selectDatabases(msg = true) {
        const fdata = {};
        return alertAPI.asyncPostData(
            "settings/get_databases",
            fdata,
            (res) => {
                dataGridApiRef.current.api.setdata(res.data.data);
            },
            msg
        );
    }
    function editDatabase(r, v) {
        const fdata = {
            row: r,
            value: v,
        };
        return alertAPI.asyncPostData(
            "settings/edit_database",
            fdata,
            (res) => {
                selectDatabases(false);
            },
            false
        );
    }
}

type TMultidataConfigurationModalProps = any;

export type TMultiData = {
    id: number;
    host: string;
    port: string;
    username: string;
    dbpass: string;
    dbname: string;
};
