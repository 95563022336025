import { TextField as MUITextField, TextFieldProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export default function TextField2({
    size = "small",
    onKeyDownCapture = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent the default behavior of Enter key
            const n = document.getElementById((e.target as HTMLInputElement).id)?.parentElement?.parentElement
                ?.nextElementSibling?.children[1]?.children[0] as HTMLInputElement;
            if (n) n.focus();
        }
    },
    tfRef,
    ...rest
}: TCustomFieldProps) {
    return <MUITextField size={size} ref={tfRef} onKeyDownCapture={onKeyDownCapture} {...rest} />;
}

export type TCustomFieldProps = TextFieldProps & {
    tfRef?;
};
