import React from "react";
export default function PropertiesTab(props) {
    return (
        <div className="flex flex-col items-center justify-around p-1">
            <div>
                <props.ItemProperties />
            </div>
        </div>
    );
}
