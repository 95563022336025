import React, { useState, useEffect } from "react";
import Tabs from "../../app/Tabs";
import CreateNewUser from "../UserSettingsTabs/CreateNewUser";
import UserList from "../UserSettingsTabs/UserList";
import UserPrivelegeSettings from "../UserSettingsTabs/UserPrivelegeSettings";

export default function UserSettings(props) {
    const [value, setValue] = useState(1);

    return (
        <div className="h-[100%]">
            <Tabs
                value={value}
                setvalue={setValue}
                tabsList={[
                    { value: 1, label: "Users List" },
                    { value: 2, label: "Create New User" },
                    { value: 3, label: "User Privileges" },
                ]}
                padding="0px"
                boxBorder="0px"
            >
                {value === 1 ? <UserList postdata={props.postdata} /> : null}
                {value === 2 ? <CreateNewUser postdata={props.postdata} /> : null}
                {value === 3 ? <UserPrivelegeSettings postdata={props.postdata} /> : null}
            </Tabs>
        </div>
    );
}
