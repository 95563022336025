import React, { useEffect, useState } from "react";
import Modal from "../../../app/Modal";
import Button from "../../../app/Button";
import CreateAttributesModal from "./CreateAttributeModal";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import XButton from "../../../app/XButton";
import { useLanguage } from "../../../app/LanguageContext";
const columns = [
    { field: "id", headerName: "Id", flex: 1, editable: false },
    { field: "name", headerName: "Name", flex: 1, editable: true },
    { field: "desc", headerName: "Description", flex: 3, editable: true },
];

export default function ItemAttributesModal(props) {
    const [data, setdata] = useState({ Categories: [] });
    const [selectedAtt, setselectedAtt] = useState("Categories");

    const [selectedRow, setselectedRow] = useState({});

    const [createAttModal, setcreateAttModal] = useState("");

    const [itemAttributesModal, setItemAttributesModal] = useState(false);
    const {lang} = useLanguage()

    useEffect(() => {
        fetchTable();
    }, []);
    return (
        <>
            <Button
                id="ItemAttributesButton"
                onClick={() => {
                    setItemAttributesModal(true);
                }}
            >
                {lang['ITEM_ATTRIBUTES']}
            </Button>
            <Modal
                show={itemAttributesModal}
                setshow={(e, r) => {
                    setItemAttributesModal(false);
                    props.fetchItems();
                }}
                width={"65vw"}
                height={"40rem"}
                title={lang['ATTRIBUTES_SETUP']}
            >
                <div className="flex h-full flex-col justify-between p-2">
                    <div className="flex h-full w-full flex-row  ">
                        <div className=" w-[15%]">
                            <div className="flex h-full w-[100%] flex-col items-stretch gap-3 ">
                                <Button
                                    onClick={() => {
                                        fetchTable();
                                        setselectedAtt("Categories");
                                    }}
                                    variant={"outlined"}
                                    theme={selectedAtt === "Categories" ? "success" : "primary"}
                                    className="my-3"
                                >
                                    {lang['CATEGORIES']}
                                </Button>
                                <Button
                                    onClick={() => {
                                        fetchTable();
                                        setselectedAtt("Sets");
                                    }}
                                    variant={"outlined"}
                                    theme={selectedAtt === "Sets" ? "success" : "primary"}
                                    className="my-3"
                                >
                                    {lang['SETS']}
                                </Button>
                                <Button
                                    onClick={() => {
                                        fetchTable();
                                        setselectedAtt("Brands");
                                    }}
                                    variant={"outlined"}
                                    theme={selectedAtt === "Brands" ? "success" : "primary"}
                                    className="my-3"
                                >
                                    {lang['BRANDS']}
                                </Button>
                                <Button
                                    onClick={() => {
                                        fetchTable();
                                        setselectedAtt("Colors");
                                    }}
                                    variant={"outlined"}
                                    theme={selectedAtt === "Colors" ? "success" : "primary"}
                                    className="my-3"
                                >
                                    {lang['COLORS']}
                                </Button>
                                <Button
                                    onClick={() => {
                                        fetchTable();
                                        setselectedAtt("Units");
                                    }}
                                    variant={"outlined"}
                                    theme={selectedAtt === "Units" ? "success" : "primary"}
                                    className="my-3"
                                >
                                    {lang['UNITS']}
                                </Button>
                                <Button
                                    onClick={() => {
                                        fetchTable();
                                        setselectedAtt("Families");
                                    }}
                                    variant={"outlined"}
                                    theme={selectedAtt === "Families" ? "success" : "primary"}
                                    className="my-3"
                                >
                                    {lang['FAMILIES']}
                                </Button>
                            </div>
                        </div>
                        <div className="w-[2%]"></div>
                        <div className="h-[full] w-[83%] ">
                            <Box sx={{ height: "30rem", width: "100%" }}>
                                <DataGrid
                                    sx={{
                                        "& .MuiDataGrid-cell:focus": {
                                            borderWidth: " 1px !important",
                                            borderColor: "blue !important",
                                            outline: "3px auto blue",
                                        },
                                    }}
                                    rows={data[selectedAtt]}
                                    columns={columns}
                                    rowsPerPageOptions={[10, 100, 1000]}
                                    showCellVerticalBorder
                                    showColumnVerticalBorder
                                    hideFooter
                                    headerHeight={45}
                                    rowHeight={40}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    getRowId={(e) => {
                                        return e.name;
                                    }}
                                    slotProps={{
                                        cell: { id: "datagridcell" },
                                    }}
                                    onCellEditStart={(e) => {
                                        setselectedRow(e);
                                    }}
                                    processRowUpdate={(e) => {
                                        if (e[selectedRow.field] !== selectedRow.value) {
                                            editAtt(
                                                selectedAtt,
                                                selectedRow.row.name,
                                                selectedRow.field,
                                                e[selectedRow.field]
                                            );
                                        }
                                        return e;
                                    }}
                                    onProcessRowUpdateError={(err) => {
                                        fetchTable();
                                    }}
                                />
                            </Box>
                        </div>
                    </div>
                    <div className="flex flex-row justify-end p-1">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setcreateAttModal(true);
                            }}
                        >
                            {lang['ADD_ATTRIBUTE']}
                        </Button>
                    </div>
                </div>
            </Modal>

            {createAttModal ? (
                <CreateAttributesModal
                    postdata={props.postdata}
                    fetchTable={fetchTable}
                    tableName={selectedAtt}
                    show={createAttModal}
                    setShow={setcreateAttModal}
                />
            ) : null}
        </>
    );
    function fetchTable() {
        let url = "goods/get_item_attributes_tables/";
        const callbackFn = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data);
            }
        };
        props.postdata(url, callbackFn, {}, false);
    }

    function editAtt(table, id, field, value) {
        let url = "goods/edit_item_attribute/";
        const callbackFn = (res) => {
            if (res.data.info === "success") {
                if (field === "name") {
                    fetchTable();
                    props.fetchItems();
                }
            } else {
                fetchTable();
                props.fetchItems();
            }
        };
        const data = {
            table: table,
            id: id,
            field: field,
            value: value,
        };
        props.postdata(url, callbackFn, data, false);
    }
}
