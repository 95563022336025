import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import XButton from "../../../app/XButton";
import { Button, TextField } from "@mui/material";
import React from "react";
export default function AddMenuItem({
    addItemModal,
    setaddItemModal,
    selectedParent,
    setitemType,
    setnewitemcode,
    setnewTitle,
    itemType,
    newitemcode,
    getItemcodeData,
    newTitle,
    newnum,
    setnewnum,
    addItem,
    newallowunderqtysale,
    setnewallowunderqtysale,
}) {
    return (
        <Modal
            open={addItemModal}
            onTransitionEnter={() => {}}
            onClose={(e, r) => {
                setaddItemModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableRestoreFocus
            disableEnforceFocus
            disableEscapeKeyDown
            disableAutoFocus
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40rem",
                    height: "30rem",
                    border: "2px solid #000",
                    boxShadow: 24,
                    borderRadius: 3,
                    p: 0,
                }}
            >
                <div className="flex h-full flex-col justify-start rounded-[0.6rem] bg-zinc-800 !text-[#c7c7c7]">
                    <div className=" m-1 flex flex-row justify-between border-b-2 border-gray-500">
                        <div className=" text-2xl font-semibold">Add To: "{selectedParent}"</div>
                        <div>
                            <XButton onClickFunc={setaddItemModal} />
                        </div>
                    </div>
                    <div className="flex h-full w-full flex-col items-center   gap-5 p-2 ">
                        <div className="flex flex-row items-center gap-4">
                            <div className=" font-semibold">Type :</div>
                            <div>
                                <Button
                                    onClick={() => {
                                        setitemType((old) => {
                                            if (old === "item") {
                                                return "group";
                                            } else {
                                                return "item";
                                            }
                                        });
                                        setnewitemcode("");
                                        setnewTitle("");
                                    }}
                                    variant="outlined"
                                    sx={{ width: "6rem" }}
                                >
                                    {itemType === "item" ? "Item" : "Group"}
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            {itemType === "item" ? (
                                <>
                                    <TextField
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        inputProps={{ className: "px-2 py-2 ", sx: { color: "#c7c7c7 !important" } }}
                                        size="small"
                                        label={"Item Code"}
                                        id={"itemcode"}
                                        value={newitemcode}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            setnewitemcode(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value !== "") {
                                                getItemcodeData(e.target.value);
                                            }
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("itemTitle").focus();
                                            }
                                        }}
                                    />
                                    <TextField
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        inputProps={{ className: "px-2 py-2 ", sx: { color: "#c7c7c7 !important" } }}
                                        size="small"
                                        id="itemTitle"
                                        label={"Title"}
                                        value={newTitle}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            setnewTitle(e.target.value);
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("itemNum").focus();
                                            }
                                        }}
                                    />
                                    <TextField
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        inputProps={{ className: "px-2 py-2 ", sx: { color: "#c7c7c7 !important" } }}
                                        size="small"
                                        id="itemNum"
                                        label={"Number"}
                                        value={newnum}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            setnewnum(e.target.value);
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("AddItem").focus();
                                            }
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <TextField
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        inputProps={{ className: "px-2 py-2 ", sx: { color: "#c7c7c7 !important" } }}
                                        size="small"
                                        id="itemTitle"
                                        label={"Title"}
                                        value={newTitle}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            setnewTitle(e.target.value);
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("itemNum").focus();
                                            }
                                        }}
                                    />
                                    <TextField
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        inputProps={{ className: "px-2 py-2 ", sx: { color: "#c7c7c7 !important" } }}
                                        size="small"
                                        id="itemNum"
                                        label={"Number"}
                                        value={newnum}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            setnewnum(e.target.value);
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("AddItem").focus();
                                            }
                                        }}
                                    />
                                </>
                            )}
                            <Button
                                onClick={() => {
                                    addItem();
                                    if (itemType === "item") {
                                        document.getElementById("itemcode").focus();
                                    } else {
                                        document.getElementById("itemTitle").focus();
                                    }
                                }}
                                id="AddItem"
                                variant="outlined"
                                sx={{ width: "100%" }}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
