import React, { useState, useEffect, useRef, useMemo } from "react";
import Modal from "../../components/app/Modal";
import DataTable from "../../components/BackOffice/StockOverview/dataTable";
import ItemData from "../../components/BackOffice/StockOverview/ItemData";
import MultiItem from "../../components/BackOffice/StockOverview/MultiItem";
import ItemCard from "../../components/BackOffice/Goods/itemCard/main/ItemCard";
import { columns } from "./Goods";
import { getJsonStorage } from "../../components/app/globalfunctions";
import ReportModal from "../../components/PrintModal/ReportModal/ReportModal";
import { useLanguage } from "../../components/app/LanguageContext";
const currentYear = new Date().getFullYear();

export default function StockOverview(props) {
    const {lang} = useLanguage()
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 116) {
                event.preventDefault();
                getData();
            } else if (event.key === "F8") {
                event.preventDefault();
                clearData();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const [selectedCur, setselectedCur] = useState({
        order: 1,
        code: "USD",
        name: "United States Dollar",
        name2: "",
        symbol: "$",
        rate: 1,
        primary: "y",
        tax_rate: 0,
    });
    const [filters, setfilters] = useState({
        category: [],
        set: [],
        brand: [],
        color: [],
        unit: [],
        family: [],
        goods_type: [],
        inv_type: [],
        branch: [],
        sman: [],
        job: [],
    });
    const lists = useMemo(() => getJsonStorage("settings").lists, []);

    const filterOptionsLists = {
        category: lists.categories,
        set: lists.sets,
        brand: lists.brands,
        color: lists.colors,
        unit: lists.units,
        family: lists.families,
        goods_type: lists.goods_types,
        inv_type: lists.inv_types,
        branch: lists.branches,
        sman: lists.sman,
        job: lists.jobs,
    };

    const groupByOptions = [
        { label: "Item", value: "itemcode", name: "itemname1", table: "goods", join: "" },
        { label: "Item Type", value: "type", name: "name", table: "goods_types", join: "goods" },
        { label: "Branch", value: "branch", name: "name", table: "branches", join: "inv" },
        { label: "Sman", value: "sman", name: "name", table: "salesman", join: "inv" },
        { label: "Job", value: "job", name: "name", table: "jobs", join: "inv" },
        { label: "Category", value: "category", name: "name", table: "categories", join: "goods" },
        { label: "Set", value: "set", name: "name", table: "sets", join: "goods" },
        { label: "Brand", value: "brand", name: "name", table: "brands", join: "goods" },
        { label: "Color", value: "color", name: "name", table: "colors", join: "goods" },
        { label: "Unit", value: "unit", name: "name", table: "units", join: "goods" },
        { label: "Family", value: "family", name: "name", table: "families", join: "goods" },
    ];

    const [items, setitems] = useState([]);

    const [data, setdata] = useState({
        purchase_qty: 0,
        sales_qty: 0,
        sales_return_qty: 0,
        purchase_return_qty: 0,
        total_qty: 0,
        total_purchase: 0,
        total_sales: 0,
        total_sales_return: 0,
        total_purchase_return: 0,
        capital: 0,
        stock_value: 0,
        sale_profit: 0,
        symbol: "$",
    });
    const currencyList = lists.currency;

    const [itemCardModal, setItemCardModal] = useState(false);

    const [tempItemCode, settempItemCode] = useState("");

    const [allowEdit, setallowEdit] = useState(true);

    const [isLoading, setisLoading] = useState(false);

    const [startDate, setstartDate] = useState(`${currentYear}-01-01`);
    const [endDate, setendDate] = useState(`${currentYear}-12-31`);
    const [selectedBy, setselectedBy] = useState("Average");
    const [selectedGroupBy, setselectedGroupBy] = useState(groupByOptions[0]);

    const [showPrintModal, setshowPrintModal] = useState(false);

    const orderByFieldRef = useRef();
    const orderTypeFieldRef = useRef();
    const limitFieldRef = useRef();
    const startDateFieldRef = useRef();
    const endDateFieldRef = useRef();

    const multicolumns = useMemo(() => {
        return [
            {
                field: `${selectedGroupBy.value}`,
                headerName: `${selectedGroupBy.label} Id`,
                width: 150,
            },
            {
                field: `${selectedGroupBy.name}`,
                headerName: `${selectedGroupBy.label} Name`,

                width: 150,
            },
            {
                field: "total_qty",
                headerName: "Total Qty",

                width: 120,
            },

            {
                field: "purchase_cost",
                headerName: `${selectedBy} Cost`,
                hidden: selectedGroupBy.value !== "itemcode",
                width: 140,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "sale_price",
                headerName: `${selectedBy} Sale`,
                hidden: selectedGroupBy.value !== "itemcode",
                width: 140,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "stock_value",
                headerName: `Stock Value`,

                width: 140,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "sale_profit",
                headerName: `Sale Profit`,

                width: 120,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "capital",
                headerName: "Capital",

                width: 120,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "purchase_qty",
                headerName: "Purchase Qty",

                width: 120,
            },
            {
                field: "sales_qty",
                headerName: "Sales Qty",

                width: 120,
            },
            {
                field: "sales_return_qty",
                headerName: "SR Qty",

                width: 120,
            },
            {
                field: "purchase_return_qty",
                headerName: "PR Qty",

                width: 120,
            },
            {
                field: "total_purchase",
                headerName: "Total Purchase",

                width: 120,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "total_sales",
                headerName: "Total Sales",

                width: 120,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "total_sales_return",
                headerName: "Total SR",

                width: 120,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
            {
                field: "total_purchase_return",
                headerName: "Total PR",

                width: 120,

                valueGetter: (params) => {
                    return params.value.toLocaleString() + " " + selectedCur.symbol;
                },
            },
        ];
    }, [selectedCur, selectedGroupBy, selectedBy]);
    return (
        <>
            <Modal
                show={props.show}
                setshow={props.setshow}
                title={lang['STOCK_OVERVIEW']}
                height="100vh"
                width="100vw"
                borderRadius="0.2rem"
            >
                <div className=" flex h-full w-full flex-col gap-2 p-2">
                    <MultiItem
                        getData={getData}
                        selectedCur={selectedCur}
                        startDateFieldRef={startDateFieldRef}
                        endDateFieldRef={endDateFieldRef}
                        filters={filters}
                        setfilters={setfilters}
                        data={data}
                        allowEdit={allowEdit}
                        isLoading={isLoading}
                        currencyList={currencyList}
                        clearData={clearData}
                        filterOptionsLists={filterOptionsLists}
                        selectedBy={selectedBy}
                        setselectedBy={setselectedBy}
                        setselectedCur={setselectedCur}
                        selectedGroupBy={selectedGroupBy}
                        setselectedGroupBy={setselectedGroupBy}
                        groupByOptions={groupByOptions}
                        multicolumns={multicolumns}
                        orderByFieldRef={orderByFieldRef}
                        limitFieldRef={limitFieldRef}
                        orderTypeFieldRef={orderTypeFieldRef}
                        startDate={startDate}
                        setstartDate={setstartDate}
                        endDate={endDate}
                        setendDate={setendDate}
                        setshowPrintModal={setshowPrintModal}
                    />

                    <div className="flex  h-[70%] w-full flex-row gap-2 ">
                        <div className="h-[98%] w-[70%] ">
                            <DataTable
                                rows={items}
                                setItemCardModal={setItemCardModal}
                                settempItemCode={settempItemCode}
                                selectedCur={selectedCur}
                                selectedBy={selectedBy}
                                selectedGroupBy={selectedGroupBy}
                                multicolumns={multicolumns}
                            />
                        </div>
                        <div className=" flex w-[30%] flex-row items-center  justify-center ">
                            <ItemData
                                selectedCur={selectedCur}
                                currencyList={currencyList}
                                getData={getData}
                                setselectedCur={setselectedCur}
                                filters={filters}
                                data={data}
                                allowEdit={allowEdit}
                                selectedBy={selectedBy}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            {itemCardModal ? (
                <ItemCard
                    modalShow={itemCardModal}
                    setModalShow={setItemCardModal}
                    postdata={props.postdata}
                    itemcode={tempItemCode}
                    columns={columns}
                    onHideFunc={() => {}}
                />
            ) : null}
            {showPrintModal && (
                <ReportModal
                    showPrintModal={showPrintModal}
                    setshowPrintModal={setshowPrintModal}
                    data={{
                        filters: filters,
                        data: data,
                        startDate: startDate,
                        endDate: endDate,
                        selectedCur: selectedCur,
                        selectedBy: selectedBy,
                        selectedGroupBy: selectedGroupBy,
                        limit: limitFieldRef.current.value,
                        orderby: orderByFieldRef.current.value,
                        ordertype: orderTypeFieldRef.current.value,
                        groupby: selectedGroupBy,
                    }}
                    reportId={"stock_overview"}
                    postdata={props.postdata}
                    onPrintFunc={() => {}}
                />
            )}
        </>
    );
    function getData() {
        setisLoading(true);
        let url = "goods/stock_overview/";
        const cbnf = (res) => {
            if (res.data.msg === " Item(s) Not Found or Inactive") {
            } else if (res.data.info === "success") {
                setitems(res.data.data.items);
                setdata(res.data.data.totals[0]);
                allowEdit && setallowEdit(false);
            } else {
                clearData();
            }
            setisLoading(false);
        };
        const fdata = {
            filters: filters,
            currency: selectedCur.code,
            startdate: startDate,
            enddate: endDate,
            by: selectedBy,
            limit: limitFieldRef.current.value,
            orderby: orderByFieldRef.current.value,
            ordertype: orderTypeFieldRef.current.value,
            groupby: selectedGroupBy,
        };

        props.postdata(url, cbnf, fdata, false);
    }

    function clearData() {
        setitems([]);
        setdata({
            purchase_qty: 0,
            sales_qty: 0,
            sales_return_qty: 0,
            purchase_return_qty: 0,
            total_qty: 0,
            total_purchase: 0,
            total_sales: 0,
            total_sales_return: 0,
            total_purchase_return: 0,
            capital: 0,
            stock_value: 0,
            sale_profit: 0,
            symbol: "$",
        });
        setallowEdit(true);
    }
}
