import TextField from "../../app/TextField";
import Box from "@mui/material/Box";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import React from "react";
import DataTable from "../../app/DataTable";

export default function DepartmentTab(props) {
    const columns = [
        {
            field: "id",
            hideable: false,
            headerName: "Number",
            flex: 1,
        },
        {
            field: "name",
            editable: true,
            hideable: false,
            headerName: "Name",
            flex: 3,
        },
        {
            field: "description",
            editable: true,
            hideable: false,
            headerName: "Description",
            flex: 10,
        },
    ];
    const [selectedRow, setselectedRow] = useState({});

    const [num, setnum] = useState("");
    const [name, setname] = useState("");
    return (
        <div className="h-full w-full">
            <Box sx={{ height: "20rem", width: "100%", border: 2 }}>
                <DataTable
                    rows={props.branches}
                    columns={columns}
                    rowsPerPageOptions={[10, 100, 1000]}
                    columnHeaderHeight={45}
                    rowHeight={30}
                    showColumnVerticalBorder
                    showCellVerticalBorder
                    hideFooter
                    getRowId={(e) => {
                        return e.id;
                    }}
                    slotProps={{
                        cell: { id: "datagridcell" },
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    onCellEditStart={(e) => {
                        setTimeout(() => {
                            setselectedRow(e);
                        }, 100);
                    }}
                    disableColumnSelector
                    processRowUpdate={(e) => {
                        if (e[selectedRow.field] !== selectedRow.value) {
                            editDepartment(e[selectedRow.field]);
                        }
                        return e;
                    }}
                    onProcessRowUpdateError={(e) => {
                        props.getSystemSettings();
                    }}
                    onCellKeyDown={(e, f) => {}}
                />
            </Box>
            <div className="m-1 ">
                <Accordion>
                    <AccordionSummary
                        className="bg-zinc-200"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className=" font-semibold ">Create New Department</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-row items-center justify-start align-middle ">
                            <TextField
                                size="small"
                                inputProps={{ className: "p-2" }}
                                label={"Name"}
                                sx={{ marginX: 3 }}
                                value={name}
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                    }
                                }}
                                onChange={(e) => {
                                    setname(e.target.value);
                                }}
                                id="name"
                            />
                            <Button
                                onClick={() => {
                                    addDepartment();
                                }}
                                variant="outlined"
                                sx={{ margin: 1 }}
                            >
                                create
                            </Button>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
    function editDepartment(value) {
        let url = "settings/edit_department/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
            }
        };
        let fdata = {
            num: selectedRow.row.num,
            field: selectedRow.field,
            value: value,
        };

        props.postdata(url, cbnf, fdata, false);
    }

    function addDepartment() {
        let url = "settings/add_department/";
        const cbnf = (res) => {
            props.getSystemSettings();
        };
        let fdata = {
            num: num,
            name: name,
        };

        props.postdata(url, cbnf, fdata, false);
    }
}
