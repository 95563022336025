import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
export default function Datatable({ cols, rows, rowId }) {
    const dghcn = "  font-semibold !text-black";
    const columns = [...cols];
    const data = [...rows];
    // const columns = [{ field: "id", headerName: "ID", width: 70 }];
    // const rows = [{ id: "1" }];
    return (
        <Box sx={{ height: "100%", width: "100%", marginY: 2 }}>
            <DataGrid
                sx={{
                    "& .MuiDataGrid-editInputCell": { color: "#000000 !important" },
                    "& .MuiDataGrid-columnHeaderTitle": { color: "#000000 !important", fontWeight: "semi-bold" },
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": { backgroundColor: "#424242" },
                    "& .MuiDataGrid-cell:focus": {
                        borderWidth: " 1px !important",
                        borderColor: "blue !important",
                        outline: "3px auto blue",
                    },
                    "& .MuiDataGrid-withBorderColor ": {
                        borderColor: "black",
                    },
                    borderColor: "black !important",
                }}
                getRowClassName={(e) => {
                    if (e.indexRelativeToCurrentPage % 2 !== 0) {
                        return " bg-zinc-100    ";
                    }
                }}
                rows={data}
                columns={columns}
                rowsPerPageOptions={[10, 100, 1000]}
                checkboxSelection={false}
                columnHeaderHeight={45}
                rowHeight={35}
                showColumnVerticalBorder
                showCellVerticalBorder
                isRowSelectable={(e) => {
                    return false;
                }}
                hideFooter={true}
                getRowId={(e) => {
                    let id = "";
                    rowId.forEach((el) => {
                        id += e[el].toString();
                    });
                    return id;
                    // return rowId(e);
                }}
                editMode="cell"
                disableColumnSelector
                disableColumnMenu
            />
        </Box>
    );
}
