import { GridApiCommunity } from "@mui/x-data-grid/internals";
import DataTable from "../../../app/DataTable";
import React, { MutableRefObject, useEffect, useState } from "react";
import { TMultiData } from "./MultidataConfigurationModal";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

export default function DataTableContainer(props: TDataTableContainerProps) {
    const [data, setData] = useState([]);

    const gridApiRef = useGridApiRef();

    props.dataGridApiRef.current = {
        api: {
            data,
            setdata: setData,
        },
        gridApiRef: gridApiRef,
    };
    return (
        <div className="flex h-full w-full flex-row">
            <DataTable
                rows={data}
                columns={cols()}
                apiRef={gridApiRef}
                onFieldEdit={(r, v) => {
                    props.editDatabase(r, v);
                }}
            />
        </div>
    );

    function cols(): GridColDef[] {
        return [
            {
                field: "id",
                headerName: "ID",
                flex: 1,
            },
            {
                field: "host",
                headerName: "Host",
                flex: 1,
                editable: true,
            },
            {
                field: "port",
                headerName: "Port",
                flex: 1,
                editable: true,
            },
            {
                field: "username",
                headerName: "Username",
                flex: 1,
                editable: true,
            },

            {
                field: "dbname",
                headerName: "DB Name",
                flex: 1,
                editable: true,
            },
            {
                field: "active",
                headerName: "Is Active",
                flex: 1,
                editable: true,
            },
        ];
    }
}

type TDataTableContainerProps = {
    dataGridApiRef?: React.MutableRefObject<TDataTableApi | null>;
    editDatabase?: (r, v) => void;
};

export type TDataTableApi = {
    api?: {
        data: TMultiData[];
        setdata: React.Dispatch<React.SetStateAction<TMultiData[]>>;
    };
    gridApiRef: MutableRefObject<GridApiCommunity>;
};
