import TextField from "../../../../app/TextField";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import CreateOtherBarcodeModal from "./CreateOtherBarcodeModal";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import QuickEditModal from "../../goodsTable/QuickEditModal";
import Modal from "../../../../app/Modal";

export default function OtherBarcodeTab(props) {
    const [showTransferOtherBarcodeModal, setshowTransferOtherBarcodeModal] = useState(false);
    const [showCreateOtherBarcodeModal, setShowCreateOtherBarcodeModal] = useState(false);

    const [isediting, setisediting] = useState(false);

    const [selectedRow, setselectedRow] = useState({});

    const [showQuickItemEdit, setshowQuickItemEdit] = useState(false);

    const [mainBarcode, setmainBarcode] = useState("");
    const [errorMsg, seterrorMsg] = useState("");

    return (
        <>
            <div className="flex h-full flex-row p-1 ">
                <div className="h-full w-[90%] " style={{ width: props.type === "Otherbarcode" ? "99%" : "90%" }}>
                    <div className="  h-full ">
                        <Box
                            sx={{
                                width: "99%",
                                height: "100%",
                                border: 4,
                                borderColor: "primary",
                                borderRadius: 2,
                                marginX: "auto",
                            }}
                        >
                            <DataGrid
                                sx={{
                                    "& .MuiDataGrid-cell:focus": {
                                        borderWidth: " 1px !important",
                                        borderColor: "blue !important",
                                        outline: "3px auto blue",
                                    },
                                }}
                                rows={props.otherCodeData}
                                columns={props.columns}
                                // pageSize={9}
                                rowsPerPageOptions={[10, 100, 1000]}
                                showCellVerticalBorder
                                showColumnVerticalBorder
                                hideFooter
                                headerHeight={40}
                                rowHeight={30}
                                getRowId={(e) => {
                                    return e.itemcode;
                                }}
                                slotProps={{
                                    cell: { id: "datagridcell", className: " !border-gray-500 " },
                                }}
                                onCellDoubleClick={(e) => {
                                    quickEditHandler(e);
                                }}
                                experimentalFeatures={{ newEditingApi: true }}
                                onCellEditStart={(e) => {
                                    setselectedRow(e);

                                    setisediting(true);
                                }}
                                processRowUpdate={(e) => {
                                    setisediting(false);
                                    if (e[selectedRow.field] !== selectedRow.value) {
                                        editOtherbarcode(e[selectedRow.field]);
                                    }
                                    return e;
                                }}
                                onProcessRowUpdateError={() => {
                                    props.getItemByCode(props.maincode);
                                }}
                                onCellKeyDown={(e, f) => {
                                    if (f.code === "Enter") {
                                        quickEditHandler(e);
                                        if (isediting) {
                                            f.nativeEvent = new KeyboardEvent("keydown", { key: "Tab" });
                                            f.key = "Tab";
                                            f.code = "Tab";
                                            document.activeElement.dispatchEvent(new KeyboardEvent("keydown", { key: "Tab" }));
                                            return f;
                                        }
                                    }
                                    if (f.code === "F2") {
                                        setselectedRow(e);

                                        props.getItemByCode(e.row.itemcode);
                                    }
                                }}
                            />
                        </Box>
                    </div>
                </div>
                {props.type !== "Otherbarcode" ? (
                    <div className="flex w-[10%] flex-col justify-evenly">
                        <Button
                            variant="outlined"
                            onClick={(e) => {
                                setShowCreateOtherBarcodeModal(true);
                            }}
                        >
                            Add Other Barcode
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={(e) => {
                                setshowTransferOtherBarcodeModal(true);
                                seterrorMsg("");
                            }}
                        >
                            Transfer Item To Other Barcode
                        </Button>
                    </div>
                ) : null}
            </div>

            {showCreateOtherBarcodeModal ? (
                <CreateOtherBarcodeModal
                    show={showCreateOtherBarcodeModal}
                    setShow={setShowCreateOtherBarcodeModal}
                    updateItemData={props.updateItemData}
                    postdata={props.postdata}
                    maincode={props.maincode}
                />
            ) : null}
            {showQuickItemEdit ? (
                <QuickEditModal
                    show={showQuickItemEdit}
                    setShow={setshowQuickItemEdit}
                    postdata={props.postdata}
                    selectedRow={selectedRow}
                    attributes={props.attributes}
                    editItem={editOtherbarcode}
                />
            ) : null}
            {/* ------------------- Transfer To Other Barcode Modal ------------------- */}
            {showTransferOtherBarcodeModal ? (
                <Modal
                    show={showTransferOtherBarcodeModal}
                    width="40rem"
                    height="20rem"
                    setshow={setshowTransferOtherBarcodeModal}
                    title="Transfer This Item To Other Barcode"
                >
                    <div className="flex h-full w-full flex-col justify-between p-2 ">
                        <div className="flex w-full flex-row items-center justify-center">
                            <TextField
                                value={mainBarcode}
                                label="Main Barcode"
                                variant="outlined"
                                onChange={(e) => {
                                    setmainBarcode(e.target.value);
                                }}
                            />
                            <Button variant="outlined" onClick={transferItemToOtherBarcode}>
                                Confirm
                            </Button>
                            <div className="mx-4 text-xl text-red-500">{errorMsg}</div>
                        </div>
                        <div className="flex flex-col ">
                            <div>* All Transactions On This Item Will Be Transferd To The Main Barcode Above (Stock - In Out - Accounting)</div>
                            <div>* Otherbarcode On This Item Will Also Be Transferd To The Main Code</div>
                            <div>* A Backup Will Be Created Uppon Confirmation</div>
                        </div>
                    </div>
                </Modal>
            ) : // <Modal
            //     show={showTransferOtherBarcodeModal}
            //     onHide={() => {
            //         setshowTransferOtherBarcodeModal(false);
            //     }}
            //     centered
            //     animation={false}
            //     id={"TransferToOtherBarcodeModal"}
            //     enforceFocus={false}
            //     backdropClassName="bg-red-800"
            //     size={"lg"}
            //     style={{ backgroundColor: "#959595ba" }}
            // >
            //     <Modal.Dialog
            //         bsPrefix=" h-[15rem]"
            //         className="h-[15rem] rounded border-4 border-neutral-700 bg-zinc-800 !text-[#c7c7c7] shadow-2xl"
            //     >
            //         <div className="flex h-[14.7rem] flex-col justify-between p-2 ">
            //             <h4>Transfer This Item To Other Barcode</h4>
            //             <div className="flex flex-row items-center justify-start">
            //                 <TextField
            //                     value={mainBarcode}
            //                     label="Main Barcode"
            //                     variant="outlined"
            //                     sx={{ color: "#c7c7c7 !important", marginX: 5 }}
            //                     InputProps={{ sx: { color: "#c7c7c7 !important" } }}
            //                     InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
            //                     onChange={(e) => {
            //                         setmainBarcode(e.target.value);
            //                     }}
            //                 />
            //                 <Button variant="outlined" onClick={transferItemToOtherBarcode}>
            //                     Confirm
            //                 </Button>
            //                 <div className="mx-4 text-xl text-red-500">{errorMsg}</div>
            //             </div>
            //             <div className="flex flex-col ">
            //                 <div>
            //                     * All Transactions On This Item Will Be Transferd To The Main Barcode Above (Stock - In
            //                     Out - Accounting)
            //                 </div>
            //                 <div>* Otherbarcode On This Item Will Also Be Transferd To The Main Code</div>
            //                 <div>* A Backup Will Be Created Uppon Confirmation</div>
            //             </div>
            //         </div>
            //     </Modal.Dialog>
            // </Modal>
            null}
        </>
    );

    function quickEditHandler(e) {
        setselectedRow(e);

        if (e.field === "itemcode") {
            props.getItemByCode(e.row.itemcode);
        } else if (["type", "category", "set", "brand", "color", "unit", "family"].includes(e.field)) {
            setshowQuickItemEdit(true);
        }
    }
    function editOtherbarcode(newval) {
        let url = "goods/edit_item/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setshowQuickItemEdit(false);
                props.setOtherCodeData((d) => {
                    let temp = [...d];
                    temp.forEach((itm, idx) => {
                        if (itm.itemcode === res.data.item.itemcode) {
                            temp[idx] = res.data.item;
                        }
                        return temp;
                    });
                    return temp;
                });
            } else {
                props.getItemByCode(selectedRow.row.itemcode);
            }
        };
        const data = {
            id: selectedRow.row.itemcode,
            field: selectedRow.field,
            value: newval,
        };
        props.postdata(url, cbnf, data, false);
    }
    function transferItemToOtherBarcode() {
        seterrorMsg("");
        let url = "goods/transfer_to_otherbarcode/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                seterrorMsg(res.data.msg);
                setTimeout(() => {
                    setshowTransferOtherBarcodeModal(false);
                }, 2000);
                document.getElementById("cardRefresh").click();
            }
        };
        const data = {
            itemcode: props.maincode,
            maincode: mainBarcode,
        };
        props.postdata(url, cbnf, data, false);
    }
}
