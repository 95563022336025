import TextField from "../../app/TextField";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import React from "react";

const dghcn = " border-y-[3px]  border-neutral-400 font-bold text-md text-[#c7c7c7] ";

export default function BranchesTab(props) {
    const columns = [
        {
            field: "num",
            headerClassName: dghcn,
            hideable: false,
            headerName: "Number",
            width: 110,
        },
        {
            field: "name",
            editable: true,
            headerClassName: dghcn,
            hideable: false,
            headerName: "Name",
            width: 300,
        },
    ];
    const [selectedRow, setselectedRow] = useState({});

    const [num, setnum] = useState("");
    const [name, setname] = useState("");
    return (
        <div className="h-full w-full">
            <Box sx={{ height: "20rem", width: "100%", border: 2 }}>
                <DataGrid
                    sx={{
                        "& .MuiDataGrid-editInputCell": { color: "#c7c7c7 !important" },
                        "& .MuiDataGrid-cell:focus": {
                            borderWidth: " 1px !important",
                            borderColor: "blue !important",
                            outline: "3px auto blue",
                        },
                        "& .MuiTablePagination-root": { color: "#c7c7c7 !important" },
                    }}
                    rows={props.branches}
                    columns={columns}
                    rowsPerPageOptions={[10, 100, 1000]}
                    columnHeaderHeight={45}
                    rowHeight={30}
                    showColumnVerticalBorder
                    showCellVerticalBorder
                    getRowClassName={(e) => {
                        return "  text-[#c7c7c7]";
                    }}
                    hideFooter
                    getRowId={(e) => {
                        return e.num;
                    }}
                    slotProps={{
                        cell: { id: "datagridcell", className: " !border-gray-500 " },
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    onCellEditStart={(e) => {
                        setTimeout(() => {
                            setselectedRow(e);
                        }, 100);
                    }}
                    disableColumnSelector
                    processRowUpdate={(e) => {
                        if (e[selectedRow.field] !== selectedRow.value) {
                            editBranch(e[selectedRow.field]);
                        }
                        return e;
                    }}
                    onProcessRowUpdateError={(e) => {
                        props.getSystemSettings();
                    }}
                    onCellKeyDown={(e, f) => {
                        // if (f.key === "Enter") {
                        //     //  quickEditHandler(e);
                        //     if (f.target.id !== "datagridcell") {
                        //         f.nativeEvent = new KeyboardEvent("keydown", { key: "Tab" });
                        //         f.key = "Tab";
                        //         f.code = "Tab";
                        //         document.activeElement.dispatchEvent(new KeyboardEvent("keydown", { key: "Tab" }));
                        //     } else if (f.target.id === "datagridcell") {
                        //         let temp = columns.filter((column) => column.show);
                        //         if (e.field === temp[temp.length - 1].field) {
                        //             let focusNum = e.id;
                        //             if (props.data.items.length === e.id) {
                        //                 focusNum = focusNum - 1;
                        //             }
                        //             props.dataTableRef.current.children[0].children[1].children[0].children[0].children[
                        //                 focusNum
                        //             ].children[1].focus();
                        //         } else {
                        //             f.nativeEvent = new KeyboardEvent("keydown", { key: "ArrowRight" });
                        //             f.key = "ArrowRight";
                        //             f.code = "ArrowRight";
                        //             document.activeElement.dispatchEvent(
                        //                 new KeyboardEvent("keydown", { key: "ArrowRight" })
                        //             );
                        //         }
                        //     }
                        //     return f;
                        // } else if (f.key === "F2") {
                        //     props.setselectedRow(e);
                        //     props.setItemCardModal(true);
                        // }
                    }}
                />
            </Box>
            <div className="m-1 ">
                <Accordion style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}>
                    <AccordionSummary
                        className="bg-zinc-200"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}
                    >
                        <div className=" font-semibold !text-[#c7c7c7]">Create New Branch</div>
                    </AccordionSummary>
                    <AccordionDetails style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}>
                        <div className="flex flex-row items-center justify-start align-middle ">
                            <TextField
                                InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                size="small"
                                inputProps={{ className: "p-2" }}
                                label={"Number"}
                                sx={{ marginX: 3 }}
                                value={num}
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                    }
                                }}
                                onChange={(e) => {
                                    setnum(e.target.value);
                                }}
                                id="Num"
                            />
                            <TextField
                                InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                size="small"
                                inputProps={{ className: "p-2" }}
                                label={"Name"}
                                sx={{ marginX: 3 }}
                                value={name}
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                    }
                                }}
                                onChange={(e) => {
                                    setname(e.target.value);
                                }}
                                id="name"
                            />
                            <Button
                                onClick={() => {
                                    addbranch();
                                }}
                                variant="outlined"
                                sx={{ margin: 1 }}
                            >
                                create
                            </Button>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
    function editBranch(value) {
        let url = "settings/edit_branch/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
            }
        };
        let fdata = {
            num: selectedRow.row.num,
            field: selectedRow.field,
            value: value,
        };

        props.postdata(url, cbnf, fdata, false);
    }

    function addbranch() {
        let url = "settings/add_branch/";
        const cbnf = (res) => {
            props.getSystemSettings();
        };
        let fdata = {
            num: num,
            name: name,
        };

        props.postdata(url, cbnf, fdata, false);
    }
}
