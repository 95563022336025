import Box from "@mui/material/Box";
import DataTable from "./DataTable";
import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import Modal from "./Modal";
import { asyncPostData } from "../../lib/Axios";
import { useGridApiRef } from "@mui/x-data-grid";

export default function DataGridModal2(props: TDataGridModalProps) {
    const [data, setdata] = useState([]);
    const [cols, setcols] = useState([
        {
            field: "",
            headerName: "",
            width: 200,
            editable: false,
        },
    ]);
    const [isLoading, setisLoading] = useState(true);

    const dataTableRef = useRef();

    const [show, setShow] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [title, setTitle] = useState("");

    const item = {
        query: (phrase) => {
            phrase = phrase.replace(/ /g, "%");
            return `itemcode as 'Item Code',originalnumber as "Original Number",itemname1 as "Item Name",\`set\` as "Set",category as "Category",sale1 as "Sale 1",sale2 as "Sale 2",sale3 as "Sale 3",sale4 as "sale 4" FROM ${localStorage.getItem(
                "dbname"
            )}.goods where (itemcode like '%${phrase}' or itemcode like '${phrase}%' or itemcode like '%${phrase}%') or (originalnumber like '%${phrase}' or originalnumber like '${phrase}%' or originalnumber like '%${phrase}%') or (itemname1 like '%${phrase}' or itemname1 like '${phrase}%' or itemname1 like '%${phrase}%') or (itemname2 like '%${phrase}' or itemname2 like '${phrase}%' or itemname2 like '%${phrase}%')`;
        },
        title: "Similar Item Code / Original Number / Item Name 1 / Item Name 2",
    };
    const account = {
        query: (phrase) => {
            return `
            id as 'Acc Id',name1 as "Name 1",name2 as "Name 2",tax_number as "Tax Number",\`cur\` as "Currency",category as "Category" ,phone1 as "Phone 1",phone2 as "Phone 2"
            FROM ${localStorage.getItem("dbname")}.accounts 
            where ((\`id\` like '${phrase}%' ) or tax_number like '%${phrase}%' or (name1 like '%${phrase}' or name1 like '${phrase}%' or name1 like '%${phrase}%') or (name2 like '%${phrase}' or name2 like '${phrase}%' or name2 like '%${phrase}%') or (cur like '%${phrase}' or cur like '${phrase}%' or cur like '%${phrase}%') or (phone1 like '%${phrase}' or phone1 like '${phrase}%' or phone1 like '%${phrase}%') or (phone2 like '%${phrase}' or phone2 like '${phrase}%' or phone2 like '%${phrase}%') ) and active = 'Y'`;
        },
        title: "Similar Account Id / Account Name 1 / Account Name 2 / Account Currency / Phone Number ",
    };

    props.dataGridAPI.show = showModal;

    const dataTableAPIRef = useGridApiRef();

    useEffect(() => {
        data.length > 0 && dataTableAPIRef.current.setCellFocus(data[0][cols[0].field], cols[0].field);
    }, [data]);

    return (
        <Modal
            show={show}
            setshow={() => {
                props.dataGridAPI.onExitPress?.();
                setShow(false);
                setdata([]);
            }}
            onTransitionEnter={() => {}}
            id={"DataGridModal"}
            disableEnforceFocus
            disableAutoFocus
            disableRestoreFocus={false}
            title={`${title} \n for "${searchPhrase}"`}
            width="90vw"
            height="90vh"
        >
            <div className=" h-full p-2">
                <Box sx={{ height: "100%", width: "100%" }}>
                    <DataTable
                        rows={data}
                        gridRef={dataTableRef}
                        apiRef={dataTableAPIRef}
                        columns={cols}
                        columnHeaderHeight={45}
                        rowHeight={35}
                        slotProps={{
                            cell: { id: "datagridcell" },
                        }}
                        showColumnVerticalBorder
                        showCellVerticalBorder
                        hideFooter
                        getRowId={(e) => {
                            return e[cols[0].field];
                        }}
                        onCellDoubleClick={(e) => {
                            props.dataGridAPI.onEnterPress(e.row[cols[0].field]);
                            setdata([]);
                            setShow(false);
                        }}
                        editMode="cell"
                        onCellKeyDown={(e, f) => {
                            if (f.key === "Enter") {
                                props.dataGridAPI.onEnterPress(e.row[cols[0].field]);
                                setdata([]);
                                setShow(false);
                            }
                        }}
                    />
                </Box>
            </div>
        </Modal>
    );
    function selectQuary(quary) {
        const url = "invoice/custom_select/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setcols((e) => {
                    const temp = [];
                    res.data.data.cols.forEach((element) => {
                        temp.push({
                            field: element,
                            headerName: element,
                            width: 200,
                            editable: false,
                        });
                    });
                    return temp;
                });
                setdata(res.data.data.data);
            }
        };
        const fdata = {
            quary: quary,
        };
        asyncPostData(url, cbnf, fdata, false);
    }
    function showModal(type, searchPhrase) {
        setTitle(type === "account" ? account.title : item.title);
        setSearchPhrase(searchPhrase);
        selectQuary(type === "account" ? account.query(searchPhrase) : item.query(searchPhrase));
        setShow(true);
    }
}
type TDataGridModalProps = {
    dataGridAPI: TDataGridModalAPI;
};

export type TDataGridModalAPI = {
    show?: (type: "account" | "item", searchPhrase: string) => void;
    onEnterPress?: (id: string) => void;
    onExitPress?: () => void;
};
