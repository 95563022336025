import TextField from "../../app/TextField";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "../../app/Select";

export default function CreateNewUser(props) {
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [password1, setpassword1] = useState("");
    const [rank, setrank] = useState(6);
    const [errormsg, seterrormsg] = useState("");

    const [adminRanks, setadminRanks] = useState([]);

    useEffect(() => {
        getAdminsRanks();
    }, []);

    return (
        <div className="flex h-[29rem] flex-col items-center justify-evenly">
            <div className="flex flex-row gap-10">
                <div>
                    <TextField
                        label="User Name"
                        value={username}
                        onChange={(e) => {
                            setusername(e.target.value);
                        }}
                        sx={{ width: 200 }}
                        size="small"
                    />
                </div>
                <div>
                    <Select
                        value={rank}
                        onChange={(e) => {
                            setrank(e.target.value);
                        }}
                        sx={{ width: 200 }}
                        menuLabels={["order", "name"]}
                        menuValue={"order"}
                        label={"Rank"}
                        options={adminRanks}
                        allowNone={false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-10">
                <div>
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setpassword(e.target.value);
                        }}
                        sx={{ width: 200 }}
                        size="small"
                    />
                </div>
                <div>
                    <TextField
                        label="Confirm Password"
                        type="password"
                        value={password1}
                        onChange={(e) => {
                            setpassword1(e.target.value);
                        }}
                        sx={{ width: 200 }}
                        size="small"
                    />
                </div>
            </div>
            <div>
                <Button
                    onClick={(e) => {
                        createNewUser();
                    }}
                    variant="outlined"
                >
                    CREATE
                </Button>
            </div>
            <div className="h-5  font-semibold text-red-600">{errormsg}</div>
        </div>
    );
    function createNewUser() {
        seterrormsg("");
        if (password !== password1) {
            seterrormsg("Passwords Dont Match");
        } else {
            let url = "admins/create_new_admin/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    insertPrivilege(username, rank);
                    setpassword("");
                    setpassword1("");
                    setrank(6);
                    setusername("");
                    seterrormsg("");
                }
            };

            let fdata = {
                name: username,
                password: password,
                rank: rank,
            };
            props.postdata(url, cbnf, fdata, true);
        }
    }

    function insertPrivilege(name, rank) {
        let url = "insert_privilege/";
        const cbnf = (res) => {};

        let fdata = {
            name: name,
            rank: rank,
        };
        props.postdata(url, cbnf, fdata, false);
    }

    function getAdminsRanks() {
        let url = "admins/get_admins_ranks/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setadminRanks(res.data.data);
            }
        };

        props.postdata(url, cbnf, {}, false);
    }
}
