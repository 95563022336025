import TextField from "../../app/TextField";
import React,{ useState, useEffect } from "react";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function UserList(props) {
    useEffect(() => {
        getUserList();
    }, []);

    const [userList, setuserList] = useState([]);

    const cUser = JSON.parse(window.localStorage.getItem("admin"));

    const [temp, settemp] = useState("");

    const [adminData, setadminData] = useState({
        name: "",
        date_created: "",
        last_login: "",
        rank: 1,
        rank_name: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        job_description: "",
        phone_number1: "",
        phone_number2: "",
        emergency_number: "",
        system_language: "en",
        note: "",
    });

    const commonAttr = {
        InputProps: { sx: { color: "#c7c7c7 !important" } },
        InputLabelProps: { sx: { color: "#c7c7c7 !important" } },
        size: "small",
        onFocus: (e) => {
            settemp(e.target.value);
            e.target.select();
        },
        onChange: (e) => {
            settemp(e.target.value);
        },
        inputProps: { className: "p-2" },
    };

    return (
        <div className=" m-2 flex h-[29rem] flex-row  rounded border-2 border-gray-500">
            <div className="flex w-[10rem] flex-col justify-between border-r-2 border-gray-500">
                <div className="border-b-2 border-gray-500 py-1 text-center text-2xl font-semibold">Users</div>
                <div className="flex h-full flex-col overflow-y-auto ">
                    {userList.map((nm) => {
                        return (
                            <div className=" m-2 w-[9rem]" key={nm.name}>
                                <Button
                                    sx={{ width: "100%" }}
                                    size="small"
                                    color={nm.name === adminData.name ? "primary" : "success"}
                                    variant="outlined"
                                    onClick={() => {
                                        getUserData(nm.name);
                                    }}
                                    disabled={cUser.rank > nm.rank && cUser.name !== nm.name}
                                >
                                    {nm.name}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="flex h-full w-full flex-col p-2 ">
                <div className="w-full">
                    <div className="my-4 flex flex-row items-center  justify-around px-1">
                        <TextField
                            {...commonAttr}
                            label={"First Name"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "first_name" ? temp : adminData.first_name}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("last_name").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("first_name", e.target.value);
                            }}
                            id="first_name"
                        />
                        <TextField
                            {...commonAttr}
                            label={"Last Name"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "last_name" ? temp : adminData.last_name}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("date_of_birth").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("last_name", e.target.value);
                            }}
                            id="last_name"
                        />
                        <TextField
                            {...commonAttr}
                            label={"Date Of Birth"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "date_of_birth" ? temp : adminData.date_of_birth}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("phone_number1").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("date_of_birth", e.target.value);
                            }}
                            id="date_of_birth"
                        />
                    </div>
                    <div className="my-4 flex flex-row items-center  justify-around px-1">
                        <TextField
                            {...commonAttr}
                            label={"Phone Number 1"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "phone_number1" ? temp : adminData.phone_number1}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("phone_number2").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("phone_number1", e.target.value);
                            }}
                            id="phone_number1"
                        />
                        <TextField
                            {...commonAttr}
                            label={"Phone Number 2"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "phone_number2" ? temp : adminData.phone_number2}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("emergency_number").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("phone_number2", e.target.value);
                            }}
                            id="phone_number2"
                        />
                        <TextField
                            {...commonAttr}
                            label={"Emergency Number"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "emergency_number" ? temp : adminData.emergency_number}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("job_description").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("emergency_number", e.target.value);
                            }}
                            id="emergency_number"
                        />
                    </div>
                    <div className="my-4 flex flex-row items-center  justify-around px-1">
                        <TextField
                            {...commonAttr}
                            label={"Job Description"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "job_description" ? temp : adminData.job_description}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("note").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("job_description", e.target.value);
                            }}
                            id="job_description"
                        />
                        <TextField
                            {...commonAttr}
                            label={"Note"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={document.activeElement.id === "note" ? temp : adminData.note}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("first_name").focus();
                                }
                            }}
                            onBlur={(e) => {
                                editUser("note", e.target.value);
                            }}
                            id="note"
                        />
                        <TextField
                            {...commonAttr}
                            label={"Date Created"}
                            sx={{ marginX: 3, width: "30%" }}
                            value={adminData.date_created}
                            disabled
                            id="date_created"
                        />
                    </div>
                    <div className="my-4 flex flex-row items-center  justify-around px-1">
                        <FormControl sx={{ width: 100 }} size="small">
                            <InputLabel sx={{ color: "#c7c7c7 !important" }} id="demo-simple-select-label">
                                Language
                            </InputLabel>
                            <Select
                                sx={{ color: "#c7c7c7 !important" }}
                                MenuProps={{
                                    sx: { color: "#c7c7c7 !important" },
                                    PaperProps: {
                                        style: {
                                            backgroundColor: "#212529",
                                        },
                                    },
                                }}
                                slotProps={{ sx: { color: "#c7c7c7 !important", backgroundColor: "#212529" } }}
                                inputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                value={adminData.system_language}
                                label="Language"
                                id="Language"
                                name="Language"
                                onChange={(e, f) => {}}
                            >
                                {["en"].map((el) => {
                                    return (
                                        <MenuItem
                                            sx={{
                                                color: "#c7c7c7 !important",
                                                backgroundColor: "#212529",
                                                ":hover": { backgroundColor: "#30353a" },
                                            }}
                                            key={el}
                                            value={el}
                                        >
                                            {el}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    );
    function getUserList() {
        let url = "admins/get_admins_name/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setuserList(res.data.data);
                getUserData(cUser.name)
            }
        };

        props.postdata(url, cbnf, {}, false);
    }
    function getUserData(username) {
        let url = "admins/get_admin_data/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setadminData(res.data.data.admin_data);
            }
        };
      
        const fdata = {
            username: username,
        };

        props.postdata(url, cbnf, fdata, false);
    }

    function editUser(field, value) {
        if (adminData[field] !== value) {
            setadminData((old) => {
                let ttemp = { ...old };
                ttemp[field] = value;
                return ttemp;
            });
            let url = "admins/edit_user/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                } else {
                    getUserData(adminData.name);
                }
            };
            const fdata = { field: field, value: value, user: adminData.name };
            setTimeout(() => {
                props.postdata(url, cbnf, fdata, false);
            }, 200);
        }
    }
}
