import React, { useState, useEffect } from "react";
import Tabs from "../../app/Tabs";
import Modal from "../../app/Modal";
import SystemSettings from "./SystemSettings";
import { getJsonStorage, getStorage } from "../../app/globalfunctions";
import UserSettings from "./UserSettings";
import OtherSettingsTab from "./OtherSettings";

export default function GeneralSettingsModal(props) {
    const [value, setValue] = useState(1);

    return (
        <Modal
            show={props.generalSettingsModal}
            setshow={props.setgeneralSettingsModal}
            id={"GeneralSettingsModal"}
            disableRestoreFocus={true}
            disableEnforceFocus={true}
            disableEscapeKeyDown={true}
            disableAutoFocus={true}
            width="85vw"
            height="75vh"
            defaultHeader={true}
            title={`General Settings -- ${getJsonStorage("admin")["name"]} -- ${getJsonStorage("admin")["pc_info"]['data']["system_name"]}`}
        >
            <Tabs
                value={value}
                setvalue={setValue}
                tabsList={[
                    { value: 1, label: "System Settings" },
                    { value: 2, label: "User Settings" },
                    { value: 3, label: "Other Settings" },
                ]}
                padding="0px"
                boxBorder="0px"
            >
                {value === 1 ? <SystemSettings postdata={props.postdata} /> : null}
                {value === 2 ? <UserSettings postdata={props.postdata} /> : null}
                {value === 3 ? <OtherSettingsTab postdata={props.postdata} /> : null}
            </Tabs>
        </Modal>
    );
}

/* <Tabs id="justify-tab-example" className="mb-3" justify>
                                <Tab
                                    tabClassName=""
                                    eventKey="systemSettings"
                                    title="System Settings"
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <div className="mx-2 mb-2 h-[33rem] rounded-lg ">
                                        <SystemSettings postdata={props.postdata} />
                                    </div>
                                </Tab>
                                <Tab
                                    tabClassName=""
                                    eventKey="userSettings"
                                    title="User Settings"
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <div className="mx-2 h-[33rem] rounded-lg ">
                                        <UserSettings postdata={props.postdata} />
                                    </div>
                                </Tab>
                                <Tab
                                    tabClassName=" "
                                    eventKey="otherSettings"
                                    title="Other Settings"
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <OtherSettingsTab postdata={props.postdata} />
                                </Tab>
                            </Tabs> */
