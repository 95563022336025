import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

export default function MultiSelect(props: TMultiSelectProps) {
    return (
        <div>
            <FormControl sx={{ width: "100%" }} size={props.size || "medium"}>
                <InputLabel id="demo-multiple-checkbox-label">{props.label}</InputLabel>
                <Select
                    multiple
                    onChange={(e) => {
                        const {
                            target: { value },
                        } = e;
                        props.setvalues(typeof value === "string" ? value.split(",") : (value as string[]));
                    }}
                    renderValue={(selected) => (selected as string[]).join(", ")}
                    {...props}
                >
                    {props.options.map((option: any) => (
                        <MenuItem key={option[props.menuValue] ?? option} value={props.menuValue === "" ? option : option[props.menuValue]}>
                            <Checkbox checked={props.value.indexOf(props.menuValue === "" ? option : option[props.menuValue]) > -1} />
                            <ListItemText
                                primary={props.menuLabels.map((label, idx) => {
                                    return label === ""
                                        ? option
                                        : option[label] + " " + ([undefined, ""].includes(option[props.menuLabels[idx + 1]]) ? "" : " - ");
                                })}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
type TMultiSelectProps = SelectProps & {
    setvalues: React.Dispatch<React.SetStateAction<string[]>>;
    options: string[];
    value: string[];
    menuValue: string;
    menuLabels: string[];
};
