import React, { useState } from "react";
import Button from "../../../../app/Button";
import Modal from "../../../../app/Modal";
import TextField from "../../../../app/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useLanguage } from "../../../../app/LanguageContext";

export default function CreateNewItemModal(props) {
  const [newCode, setnewCode] = useState("");
  const [openItemCard, setopenItemCard] = useState(true);
  const [isTaxed, setisTaxed] = useState(true);
  const [showCreateNewItem, setshowCreateNewItem] = useState(false);
  const { lang } = useLanguage();

  return (
    <>
      <Button
        id="CreateNewItemButton"
        theme="success"
        onClick={() => {
          setshowCreateNewItem(true);
        }}
      >
        {lang['CREATE_NEW_ITEM']} - F1
      </Button>
      <Modal
        show={showCreateNewItem}
        setshow={() => setshowCreateNewItem(false)}
        title={lang['CREATE_NEW_ITEM']}
        width="30rem"
        height="15rem"
      >
        <div className="flex h-full flex-col justify-between p-2">
          <TextField
            autoFocus
            onKeyDownCapture={(e) => {
              if (e.code === "Enter") {
                createNewItem();
              }
            }}
            label={lang['NEW_ITEM_CODE']}
            value={newCode}
            id="newcode"
            onChange={(e) => {
              setnewCode(e.target.value);
            }}
          />
          <div className="flex flex-row items-center">
            <Checkbox
              checked={isTaxed}
              onChange={(e) => {
                setisTaxed(!isTaxed);
              }}
            />

            <div>{lang['IS_TAXED']}?</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <Checkbox
                checked={openItemCard}
                onChange={(e) => {
                  setopenItemCard(!openItemCard);
                }}
              />

              <div>{lang['OPEN_ITEM_CARD_ON_CREATION']}?</div>
            </div>
            <Button onClick={createNewItem}>{lang['CREATE_ITEM']}</Button>
          </div>
        </div>
      </Modal>
    </>
  );
  function createNewItem() {
    const url = "goods/create_new_item/";
    const cbnf = (res) => {
      if (res.data.info === "success") {
        props.fetchItems();
        if (openItemCard) {
          props.setselectedRow((old) => {
            let temp = old;
            temp.row = res.data.item;
            return temp;
          });
          setshowCreateNewItem(false);
          props.setShowItemCard(true);
        } else {
          props.setselectedRow((old) => {
            let temp = old;
            temp.row = res.data.item;
            return temp;
          });
          setnewCode("");
          document.getElementById("newcode").focus();
        }
        // setdata(res.data.data);
        // try {
        //     setVeiwCode(res.data.data[0]["itemcode"]);
        // } catch (error) {}
      }
    };
    const data = {
      data: {
        itemcode: newCode,
        tax: isTaxed ? "Y" : "0",
      },
    };

    props.postdata(url, cbnf, data, true);
  }
}
