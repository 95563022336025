import TextField from "../../app/TextField";
import Button from "../../app/Button";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Select from "../../app/Select";
import LoadingSpinner from "../../app/LoadingSpinner";
import React from "react";

export default function OneItem(props) {
    const dataOptions = [
        { label: "Average" },
        { label: "Last" },
        // { label: "Relative" }
    ];
    return (
        <div className="top m-2 flex h-[17rem] flex-row justify-evenly  ">
            <div className="flex flex-row gap-3 p-2">
                <div className=" flex w-[12rem] flex-col gap-2">
                    <TextField
                        size="small"
                        label={"Item Code"}
                        value={props.itemcode}
                        autoFocus
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                                props.getData();
                                document.getElementById("startDate").focus();
                            }
                        }}
                        onChange={(e) => {
                            props.setitemcode(e.target.value);
                        }}
                        id="ItemCode"
                        disabled={!props.allowEdit}
                    />

                    <TextField disabled size="small" label={"Item Name 1"} value={props.itemdata.itemname1} id="ItemName1" />
                    <TextField disabled size="small" label={"Item Name 2"} value={props.itemdata.itemname2} id="ItemName2" />

                    <TextField
                        disabled
                        size="small"
                        label={"Original Number"}
                        value={props.itemdata.originalnumber}
                        id="OriginalNumber"
                    />
                    <TextField
                        disabled
                        size="small"
                        label={"Date Created"}
                        value={props.itemdata.date_created}
                        id="datecreated"
                    />
                </div>
                <div className=" flex w-[12rem] flex-col gap-2">
                    <TextField
                        disabled
                        size="small"
                        label={"Last Modified"}
                        value={props.itemdata.last_modified}
                        id="lastmodified"
                    />
                    <TextField disabled size="small" label={"Sale 1"} value={props.itemdata.sale1} id="sale1" />

                    <TextField disabled size="small" label={"Sale 2"} value={props.itemdata.sale2} id="sale2" />
                    <TextField disabled size="small" label={"Sale 3"} value={props.itemdata.sale3} id="sale3" />

                    <TextField disabled size="small" label={"Sale 4"} value={props.itemdata.sale4} id="sale4" />
                </div>
                <div className=" flex w-[12rem] flex-col gap-2">
                    <TextField disabled size="small" label={"Tax"} value={props.itemdata.tax} id="tax" />
                    <TextField disabled size="small" label={"Note"} value={props.itemdata.note} id="note" />
                    <TextField disabled size="small" label={"Category"} value={props.itemdata.category} id="category" />

                    <TextField disabled size="small" label={"Set"} value={props.itemdata.set} id="set" />
                    <TextField disabled size="small" label={"Brand"} value={props.itemdata.brand} id="brand" />
                </div>
                <div className="flex w-[12rem] flex-col gap-2">
                    <TextField disabled size="small" label={"size"} value={props.itemdata.size} id="size" />
                    <TextField disabled size="small" label={"color"} value={props.itemdata.color} id="color" />
                    <TextField disabled size="small" label={"Family"} value={props.itemdata.family} id="family" />
                    <TextField disabled size="small" label={"Type"} value={props.itemdata.type} id="type" />
                </div>
            </div>
            <div className="border-l border-gray-500"></div>
            <div className="mx-2 flex w-[10rem] flex-col gap-4 ">
                <Button
                    variant="outlined"
                    onClick={(e) => {
                        props.getData();
                    }}
                    size="small"
                    disabled={props.isLoading}
                >
                    Get Data - F5
                    <LoadingSpinner show={props.isLoading} />
                </Button>
                <Button
                    onClick={() => {
                        props.setshowPrintModal(true);
                    }}
                    disabled={props.allowEdit}
                    size="small"
                    variant="outlined"
                >
                    Print Report
                </Button>
                {/* <Button variant="outlined" size="small" color="error" onClick={(e) => {}}>
                    Reset filters
                </Button> */}
                <Button
                    variant="outlined"
                    size="small"
                    theme="error"
                    onClick={(e) => {
                        props.clearData();
                    }}
                >
                    Clear Data - F8
                </Button>
            </div>

            <div className="border-l border-gray-500"></div>
            <div className="flex flex-col justify-start  gap-3 p-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className=" flex flex-row gap-3 ">
                        <DateField
                            label="Start Date"
                            size="small"
                            value={dayjs(props.startDate)}
                            format="DD/MM/YYYY"
                            id="startDate"
                            onChange={(newValue) => {
                                if (newValue.isValid()) {
                                    props.setstartDate(newValue.format("YYYY-MM-DD"));
                                }
                            }}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("endDate").focus();
                                }
                            }}
                            disabled={!props.allowEdit}
                        />
                        <IconButton
                            children={<MoreVertIcon />}
                            color="primary"
                            sx={{
                                width: "2.1rem",
                                height: "2.1rem",
                            }}
                            disabled={!props.allowEdit}
                        ></IconButton>
                        <DateField
                            label="End Date"
                            value={dayjs(props.endDate)}
                            id="endDate"
                            size="small"
                            format="DD/MM/YYYY"
                            onChange={(newValue) => {
                                if (newValue.isValid()) {
                                    props.setendDate(newValue.format("YYYY-MM-DD"));
                                }
                            }}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    document.getElementById("startDate").focus();
                                }
                            }}
                            disabled={!props.allowEdit}
                        />
                    </div>
                </LocalizationProvider>
                <div className="flex flex-row justify-between">
                    <div className="flex w-[45%] flex-col gap-3">
                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            value={props.selectedCur.code}
                            disabled={!props.allowEdit}
                            label="Currency"
                            id="currency"
                            onChange={(e) => {
                                props.currencyList.forEach((cur) => {
                                    if (cur.code === e.target.value) {
                                        props.setselectedCur(cur);
                                    }
                                });
                            }}
                            options={props.currencyList}
                            menuValue={"code"}
                            menuLabels={["code"]}
                        />

                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedBranch}
                            label="Branch"
                            id="branch"
                            onChange={(e) => {
                                props.setselectedBranch(e.target.value);
                            }}
                            options={props.branches}
                            menuValue={"num"}
                            menuLabels={["num", "name"]}
                        />

                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedSman}
                            label="Sales Man"
                            id="Sales Man"
                            onChange={(e) => {
                                props.setselectedSman(e.target.value);
                            }}
                            options={props.smanList}
                            menuValue={"name"}
                            menuLabels={["name", "description"]}
                        />

                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedJob}
                            label="Job"
                            id="job"
                            onChange={(e) => {
                                props.setselectedJob(e.target.value);
                            }}
                            options={props.jobList}
                            menuValue={"name"}
                            menuLabels={["name", "description"]}
                        />
                    </div>
                    <div className="flex w-[45%] flex-col gap-3">
                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedCost}
                            label="Cost By"
                            id="Cost By"
                            onChange={(e) => {
                                props.setselectedCost(e.target.value);
                            }}
                            options={dataOptions}
                            menuValue={"label"}
                            menuLabels={["label"]}
                        />

                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedPrice}
                            label="Sale By"
                            id="Sale By"
                            onChange={(e) => {
                                props.setselectedPrice(e.target.value);
                            }}
                            options={dataOptions}
                            menuValue={"label"}
                            menuLabels={["label"]}
                        />

                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedStockValue}
                            label="Stock Value By"
                            id="Stock Value By"
                            name="Stock Value By"
                            onChange={(e) => {
                                props.setselectedStockValue(e.target.value);
                            }}
                            options={dataOptions}
                            menuValue={"label"}
                            menuLabels={["label"]}
                        />

                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedSaleProfit}
                            label="Sales Profit By"
                            id="Sales Profit By"
                            name="Sales Profit By"
                            onChange={(e) => {
                                props.setselectedSaleProfit(e.target.value);
                            }}
                            options={dataOptions}
                            menuValue={"label"}
                            menuLabels={["label"]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
