import { useLanguage } from "../../app/LanguageContext";
import AdvancedTable from "../../app/AdvancedTable";
import React from "react";
export default function DataTable(props) {
    const {lang} = useLanguage()
    const columns = [
        {
            field: "account_id",
            headerName: lang['ACCOUNT_ID'],

            flex: 100,
            editable: false,
            sortable: true,
        },
        {
            field: "name1",
            headerName: lang['NAME_1'],

            flex: 250,
            editable: false,
        },
        {
            field: "cur",
            headerName: lang['CUR'],

            flex: 70,
            editable: false,
        },
        {
            field: "crtotal",
            headerName: lang['CR_AMOUNT'],

            flex: 140,
            editable: false,
            valueGetter: (params) => {
                return params.row.crtotal.toLocaleString() + " " + params.row.symbol;
            },
        },
        {
            field: "dbtotal",
            headerName: lang['DB_AMOUNT'],

            flex: 140,
            editable: false,
            valueGetter: (params) => {
                return params.row.dbtotal.toLocaleString() + " " + params.row.symbol;
            },
        },
        {
            field: "balance",
            headerName: lang['BALANCE'],

            flex: 140,
            editable: false,
            valueGetter: (params) => {
                return params.row.balance.toLocaleString() + " " + params.row.symbol;
            },
        },
        {
            field: "balance_1",
            headerName: ` ${props.priCur.code} ${lang['BALANCE']}`,

            flex: 140,
            editable: false,
            valueGetter: (params) => {
                return params.value.toLocaleString() + " " + props.priCur.symbol;
            },
        },
        {
            field: "balance_2",
            headerName: ` ${props.rateCur.code} ${lang['BALANCE']}`,

            flex: 140,
            editable: false,
            valueGetter: (params) => {
                return params.value.toLocaleString() + " " + props.rateCur.symbol;
            },
        },
    ];

    return (
        <>
            <AdvancedTable
                rows={props.rows.filter((e) => {
                    return e.balance !== 0;
                })}
                cols={columns}
                getRowClassName={(e) => {
                    if (e.row.balance < 0) {
                        return " text-red-500  ";
                    } else if (e.row.balance > 0) {
                        return "  text-green-500 ";
                    } else {
                        return "  text-orange-500 ";
                    }
                }}
                getRowId={(e) => {
                    return e.account_id.toString();
                }}
                onCellDoubleClick={(e) => {
                    if (e.field === "account_id") {
                        window.localStorage.setItem("tempAccId", e.row.account_id);
                        props.setShowStatementOfAccount(true);
                    }
                }}
                gridApiRef={props.gridApiRef}
            />
        </>
    );
}
