import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
export default function SelectionMenu({ postdata, selectedConf, setselectedConf, setisSelected }) {
    useEffect(() => {
        getConfigs();
    }, []);

    const [listOfConfigs, setlistOfConfigs] = useState([]);
    const [name, setname] = useState("");

    return (
        <div className="flex h-full w-full flex-col items-center  gap-5 p-4">
            <div className=" text-xl font-semibold">Select Menu Configuration</div>
            <FormControl sx={{ width: 200 }} size="small">
                <InputLabel sx={{ color: "#c7c7c7 !important" }} id="demo-simple-select-label">
                    Selected Config
                </InputLabel>
                <Select
                    sx={{ color: "#c7c7c7 !important" }}
                    slotProps={{
                        sx: { color: "#c7c7c7 !important", backgroundColor: "#212529" },
                    }}
                    value={selectedConf}
                    label="Selected Config"
                    id="SelectedConfig"
                    name="SelectedConfig"
                    onChange={(e) => {
                        setselectedConf(e.target.value);
                    }}
                >
                    {listOfConfigs.map((cnf) => {
                        return (
                            <MenuItem key={cnf.name} value={cnf.id}>
                                {cnf.id} - {cnf.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Button
                variant="outlined"
                onClick={() => {
                    if (selectedConf !== "") {
                        setisSelected(true);
                    }
                }}
            >
                Select
            </Button>
            <div className="m-3">
                <Accordion style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}>
                    <AccordionSummary
                        className="bg-zinc-200"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}
                    >
                        <div className=" font-semibold !text-[#c7c7c7]">Create New Configuraion</div>
                    </AccordionSummary>
                    <AccordionDetails style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}>
                        <div className="flex flex-row items-center justify-start align-middle ">
                            <TextField
                                InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                size="small"
                                inputProps={{ className: "p-2" }}
                                label={"Name"}
                                sx={{ marginX: 3 }}
                                value={name}
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                    }
                                }}
                                onBlur={(e) => {
                                    setname(e.target.value);
                                }}
                                onChange={(e) => {
                                    setname(e.target.value);
                                }}
                                id="name"
                            />

                            <Button
                                onClick={() => {
                                    createNewConfig();
                                }}
                                variant="outlined"
                                sx={{ margin: 1 }}
                            >
                                create
                            </Button>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
    function getConfigs() {
        let url = "settings/get_printer_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setlistOfConfigs(res.data.data);
            }
        };
        let fdata = {};
        postdata(url, cbnf, fdata, false);
    }
    function createNewConfig() {
        let url = "settings/add_printer_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setlistOfConfigs(res.data.data);
                setname("");
            }
        };
        let fdata = {
            name: name,
        };
        postdata(url, cbnf, fdata, true);
    }
}
