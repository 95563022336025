import { DataGrid, GridApiCommon, GridCellParams } from "@mui/x-data-grid";
import { DataGridProps } from "@mui/x-data-grid";
import { useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState, forwardRef, MutableRefObject } from "react";

export type TCustomDataTableProps = {
    enterDirection?: "Horizontal" | "Vertical";
    setSelectedRow?: (e: any) => void;
    setSelectedLines?: any;
    onFieldEdit?: (selectedRow: GridCellParams, v: any) => void;
    gridRef?: MutableRefObject<any>;
    apiRef?: MutableRefObject<GridApiCommon>;
    onCellKeyDown?: (e: any, f: any) => void;
};

function DataTable({
    enterDirection = "Horizontal",
    setSelectedRow = (e: any) => {},
    setSelectedLines,
    onFieldEdit = (e, v) => {},
    gridRef,
    apiRef = useGridApiRef(),
    onCellKeyDown = (e, f) => {},
    ...props
}: DataGridProps & TCustomDataTableProps) {
    const selectedRow = useRef<GridCellParams>();
    const selectedRowHandler = (e: any) => {
        setSelectedRow(e);
        selectedRow.current = e;
    };
    return (
        <DataGrid
            {...props}
            apiRef={apiRef}
            ref={gridRef}
            columnHeaderHeight={props.columnHeaderHeight || 45}
            rowHeight={props.rowHeight || 35}
            getRowClassName={
                props.getRowClassName ||
                ((e) => {
                    if (e.indexRelativeToCurrentPage % 2 !== 0) {
                        return "bg-[#0000001c]";
                    }
                })
            }
            showColumnVerticalBorder={props.showColumnVerticalBorder || true}
            showCellVerticalBorder={props.showCellVerticalBorder || true}
            onCellEditStart={(e) => {
                selectedRowHandler(e);
            }}
            onCellClick={(e) => {
                selectedRowHandler(e);
            }}
            rowBuffer={props.rowBuffer || 100}
            sx={{
                "& .MuiDataGrid-cell:focus": {
                    borderWidth: "1px !important",
                    borderColor: "#0d6efd !important",
                    outline: "3px auto #0d6efd",
                },
                "& .MuiDataGrid-withBorderColor": {
                    borderColor: "#333333",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    color: "dataTable.header.color",
                    fontWeight: "500",
                },
                "& .MuiDataGrid-columnHeader": {
                    // backgroundColor: "dataTable.header.backgroundColor",
                },
                // backgroundColor: "dataTable.root.backgroundColor",
                borderColor: "dataTable.root.borderColor",
                color: "dataTable.root.color",
                ...props.sx,
            }}
            slotProps={{
                ...props.slotProps,
                cell: { id: "datagridcell" },
            }}
            hideFooter={props.hideFooter || props.rows.length < 100}
            processRowUpdate={
                props.processRowUpdate ||
                ((e) => {
                    if (e[selectedRow.current.field] !== selectedRow.current.value) {
                        onFieldEdit(selectedRow.current, e[selectedRow.current.field]);
                    }
                    return e;
                })
            }
            onProcessRowUpdateError={(e) => {
                console.log(e);
            }}
            isRowSelectable={props.isRowSelectable || ((e) => true)}
            onCellKeyDown={(e, f) => {
                onCellKeyDown(e, f);
                if (f.key === "Enter") {
                    if (enterDirection === "Horizontal") {
                        const thisField = props.columns.find((el) => el.field === e.field);
                        const thisRowIndex = props.rows.findIndex((el) => {
                            return props.getRowId(el) === props.getRowId(e.row);
                        });
                        f.preventDefault();
                        f.defaultMuiPrevented = true;
                        if (thisField.field === props.columns[props.columns.length - 1].field) {
                            const nextRow = apiRef.current.getRowIdFromRowIndex(thisRowIndex + 1);
                            apiRef.current.setCellFocus(nextRow, props.columns[1].field);
                        } else {
                            apiRef.current.setCellFocus(e.id, props.columns[props.columns.findIndex((el) => el.field === e.field) + 1].field);
                        }
                    }
                }
            }}
        />
    );
}

export default DataTable;

// ||
// thisField.editable === false ||
// props.columns[props.columns.findIndex((el) => el.field === e.field) + 1].editable === false
