import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import XButton from "../../../app/XButton";
import DataGridModal from "../../../app/DataGridModal";
import MenuItemsView from "./MenuItemsView";
import RemoveMenuItem from "./RemoveMenuItem";
import AddMenuItem from "./AddMenuItem";

export default function POSMenuConfigModal(props) {
    const [selectedConf, setselectedConf] = useState("");

    const [isSelected, setisSelected] = useState(false);

    const [listOfConfigs, setlistOfConfigs] = useState([]);
    const [name, setname] = useState("");
    const [col, setcol] = useState(0);
    const [row, setrow] = useState(0);

    const [addItemModal, setaddItemModal] = useState(false);
    const [removeItemModal, setremoveItemModal] = useState(false);

    const [selectedParent, setselectedParent] = useState("");
    const [selectedElement, setselectedElement] = useState("");
    const [itemType, setitemType] = useState("item");

    const [newitemcode, setnewitemcode] = useState("");
    const [newnum, setnewnum] = useState(0);
    const [newTitle, setnewTitle] = useState("");
    const [newallowunderqtysale, setnewallowunderqtysale] = useState("");

    const [showDataGridModal, setshowDataGridModal] = useState(false);

    const [data, setdata] = useState({
        config: {
            id: 0,
            name: "",
            row_count: 0,
            column_count: 0,
        },
        items: [],
    });

    useEffect(() => {
        getConfigs();
    }, []);

    const handleConfigChange = (field, value) => {
        setdata({
            ...data,
            config: {
                ...data.config,
                [field]: value,
            },
        });
    };

    const commonAttr = {
        inputProps: { sx: { color: "#c7c7c7 !important" }, className: "p-2" },
    };

    const commonSelectAttr = {
        MenuProps: {
            sx: { color: "#c7c7c7 !important" },
            PaperProps: {
                style: {
                    backgroundColor: "#212529",
                },
            },
        },
    };
    const commonMenuAttr = {
        sx: {
            color: "#c7c7c7 !important",
            backgroundColor: "#212529",
            ":hover": { backgroundColor: "#30353a" },
        },
    };
    return (
        <>
            <Modal
                open={props.show}
                onTransitionEnter={() => {}}
                onClose={(e, r) => {
                    props.setshow(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableRestoreFocus
                disableEnforceFocus
                disableEscapeKeyDown
                disableAutoFocus
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80vw",
                        height: "80vh",
                        border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: 3,
                        p: 0,
                    }}
                >
                    <div className="flex h-full flex-col justify-start rounded-xl bg-zinc-800 !text-[#c7c7c7]">
                        <div className=" m-1 flex flex-row justify-between border-b-2 border-gray-500">
                            <div className=" text-2xl font-semibold">POS Menu Configuration</div>
                            <div>
                                <XButton onClickFunc={props.setshow} />
                            </div>
                        </div>
                        <div className="flex h-full w-full flex-col items-center justify-between gap-5  overflow-y-auto p-2 ">
                            {isSelected ? (
                                <MenuItemsView
                                    data={data}
                                    setisSelected={setisSelected}
                                    handleConfigChange={handleConfigChange}
                                    editConfig={editConfig}
                                    setselectedParent={setselectedParent}
                                    setaddItemModal={setaddItemModal}
                                    setnewnum={setnewnum}
                                    setselectedElement={setselectedElement}
                                    setremoveItemModal={setremoveItemModal}
                                />
                            ) : (
                                <>
                                    <div className="flex h-full w-full flex-col items-center  gap-5 p-4">
                                        <div className=" text-xl font-semibold">Select Menu Configuration</div>
                                        <FormControl sx={{ width: 200 }} size="small">
                                            <InputLabel sx={{ color: "#c7c7c7 !important" }} id="demo-simple-select-label">
                                                Selected Config
                                            </InputLabel>
                                            <Select
                                                sx={{ color: "#c7c7c7 !important" }}
                                                slotProps={{
                                                    sx: { color: "#c7c7c7 !important", backgroundColor: "#212529" },
                                                }}
                                                {...commonSelectAttr}
                                                {...commonAttr}
                                                value={selectedConf}
                                                label="Selected Config"
                                                id="SelectedConfig"
                                                name="SelectedConfig"
                                                onChange={(e) => {
                                                    setselectedConf(e.target.value);
                                                }}
                                            >
                                                {listOfConfigs.map((cnf) => {
                                                    return (
                                                        <MenuItem {...commonMenuAttr} key={cnf.name} value={cnf.id}>
                                                            {cnf.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                if (selectedConf !== "") {
                                                    getMenuConfig();
                                                }
                                            }}
                                        >
                                            Select
                                        </Button>
                                    </div>
                                    <div className="m-3">
                                        <Accordion style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}>
                                            <AccordionSummary
                                                className="bg-zinc-200"
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}
                                            >
                                                <div className=" font-semibold !text-[#c7c7c7]">Create New Configuraion</div>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{ color: "#c7c7c7 !important", backgroundColor: "#5a5a5a" }}
                                            >
                                                <div className="flex flex-row items-center justify-start align-middle ">
                                                    <TextField
                                                        InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                                        size="small"
                                                        inputProps={{ className: "p-2" }}
                                                        label={"Name"}
                                                        sx={{ marginX: 3 }}
                                                        value={name}
                                                        onKeyDownCapture={(e) => {
                                                            if (e.key === "Enter") {
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            setname(e.target.value);
                                                        }}
                                                        onChange={(e) => {
                                                            setname(e.target.value);
                                                        }}
                                                        id="name"
                                                    />
                                                    <TextField
                                                        InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                                        size="small"
                                                        inputProps={{ className: "p-2" }}
                                                        label={"Column Count"}
                                                        sx={{ marginX: 3 }}
                                                        value={col}
                                                        onKeyDownCapture={(e) => {
                                                            if (e.key === "Enter") {
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setcol(e.target.value);
                                                        }}
                                                        id="name"
                                                    />
                                                    <TextField
                                                        InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                                        size="small"
                                                        inputProps={{ className: "p-2" }}
                                                        label={"Row Count"}
                                                        sx={{ marginX: 3 }}
                                                        value={row}
                                                        onKeyDownCapture={(e) => {
                                                            if (e.key === "Enter") {
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setrow(e.target.value);
                                                        }}
                                                        id="name"
                                                    />
                                                    <Button
                                                        onClick={() => {
                                                            createNewConfig();
                                                        }}
                                                        variant="outlined"
                                                        sx={{ margin: 1 }}
                                                    >
                                                        create
                                                    </Button>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Box>
            </Modal>
            {addItemModal ? (
                <AddMenuItem
                    addItemModal={addItemModal}
                    setaddItemModal={setaddItemModal}
                    selectedParent={selectedParent}
                    setitemType={setitemType}
                    setnewitemcode={setnewitemcode}
                    setnewTitle={setnewTitle}
                    itemType={itemType}
                    newitemcode={newitemcode}
                    getItemcodeData={getItemcodeData}
                    newTitle={newTitle}
                    newnum={newnum}
                    setnewnum={setnewnum}
                    addItem={addItem}
                    newallowunderqtysale={newallowunderqtysale}
                    setnewallowunderqtysale={setnewallowunderqtysale}
                />
            ) : null}
            {removeItemModal ? (
                <RemoveMenuItem
                    removeItemModal={removeItemModal}
                    setremoveItemModal={setremoveItemModal}
                    selectedElement={selectedElement}
                    removeItem={removeItem}
                />
            ) : null}

            {showDataGridModal ? (
                <DataGridModal
                    show={showDataGridModal}
                    setShow={setshowDataGridModal}
                    modalTitle={"Similar Item Code / Original Number / Item Name 1 / Item Name 2 "}
                    searchPhrase={newitemcode}
                    quary={`itemcode as 'Item Code',originalnumber as "Original Number",itemname1 as "Item Name",\`set\` as "Set",category as "Category",sale1 as "Sale 1",sale2 as "Sale 2",sale3 as "Sale 3",sale4 as "sale 4" FROM ${localStorage.getItem(
                        "dbname"
                    )}.goods where (itemcode like '%${newitemcode}' or itemcode like '${newitemcode}%' or itemcode like '%${newitemcode}%') or (originalnumber like '%${newitemcode}' or originalnumber like '${newitemcode}%' or originalnumber like '%${newitemcode}%') or (itemname1 like '%${newitemcode}' or itemname1 like '${newitemcode}%' or itemname1 like '%${newitemcode}%') or (itemname2 like '%${newitemcode}' or itemname2 like '${newitemcode}%' or itemname2 like '%${newitemcode}%')`}
                    postdata={props.postdata}
                    onEnterPress={(newval) => {
                        getItemcodeData(newval);
                    }}
                />
            ) : null}
        </>
    );

    function getConfigs() {
        let url = "pos/get_menu_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setlistOfConfigs(res.data.data);
            }
        };
        let fdata = {};
        props.postdata(url, cbnf, fdata, false);
    }

    function createNewConfig() {
        let url = "pos/add_menu_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setlistOfConfigs(res.data.data);
                setname("");
                setrow(0);
                setcol(0);
            }
        };
        let fdata = {
            name: name,
            row: row,
            col: col,
        };
        props.postdata(url, cbnf, fdata, true);
    }
    function getMenuConfig() {
        let url = "pos/get_menu_items/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data);
                setisSelected(true);
            }
        };

        let fdata = {
            config_id: selectedConf,
        };

        props.postdata(url, cbnf, fdata, false);
    }

    function getItemcodeData(itemcode) {
        let url = "goods/get_items/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                if (res.data.data.goods.length === 1) {
                    setnewitemcode(res.data.data.goods[0]["itemcode"]);
                    setnewTitle(res.data.data.goods[0]["itemname1"]);
                } else {
                    setshowDataGridModal(true);
                }
            }
        };

        let fdata = {
            filters: [`and itemcode = '${itemcode}'`],
        };

        props.postdata(url, cbnf, fdata, false);
    }
    function addItem() {
        let url = "pos/add_menu_item/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data);
                setisSelected(true);
                setnewitemcode("");
                setnewTitle("");
                setnewnum(parseInt(newnum) + 1);
            }
        };

        let fdata = {
            config_id: data.config.id,
            num: newnum,
            parent: selectedParent,
            title: newTitle,
            itemcode: newitemcode,
            itype: itemType,
        };
        props.postdata(url, cbnf, fdata, true);
    }
    function removeItem() {
        let url = "pos/remove_menu_item/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data);
                setremoveItemModal(false);
            }
        };

        let fdata = {
            config_id: data.config.id,
            num: selectedElement.num,
            parent: selectedElement.parent,
        };
        props.postdata(url, cbnf, fdata, true);
    }

    function editConfig(field, value) {
        let url = "pos/edit_menu_config/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
            } else {
                getMenuConfig();
            }
        };

        let fdata = {
            config_id: data.config.id,
            field: field,
            value: value,
        };
        props.postdata(url, cbnf, fdata, false);
    }
}
