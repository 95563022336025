import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export type TextFieldProps = MUITextFieldProps & {
    tfRef?: React.MutableRefObject<HTMLInputElement> | undefined;
    txtcolor?: "primary" | "secondary" | "error" | "success";
    formatedValue?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDownCapture?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    useInnerState?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    iRef?: React.MutableRefObject<HTMLInputElement> | undefined;
    autoSelect?: boolean;
};

// export default function TextField(props: TextFieldProps) {
export default function TextField({
    value,
    tfRef = React.createRef(),
    sx = {},
    txtcolor,
    onKeyDownCapture = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent the default behavior of Enter key
            const n = document.getElementById(e.target.id)?.parentElement?.parentElement?.nextElementSibling?.children[1]
                ?.children[0] as HTMLInputElement;
            if (n) n.focus();
        }
    },
    onFocus = (e) => {},
    onBlur = (e) => {},
    onChange = (e) => {},
    useInnerState = false,
    iRef = React.createRef(),
    autoSelect = true,
    ...rest
}: TextFieldProps) {
    return (
        <MUITextField
            {...rest}
            value={value}
            onChange={(e) => {
                onChange(e);
            }}
            onBlur={(e) => {
                onBlur(e);
            }}
            onKeyDownCapture={(e) => {
                onKeyDownCapture(e);
            }}
            onFocus={(e) => {
                onFocus(e);
                if (autoSelect) e.target.select();
            }}
            inputRef={tfRef}
            ref={iRef}
            sx={{
                ...sx,
                "& .MuiOutlinedInput-input": { color: "input.text." + txtcolor ?? "primary" },
                "& .MuiInputLabel-root": { color: "input.text.secondary" },
            }}
        />
    );
}
