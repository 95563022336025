import Button from "../../app/Button";
import POSMenuConfigModal from "../OtherSettingsTabs/POSMenuConfiguration/POSMenuConfigModal";
import PrinterConfigModal from "../OtherSettingsTabs/PrinterConfiguration/PrinterConfigModal";
import React, { useState } from "react";
import MultidataConfigurationModal from "../OtherSettingsTabs/MultidataConfiguration/MultidataConfigurationModal";

export default function OtherSettingsTab(props) {
    const [showPOSMenuConfig, setshowPOSMenuConfig] = useState(false);

    const [showPrinterConfig, setshowPrinterConfig] = useState(false);

    return (
        <>
            <div className="flex flex-row flex-wrap gap-4 p-1">
                <Button
                    variant="outlined"
                    onClick={() => {
                        setshowPrinterConfig(true);
                    }}
                >
                    Printers Configuration
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setshowPOSMenuConfig(true);
                    }}
                >
                    POS Menu Configuration
                </Button>
                <MultidataConfigurationModal />
                <Button
                    variant="outlined"
                    onClick={() => {
                        postToAccounting();
                    }}
                >
                    Custom Posting
                </Button>
            </div>
            {showPOSMenuConfig ? <POSMenuConfigModal show={showPOSMenuConfig} setshow={setshowPOSMenuConfig} postdata={props.postdata} /> : null}
            {showPrinterConfig ? <PrinterConfigModal show={showPrinterConfig} setshow={setshowPrinterConfig} postdata={props.postdata} /> : null}
        </>
    );
    function postToAccounting() {
        const url = "invoice/shipping_posting/";
        const cbnf = () => {};
        const fdata = {};
        props.postdata(url, cbnf, fdata, true );
    }
}
