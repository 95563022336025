import TextField from "../../app/TextField";
import React from "react";
export default function ItemData(props) {
    return (
        <div className="flex h-full w-full flex-col ">
            <div className="flex h-[30%] w-full flex-row ">
                <div className="flex h-full w-[50%] flex-col items-center justify-around">
                    <TextField
                        size="small"
                        label={`${props.selectedCost} Cost Price`}
                        value={props.data.cost.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="OriginalNumber"
                    />
                    <TextField
                        size="small"
                        label={`Stock Value From ${props.selectedStockValue}`}
                        value={props.data.stock_value.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="OriginalNumber"
                    />
                </div>
                <div className="flex h-full w-[50%] flex-col items-center justify-around">
                    <TextField
                        size="small"
                        label={`${props.selectedPrice} Sale Price`}
                        value={props.data.sale.toLocaleString() + " " + props.data.symbol}
                        id="OriginalNumber"
                    />
                    <TextField
                        size="small"
                        label={`Profit From ${props.selectedSaleProfit} Sale`}
                        value={props.data.sales_profit.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="OriginalNumber"
                    />
                </div>
            </div>
            <div className="h-[1px] w-full border-t border-gray-500"></div>
            <div className="flex h-[70%] w-full flex-row ">
                <div className="flex h-full w-[50%] flex-col items-center justify-around">
                    <TextField
                        size="small"
                        label={"Qty In From Purchase"}
                        value={props.data.purchase_qty.toLocaleString()}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="PI"
                    />
                    <TextField
                        size="small"
                        label={"Qty Out From Sales"}
                        value={props.data.sales_qty.toLocaleString()}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="SA"
                    />
                    <TextField
                        size="small"
                        label={"Qty In From Sales Return"}
                        value={props.data.sales_return_qty.toLocaleString()}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="SR"
                    />
                    <TextField
                        size="small"
                        label={"Qty Out From Purchase Return"}
                        value={props.data.purchase_return_qty.toLocaleString()}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="PR"
                    />
                    <TextField
                        size="small"
                        label={"Total Qty"}
                        value={props.data.total_qty.toLocaleString()}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="PR"
                    />
                </div>
                <div className="flex h-full w-[50%] flex-col items-center justify-around">
                    <TextField
                        size="small"
                        label={"Sum Of Purchase"}
                        value={props.data.purchase_total.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="PI"
                    />
                    <TextField
                        size="small"
                        label={"Sum Of Sales"}
                        value={props.data.sales_total.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="SA"
                    />
                    <TextField
                        size="small"
                        label={"Sum Of Sales Return"}
                        value={props.data.purchase_return_total.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="SR"
                    />
                    <TextField
                        size="small"
                        label={"Sum Of Purchase Return"}
                        value={props.data.sales_return_total.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="PR"
                    />
                    <TextField
                        size="small"
                        label={"Total"}
                        value={props.data.invoices_total.toLocaleString() + " " + props.data.symbol}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                        id="totalPaid"
                    />
                </div>
            </div>
        </div>
    );
}
