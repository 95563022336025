import Modal from "./Modal";
import Button from "./Button";
import { Box, IconButton } from "@mui/material";
import React, { useState, useRef } from "react";
export default function ConfirmationModal({
    Icon = null,
    handleConfirm = () => {},
    title = "No Title",
    message = "No Message",
    children = null,
    color = "primary",
    size = "small",
    showOnHover = false,
    disabled = false,
}: {
    Icon?: any;
    handleConfirm?: any;
    title?: any;
    message?: any;
    children?: any;
    color?: any;
    size?: any;
    showOnHover?: any;
    disabled?: any;
}) {
    const [show, setshow] = useState(false);
    const icnref = useRef(null);
    return (
        <>
            <div className="flex w-full flex-row items-center justify-center">
                {Icon ? (
                    <IconButton
                        color={color}
                        size={size}
                        onClick={() => {
                            setshow(true);
                        }}
                        onMouseEnter={() => {
                            if (showOnHover) icnref.current.style.visibility = "visible";
                        }}
                        onMouseLeave={() => {
                            if (showOnHover) icnref.current.style.visibility = "hidden";
                        }}
                        disabled={disabled || false}
                    >
                        <div id="icnCon" ref={icnref} style={{ visibility: showOnHover ? "hidden" : "visible" }}>
                            <Icon />
                        </div>
                    </IconButton>
                ) : (
                    <Button
                        disabled={disabled || false}
                        onClick={() => {
                            setshow(true);
                        }}
                        theme={color}
                        sx={{ width: "100%" }}
                    >
                        {children}
                    </Button>
                )}
            </div>
            {show && (
                <Modal show={show} setshow={setshow} height="15rem" width="30rem" title={title}>
                    <div className="flex h-full flex-col justify-between gap-4 p-2">
                        <div className="text-xl">{message}</div>
                        <div className="flex flex-row justify-end gap-4 p-2">
                            <Button
                                onClick={() => {
                                    setshow(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleConfirm();
                                    setshow(false);
                                }}
                                theme="error"
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}
