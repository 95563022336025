import TextField from "../../app/TextField";
import React from "react";
import Button from "../../app/Button";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useState } from "react";
import DateField from "../../app/DateField";
import FilterSelectionModal from "../../Common/FilterSelectionModal/FilterSelectionModal";
import { useLanguage } from "../../app/LanguageContext";
import Select from "../../app/Select";
import QuickDateSelector from "../../app/QuickDateSelector";

const currentYear = new Date().getFullYear();

export default function AccountsFilters(props) {
    const { lang } = useLanguage();
    const navigate = useNavigate();

    const [showModal, setshowModal] = useState(false);
    const [selectedFilter, setselectedFilter] = useState("");
    const [rowLimit, setrowLimit] = useState(1000);

    return (
        <>
            <div className=" flex h-[100%] flex-col justify-between gap-2 rounded-lg  ">
                <Box
                    sx={{ backgroundColor: "modal.secondaryBG" }}
                    className="flex h-[35%] flex-col items-center justify-evenly gap-2 rounded p-2  "
                >
                    <TextField
                        sx={{ width: "90%" }}
                        size={"small"}
                        label={lang["ACCOUNT_ID"]}
                        value={props.account_id}
                        onChange={(e) => {
                            props.setaccount_id(e.target.value);
                        }}
                        onFocus={(e) => {
                            e.target.select();
                        }}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                                props.getData();
                            }
                        }}
                        autoFocus
                    />
                    <TextField
                        sx={{ width: "90%" }}
                        size={"small"}
                        label={lang["ACCOUNT_NAME"]}
                        value={props.accData.name1}
                    />
                    <div className="flex w-[90%] flex-row justify-between">
                        <TextField
                            txtcolor={props.accData.balance > 0 ? "error" : "success"}
                            sx={{ width: "70%" }}
                            size={"small"}
                            label="Balance"
                            value={
                                props.accData.balance === ""
                                    ? ""
                                    : props.accData.balance.toLocaleString() + " " + props.accData.symbol
                            }
                        />
                        <TextField sx={{ width: "25%" }} size={"small"} label={lang["CURRENCY"]} value={props.accData.cur} />
                    </div>
                    <Button
                        variant="outlined"
                        sx={{ width: "90%" }}
                        onClick={(e) => {
                            automaticPayment();
                            props.setshow(false);
                        }}
                        disabled={props.accData.balance === 0 || props.accData.balance === ""}
                    >
                        Automatic Payment
                    </Button>
                </Box>
                <Box sx={{ backgroundColor: "modal.secondaryBG" }} className=" my-1  h-[65%]   rounded  ">
                    <div className=" h-[3rem] w-full border-b-2 border-gray-500 text-center text-2xl">{lang["FILTERS"]}</div>
                    <div style={{ height: "calc(100% - 6rem)" }} className=" overflow-y-auto">
                        <div className=" flex flex-col gap-2   ">
                            <div className="flex flex-col gap-2 p-2 ">
                                <div className=" text-xl font-semibold">Date</div>
                                <div className="flex flex-row justify-center gap-2">
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateField
                                            label="Start Date"
                                            size="small"
                                            value={dayjs(props.startDate)}
                                            format="DD/MM/YYYY"
                                            id="startDate"
                                            disabled={!props.allowEdit}
                                            onChange={(newValue) => {
                                                if (newValue.isValid()) {
                                                    props.setstartDate(newValue.format("YYYY-MM-DD"));
                                                }
                                            }}
                                            onKeyDownCapture={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("endDate").focus();
                                                }
                                            }}
                                        />
                                        <DateField
                                            label="End Date"
                                            value={dayjs(props.endDate)}
                                            id="endDate"
                                            size="small"
                                            format="DD/MM/YYYY"
                                            disabled={!props.allowEdit}
                                            onChange={(newValue) => {
                                                if (newValue.isValid()) {
                                                    props.setendDate(newValue.format("YYYY-MM-DD"));
                                                }
                                            }}
                                            onKeyDownCapture={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("startDate").focus();
                                                }
                                            }}
                                        />
                                    </LocalizationProvider> */}
                                    <DateField
                                        value={props.startDate}
                                        label="Start Date"
                                        confirmFunc={props.setstartDate}
                                        nextFocus={() => {
                                            props.endDateFieldRef.current.focus();
                                        }}
                                        inputRef={props.startDateFieldRef}
                                        disabled={!props.allowEdit}
                                    />
                                    <QuickDateSelector
                                        disabled={!props.allowEdit}
                                        setstartDate={props.setstartDate}
                                        setendDate={props.setendDate}
                                    />
                                    <DateField
                                        value={props.endDate}
                                        label="End Date"
                                        confirmFunc={props.setendDate}
                                        inputRef={props.endDateFieldRef}
                                        disabled={!props.allowEdit}
                                    />
                                </div>
                            </div>
                            <div className="flex w-full flex-row items-center justify-center">
                                <Select
                                    options={[100, 500, 1000, 10000, 5000, 25000, 50000, 100000, 200000]}
                                    value={rowLimit}
                                    onChange={(e) => {
                                        setrowLimit(e.target.value);
                                    }}
                                    menuLabels={[""]}
                                    menuValue={""}
                                    id={"rowLimitSelect"}
                                    allowNone={false}
                                    label={"Row Count"}
                                    disabled={!props.allowEdit}
                                />
                            </div>
                            <div className="flex flex-col gap-2 p-2 ">
                                <div className=" text-xl font-semibold">{lang["SELECTED_VOUCHER_TYPES"]}:</div>
                                <div>{props.filters.type.toString() || "All"}</div>
                                <Button
                                    onClick={(e) => {
                                        setselectedFilter("type");
                                        setshowModal(true);
                                    }}
                                    disabled={!props.allowEdit}
                                >
                                    Voucher Types
                                </Button>
                            </div>
                            <div className="flex flex-col gap-2 p-2 ">
                                <div className=" text-xl font-semibold">{lang["SELECTED_BRANCHES"]}</div>
                                <div>{props.filters.branch.toString() || "All"}</div>
                                <Button
                                    onClick={(e) => {
                                        setselectedFilter("branch");
                                        setshowModal(true);
                                    }}
                                    disabled={!props.allowEdit}
                                >
                                    Branches
                                </Button>
                            </div>
                            <div className="flex flex-col gap-2 p-2 ">
                                <div className=" text-xl font-semibold">{lang["SELECTED_SALESMAN"]}</div>
                                <div>{props.filters.sman.toString() || "All"}</div>
                                <Button
                                    onClick={(e) => {
                                        setselectedFilter("sman");
                                        setshowModal(true);
                                    }}
                                    disabled={!props.allowEdit}
                                >
                                    {lang["SALES_MAN"]}
                                </Button>
                            </div>
                            <div className="flex flex-col gap-2 p-2 ">
                                <div className=" text-xl font-semibold">{lang["SELECTED_JOBS"]}</div>
                                <div>{props.filters.job.toString() || "All"}</div>
                                <Button
                                    onClick={(e) => {
                                        setselectedFilter("job");
                                        setshowModal(true);
                                    }}
                                    disabled={!props.allowEdit}
                                >
                                    {lang["JOBS"]}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className=" flex h-[3rem] flex-row items-center justify-around border-t-2 border-gray-500 text-center text-2xl">
                        <Button
                            variant="outlined"
                            sx={{ width: "35%", marginTop: 1 }}
                            theme="error"
                            onClick={(e) => {
                                props.resetFilters();
                            }}
                        >
                            {lang["CLEAR_DATA"]}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ width: "35%", marginTop: 1 }}
                            onClick={(e) => {
                                props.getData();
                            }}
                        >
                            {lang["GET_DATA"]}
                        </Button>
                    </div>
                </Box>
            </div>
            {showModal && (
                <FilterSelectionModal
                    show={showModal}
                    setshow={setshowModal}
                    options={props.filterOptions}
                    selectedOptions={props.filters}
                    setSelectedOptions={props.setfilters}
                    selectedFilter={selectedFilter}
                />
            )}
        </>
    );

    function automaticPayment() {
        let url = "accounts/auto_payment/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                window.localStorage.setItem("useTempAccounting", "true");
                window.localStorage.setItem("tempAccType", res.data.data.type);
                window.localStorage.setItem("tempAccNum", res.data.data.num);

                window.localStorage.setItem("statementofaccount", "true");

                window.localStorage.setItem("userecieptmodal", "true");
                navigate("/vouchers");
            }
        };
        let fdata = {
            account_id: props.account_id,
            balance: props.accData.balance,
        };

        props.postdata(url, cbnf, fdata, true);
    }
}
