import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import XButton from "../../../app/XButton";
import { Button } from "@mui/material";
import React from "react";
export default function RemoveMenuItem({ removeItemModal, setremoveItemModal, selectedElement, removeItem }) {
    return (
        <Modal
            open={removeItemModal}
            onTransitionEnter={() => {}}
            onClose={(e, r) => {
                setremoveItemModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableRestoreFocus
            disableEnforceFocus
            disableEscapeKeyDown
            disableAutoFocus
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "25rem",
                    height: "10rem",
                    border: "2px solid #000",
                    boxShadow: 24,
                    borderRadius: 3,
                    p: 0,
                }}
            >
                <div className="flex h-full flex-col justify-start rounded-[0.6rem] bg-zinc-800 !text-[#c7c7c7]">
                    <div className=" m-1 flex flex-row justify-between border-b-2 border-gray-500">
                        <div className=" text-2xl font-semibold">Remove: "{selectedElement.title}" ?</div>
                        <div>
                            <XButton onClickFunc={setremoveItemModal} />
                        </div>
                    </div>
                    <div className="flex h-full w-full flex-col items-end justify-end   gap-5 p-2 ">
                        <Button
                            onClick={() => {
                                removeItem();
                            }}
                            id="RemoveItem"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            color={"error"}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
