import React, { useState } from "react";
import Button from "../../../app/Button";
import Modal from "../../../app/Modal";
import TextField2 from "../../../app/TextField2";
import { AlertContext } from "../../../../providers/AlertProvider";
import LoadingSpinner from "../../../app/LoadingSpinner";
import DataTable from "../../../app/DataTable";
import { GridColDef } from "@mui/x-data-grid";
import DataGridModal2, { TDataGridModalAPI } from "../../../app/DataGridModal2";
import ConfirmationModal from "../../../app/ConfirmationModal";
import { Delete } from "@mui/icons-material";

export default function LabelExportModal(props: TLabelExportModalProps) {
    const alertAPI = React.useContext(AlertContext);

    const [showModal, setShowModal] = useState(false);

    const cols: GridColDef[] = [
        { field: "itemcode", headerName: "Item Code", flex: 1 },
        { field: "description", headerName: "Description", flex: 1, editable: true },
        { field: "qty", headerName: "Qty", flex: 1 },
        {
            field: "r",
            headerName: "del",
            width: 50,
            renderCell: (params) => (
                <ConfirmationModal
                    Icon={Delete}
                    handleConfirm={() => {
                        // deletetrans(e.row.trans_id);
                        setSelectedItems((old) => {
                            return old.filter((e) => e.itemcode !== params.row.itemcode);
                        });
                    }}
                    color={"error"}
                    size="small"
                    title={"Remove Row"}
                    message={"Selected Row Will Be Removed"}
                    showOnHover={true}
                />
            ),
        },
    ];

    const [selectedItems, setSelectedItems] = useState([]);

    const [itemcode, setItemcode] = useState("");
    const [itemname, setitemname] = useState("");
    const [qty, setQty] = useState<string | number>(1);

    const dataGridApiRef = React.useRef<TDataGridModalAPI>({});
    return (
        <>
            <Button
                onClick={() => {
                    setShowModal(true);
                }}
            >
                Label Export
            </Button>
            <Modal title="Label Export" show={showModal} setshow={setShowModal}>
                <div className="flex h-full w-full flex-col gap-2 p-2">
                    <div className="h-[90%] w-full">
                        <DataTable
                            rows={selectedItems}
                            columns={cols}
                            getRowId={(e) => {
                                return e.itemcode;
                            }}
                            onFieldEdit={(e, v) => {
                                setSelectedItems((old) => {
                                    const newdata = [...old];
                                    const idx = newdata.findIndex((row) => row.itemcode === e.row.itemcode);
                                    newdata[idx][e.field] = v;
                                    return newdata;
                                });
                            }}
                        />
                    </div>
                    <div className="flex h-[10%] w-full flex-row items-center justify-between px-3">
                        <div className="flex flex-row items-center gap-2">
                            <TextField2
                                label="Item Code"
                                value={itemcode}
                                onChange={(e) => {
                                    setItemcode(e.target.value);
                                }}
                                onBlur={itemcode && validatecode}
                                id="item"
                            />
                            <TextField2
                                label="Qty"
                                value={qty}
                                onChange={(e) => {
                                    setQty(e.target.value);
                                }}
                            />
                            <Button onClick={addItem}>Add Item</Button>
                        </div>
                        <Button onClick={exportData}>Export</Button>
                    </div>
                    <DataGridModal2 dataGridAPI={dataGridApiRef.current} />
                </div>
            </Modal>
        </>
    );

    function validatecode() {
        const url = "goods/get_items/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                if (res.data.data.goods.length === 1) {
                    setitemname(res.data.data.goods[0]["itemname1"]);
                } else {
                    dataGridApiRef.current.show("item", itemcode);
                    dataGridApiRef.current.onEnterPress = (data) => {
                        setItemcode(data);
                    };
                    dataGridApiRef.current.onExitPress = () => {
                        setItemcode("");
                    };
                }
            }
        };

        const fdata = {
            filters: [`and itemcode = '${itemcode}'`],
        };

        alertAPI.asyncPostData(url, fdata, cbnf, false);
    }

    function addItem() {
        if (itemcode === "") {
            return;
        }
        if (qty === "") {
            return;
        }
        if (selectedItems.find((e) => e.itemcode === itemcode)) {
            return;
        }
        setSelectedItems((old) => {
            return [...old, { itemcode, description: itemname, qty }];
        });
        setItemcode("");
        setitemname("");
        document.getElementById("item").focus();
    }

    function exportData() {
        const url = "goods/export_label/";
        alertAPI.asyncPostData(
            url,
            { items: selectedItems, label_id: "" },
            (res) => {
                if (res.data.info === "success") {
                    setSelectedItems([]);
                }
            },
            true
        );
    }
}

type TLabelExportModalProps = any;
