import React, { useState, useRef } from "react";
import Modal from "../../components/app/Modal";
import DataTableContainer from "../../components/BackOffice/StatementOfAccount/dataTableContainer";
import AccountsFilters from "../../components/BackOffice/StatementOfAccount/AccountsFilters";
import MultiDataSelect from "../../components/BackOffice/StatementOfAccount/MultiDataSelect";
import ExchangeRateModal from "../../components/BackOffice/StatementOfAccount/ExchangeRateModal";
import dayjs from "dayjs";
import DataGridModal from "../../components/app/DataGridModal";
import AccountProfile from "../../components/BackOffice/AccountProfile/AccountProfile";
import TextField from "../../components/app/TextField";
import Button from "../../components/app/Button";
import ReportModal from "../../components/PrintModal/ReportModal/ReportModal";
import { getJsonStorage } from "../../components/app/globalfunctions";
import { useMemo } from "react";
import { useLanguage } from "../../components/app/LanguageContext";
import { useGridApiRef } from "@mui/x-data-grid";
import { TMultiDataSelectApi } from "../../components/BackOffice/StatementOfAccount/MultiDataSelect";

const currentYear = new Date().getFullYear();

export default function StatementOfAccount(props) {
    const [account_id, setaccount_id] = useState(window.localStorage.getItem("tempAccId") ?? "");
    const { lang } = useLanguage();

    const [accData, setaccData] = useState({
        id: "",
        name1: "",
        name2: "",
        limit: 0,
        cur: "",
        category: "",
        set: "",
        active: "",
        level: "",
        date_created: "",
        last_modified: "",
        order: 0,
        code: "",
        name: "",
        symbol: "",
        rate: 1,
        primary: "",
        tax_rate: 0,
        default_sale_price: 0,
        balance: 0,
        balance1: 0,
        balance2: 0,
    });

    const [inv_sum, setinv_sum] = useState(0);

    const gridRef = useGridApiRef();

    const [crdb, setcrdb] = useState([]);

    const [startDate, setstartDate] = useState(`${currentYear}-01-01`);
    const [endDate, setendDate] = useState(`${currentYear}-12-31`);

    const [showDataGridModal, setshowDataGridModal] = useState(false);
    const [tempAccId, settempAccId] = useState("");

    const [accountProfileModal, setaccountProfileModal] = useState(false);

    const lists = useMemo(() => getJsonStorage("settings").lists, []);
    const vchTypes = lists.vch_types;
    const branchList = lists.branches;
    const smanList = lists.sman;
    const jobList = lists.jobs;

    const [showPrintModal, setshowPrintModal] = useState(false);

    const [allowEdit, setallowEdit] = useState(true);

    const [rateCur, setrateCur] = useState({
        order: 2,
        code: "LBP",
        name: "Lebanease Pound",
        name2: "",
        symbol: "L.L",
        rate: 90000,
        primary: "",
        tax_rate: 15000,
        default_sale_price: 0,
    });
    const [priCur, setpriCur] = useState({
        order: 1,
        code: "USD",
        name: "United States Dollar",
        name2: "",
        symbol: "$",
        rate: 1,
        primary: "y",
        tax_rate: 0,
        default_sale_price: 0,
    });

    const [filters, setfilters] = useState({
        type: [],
        branch: [],
        sman: [],
        job: [],
    });

    const startDateFieldRef = useRef();
    const endDateFieldRef = useRef();
    const selectedPageRef = useRef();

    const multiDataApiRef = useRef<TMultiDataSelectApi>({});

    return (
        <>
            <Modal
                show={props.show}
                setshow={props.setshow}
                width="100vw"
                height="100vh"
                title={lang["STATEMENT_OF_ACCOUNT"]}
                borderRadius="0.2rem"
                keepMounted={true}
                modalSx={{ visibility: props.tempHide ? "hidden" : "visible", display: props.tempHide ? "none" : "block" }}
            >
                <div className=" flex  h-full w-full flex-row justify-center p-2 ">
                    <div className=" h-full  w-[25%]">
                        <AccountsFilters
                            jobList={jobList}
                            getData={getData}
                            account_id={account_id}
                            setaccount_id={setaccount_id}
                            accData={accData}
                            startDate={startDate}
                            setstartDate={setstartDate}
                            endDate={endDate}
                            setendDate={setendDate}
                            resetFilters={resetFilters}
                            postdata={props.postdata}
                            branchList={branchList}
                            smanList={smanList}
                            setshow={props.setshow}
                            filters={filters}
                            setfilters={setfilters}
                            filterOptions={{
                                type: vchTypes,
                                branch: branchList,
                                sman: smanList,
                                job: jobList,
                            }}
                            allowEdit={allowEdit}
                            setallowEdit={setallowEdit}
                            startDateFieldRef={startDateFieldRef}
                            endDateFieldRef={endDateFieldRef}
                        />
                    </div>
                    <div className=" mx-2 flex h-full w-[75%] flex-col items-start justify-between ">
                        <DataTableContainer
                            rows={crdb}
                            setaccountProfileModal={setaccountProfileModal}
                            rateCur={rateCur}
                            priCur={priCur}
                            setshow={props.setshow}
                            gridRef={gridRef}
                            selectedPageRef={selectedPageRef}
                            saveToStorage={saveToStorage}
                            setTempHide={props.setTempHide}
                            setTempHideTrial={props.setTempHideTrial}
                        />
                        <div className=" mt-2 flex w-full flex-row items-center overflow-x-auto py-2 ">
                            <div className="flex flex-row  px-2">
                                <TextField
                                    txtcolor={accData.balance1 > 0 ? "error" : "success"}
                                    sx={{ width: 170, mx: 2 }}
                                    size="small"
                                    label={"Acc. Balance " + priCur.code}
                                    value={accData.balance1.toLocaleString() + " " + priCur.symbol}
                                    id="priAcc"
                                />
                                <TextField
                                    txtcolor={accData.balance2 > 0 ? "error" : "success"}
                                    sx={{ width: 170, mx: 2 }}
                                    size="small"
                                    label={"Acc. Balance " + rateCur.code}
                                    value={accData.balance2.toLocaleString() + " " + rateCur.symbol}
                                    id="priAcc"
                                />
                            </div>
                            <div className="flex flex-row px-4">
                                <Button
                                    onClick={() => {
                                        setshowPrintModal(true);
                                    }}
                                    variant="outlined"
                                    sx={{ marginX: 1, whiteSpace: "nowrap" }}
                                    disabled={allowEdit || crdb.length === 0}
                                >
                                    Print Report
                                </Button>
                                <Button
                                    onClick={() => {
                                        gridRef.current.exportDataAsCsv({ fileName: "statement_of_account" });
                                    }}
                                    variant="outlined"
                                    sx={{ marginX: 1, whiteSpace: "nowrap" }}
                                    disabled={allowEdit || crdb.length === 0}
                                >
                                    Export As Csv
                                </Button>
                                <ExchangeRateModal disabled={allowEdit || crdb.length === 0} accData={accData} postdata={props.postdata} />
                            </div>
                            <div className="flex w-full flex-row justify-end">
                                <MultiDataSelect multiDataApiRef={multiDataApiRef} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {showDataGridModal ? (
                <DataGridModal
                    show={showDataGridModal}
                    setShow={setshowDataGridModal}
                    modalTitle={"Similar Account Id / Account Name 1 / Account Name 2 / Account Currency "}
                    searchPhrase={tempAccId}
                    quary={`id as 'Acc Id',name1 as "Name 1",name2 as "Name 2",tax_number as "Tax Number",\`cur\` as "Currency",category as "Category" FROM ${localStorage.getItem(
                        "dbname"
                    )}.accounts where ((\`id\` like '${tempAccId}%' ) or tax_number like '%${tempAccId}%' or (name1 like '%${tempAccId}' or name1 like '${tempAccId}%' or name1 like '%${tempAccId}%') or (name2 like '%${tempAccId}' or name2 like '${tempAccId}%' or name2 like '%${tempAccId}%') or (cur like '%${tempAccId}' or cur like '${tempAccId}%' or cur like '%${tempAccId}%') ) and active = 'Y' `}
                    postdata={props.postdata}
                    onEnterPress={(newvalue) => {
                        setaccount_id(newvalue);
                        getData(newvalue);
                    }}
                    onHideFunc={() => {}}
                />
            ) : null}
            {accountProfileModal ? (
                <AccountProfile
                    modalShow={accountProfileModal}
                    setModalShow={setaccountProfileModal}
                    postdata={props.postdata}
                    selectedAcc={{ row: { id: account_id } }}
                    onHideFunc={() => {
                        getData();
                    }}
                />
            ) : null}

            {showPrintModal ? (
                <ReportModal
                    showPrintModal={showPrintModal}
                    setshowPrintModal={setshowPrintModal}
                    data={{
                        accData: accData,
                        crdb: crdb,
                        filters: {
                            fromDate: startDate,
                            toDate: endDate,
                            types: filters.type,
                            branches: filters.branch,
                            smans: filters.sman,
                            jobs: filters.job,
                        },
                        rateCur: rateCur,
                        priCur: priCur,
                    }}
                    reportId={"statement_of_account"}
                    postdata={props.postdata}
                    onPrintFunc={() => {}}
                />
            ) : null}
        </>
    );
    function getData(newvalue = account_id) {
        const url = "accounts/statement_of_account/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                window.localStorage.setItem("tempAccId", res.data.data.account.id);
                setaccData(res.data.data.account);
                setcrdb(res.data.data.crdb);
                setinv_sum(res.data.data.inv_sum);

                setrateCur(res.data.data.rateCur);
                setpriCur(res.data.data.priCur);

                setTimeout(() => {
                    gridRef.current.setPage(Math.ceil(res.data.data.crdb.length / 100));
                    document.getElementById("accDataTableCon").children[0].children[0].children[1].scrollTo({ top: 99999, behavior: "smooth" });
                }, 500);
                setallowEdit(false);
            } else if (res.data.msg === " Account Does Not Exist" || res.data.msg === " Account Is Inactive") {
                settempAccId(newvalue);
                setshowDataGridModal(true);
            }
        };
        const fdata = {
            account_id: newvalue,
            startDate: startDate,
            endDate: endDate,
            filters: filters,
            rowLimit: document.getElementById("rowLimitSelect").innerText,
            useOtherData: multiDataApiRef.current.useMultiData || false,
            otherDataId: multiDataApiRef.current.selectedId || 0,
        };
        props.postdata(url, cbnf, fdata, false);
    }

    function resetFilters() {
        setstartDate(`${currentYear}-01-01`);
        setendDate(`${currentYear}-12-31`);
        setfilters({
            type: [],
            branch: [],
            sman: [],
            job: [],
        });

        setinv_sum(0);

        setcrdb([]);
        setaccData({
            id: "",
            name1: "",
            name2: "",
            limit: 0,
            cur: "",
            category: "",
            set: "",
            active: "",
            level: "",
            date_created: "",
            last_modified: "",
            order: 0,
            code: "",
            name: "",
            symbol: "",
            rate: 1,
            primary: "",
            tax_rate: 0,
            default_sale_price: 0,
            balance: 0,
            balance1: 0,
            balance2: 0,
        });
        setallowEdit(true);
    }
    function saveToStorage(e) {}
}
