import StatementOfAccount from "../../Pages/BackOffice/StatementOfAccount";
import StatementOfItem from "../../Pages/BackOffice/StatementOfItem";
import StockOverview from "../../Pages/BackOffice/StockOverview";
import TrialBalance from "../../Pages/BackOffice/TrialBalance";
import React, { useState } from "react";
import { TStatementsContainerAPI } from "./NavContainer";

export default function StateentsContainer({ ...props }: TStatmentsContainerProps) {
    const [showStatementOfAccount, setShowStatementOfAccount] = useState(false);
    const [showStatementOfItem, setShowStatementOfItem] = useState(false);
    const [showStockOverview, setShowStockOverview] = useState(false);
    const [showTrialBalance, setShowTrialBalance] = useState(false);
    const [tempHideAccount, setTempHideAccount] = useState(false);
    const [tempHideItem, setTempHideItem] = useState(false);
    const [tempHideOverview, setTempHideOverview] = useState(false);
    const [tempHideTrial, setTempHideTrial] = useState(false);

    props.outerStatementsContainerAPI.current = {
        setShowStatementOfAccount: (b: boolean) => {
            setShowStatementOfAccount(b);
        },
        setShowStatementOfItem: (b: boolean) => setShowStatementOfItem(b),
        setShowStockOverview: (b: boolean) => setShowStockOverview(b),
        setShowTrialBalance: (b: boolean) => setShowTrialBalance(b),
        setTempHideAccount: (b: boolean) => {
            setTempHideAccount(b);
        },
        setTempHideItem: (b: boolean) => setTempHideItem(b),
        setTempHideOverview: (b: boolean) => setTempHideOverview(b),
        setTempHideTrial: (b: boolean) => setTempHideTrial(b),
    };
    return (
        <>
            {showStatementOfAccount && (
                <StatementOfAccount
                    postdata={props.postdata}
                    show={showStatementOfAccount}
                    setshow={setShowStatementOfAccount}
                    setTempHide={setTempHideAccount}
                    tempHide={tempHideAccount}
                    setTempHideTrial={setTempHideTrial}
                />
            )}
            {showStatementOfItem && (
                <StatementOfItem
                    postdata={props.postdata}
                    show={showStatementOfItem}
                    setshow={setShowStatementOfItem}
                    setTempHide={setTempHideItem}
                    tempHide={tempHideItem}
                    defaultItemCode={
                        window.localStorage.getItem("itemcode") !== undefined ? window.localStorage.getItem("itemcode") : ""
                    }
                />
            )}
            {showStockOverview && (
                <StockOverview
                    postdata={props.postdata}
                    show={showStockOverview}
                    setshow={setShowStockOverview}
                    setTempHide={setTempHideOverview}
                    tempHide={tempHideOverview}
                />
            )}
            {showTrialBalance && (
                <TrialBalance
                    postdata={props.postdata}
                    show={showTrialBalance}
                    setshow={setShowTrialBalance}
                    setTempHide={setTempHideTrial}
                    tempHide={tempHideTrial}
                    setShowStatementOfAccount={setShowStatementOfAccount}
                />
            )}
        </>
    );
}

export type TStatmentsContainerProps = {
    outerStatementsContainerAPI: TStatementsContainerAPI;
    postdata;
};
