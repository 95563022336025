import React, { useState, useEffect } from "react";
import TextField from "../components/app/TextField";
import { useLanguage } from "../components/app/LanguageContext";

export let accountStruct = {
    id: 0,
    name1: "",
    name2: "",
    limit: 0,
    cur: "",
    category: "",
    set: "",
    active: "Y",
    level: "",
    tax_number: "",
    date_created: "2023-06-22T08:28:43",
    last_modified: "2023-06-22T08:28:43",
    country: "",
    province: "",
    city: "",
    street: "",
    building: "",
    floor: "",
    email: "",
    phone1: "",
    phone2: "",
    website: "",
    address1: "",
    address2: "",
};
export function AccountsFeilds({ postdata, data, setdata }) {
    const [temp, settemp] = useState("");
    const { lang } = useLanguage();

    const commonProps = {
        sx: { width: "24%" },
    };

    const fields = {
        name1: {
            ...commonProps,
            id: "name1",
            onBlur: (e) => {
                editData("name1", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("name1", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("name2");
                }
            },
            label: lang["ACCOUNT_NAME_1"],
            value: data.name1,
        },
        name2: {
            ...commonProps,
            id: "name2",
            onBlur: (e) => {
                editData("name2", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("name2", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("active");
                }
            },
            label: lang["ACCOUNT_NAME_2"],
            value: data.name2,
        },
        active: {
            ...commonProps,
            id: "active",
            onBlur: (e) => {
                editData("active", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("active", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("limit");
                }
            },
            label: lang["ACTIVE"],
            value: data.active,
        },
        limit: {
            ...commonProps,
            id: "limit",
            onBlur: (e) => {
                editData("limit", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("limit", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("country");
                }
            },
            label: lang["LIMIT"],
            value: data.limit,
        },
        country: {
            ...commonProps,
            id: "country",
            onBlur: (e) => {
                editData("country", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("country", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("address1");
                }
            },
            label: lang["COUNTRY"],
            value: data.country,
        },
        address1: {
            ...commonProps,
            sx: { width: "50%" },
            id: "address1",
            onBlur: (e) => {
                editData("address1", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("address1", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("address2");
                }
            },
            label: lang["ADDRESS_1"],
            value: data.address1,
        },
        address2: {
            ...commonProps,
            sx: { width: "50%" },
            id: "address2",
            onBlur: (e) => {
                editData("address2", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("address2", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("province");
                }
            },
            label: lang["ADDRESS_2"],
            value: data.address2,
        },
        province: {
            ...commonProps,
            id: "province",
            onBlur: (e) => {
                editData("province", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("province", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("city");
                }
            },
            label: lang["PROVINCE"],
            value: data.province,
        },
        city: {
            ...commonProps,
            id: "city",
            onBlur: (e) => {
                editData("city", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("city", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("street");
                }
            },
            label: lang["CITY"],
            value: data.city,
        },
        street: {
            ...commonProps,
            id: "street",
            onBlur: (e) => {
                editData("street", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("street", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("building");
                }
            },
            label: lang["STREET"],
            value: data.street,
        },
        building: {
            ...commonProps,
            id: "building",
            onBlur: (e) => {
                editData("building", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("building", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("floor");
                }
            },
            label: lang["BUILDING"],
            value: data.building,
        },
        floor: {
            ...commonProps,
            id: "floor",
            onBlur: (e) => {
                editData("floor", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("floor", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("tax_number");
                }
            },
            label: lang["FLOOR"],
            value: data.floor,
        },
        tax_number: {
            ...commonProps,
            id: "tax_number",
            onBlur: (e) => {
                editData("tax_number", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("tax_number", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("phone1");
                }
            },
            label: lang["TAX_NUMBER"],
            value: data.tax_number,
        },
        phone1: {
            ...commonProps,
            id: "phone1",
            onBlur: (e) => {
                editData("phone1", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("phone1", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("phone2");
                }
            },
            label: lang["PHONE_1"],
            value: data.phone1,
        },
        phone2: {
            ...commonProps,
            id: "phone2",
            onBlur: (e) => {
                editData("phone2", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("phone2", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("email");
                }
            },
            label: lang["PHONE_2"],
            value: data.phone2,
        },
        email: {
            ...commonProps,
            id: "email",
            onBlur: (e) => {
                editData("email", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("email", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    enterHandler("website");
                }
            },
            label: lang["EMAIL"],
            value: data.email,
        },
        website: {
            ...commonProps,
            id: "website",
            onBlur: (e) => {
                editData("website", e.target.value, true);
            },
            onFocus: (e) => {
                settemp(e.target.value);
                e.target.select();
            },
            onChange: (e) => {
                editData("website", e.target.value, false);
            },
            onKeyDownCapture: (e) => {
                if (e.code === "Enter") {
                    document.activeElement.blur();
                }
            },
            label: lang["WEBSITE"],
            value: data.website,
        },
    };
    return (
        <div className="flex w-full flex-col gap-4 p-2">
            <div className="flex flex-row items-center justify-between gap-3">
                <TextField {...fields.name1} />
                <TextField {...fields.name2} />
                <TextField {...fields.active} />
                <TextField {...fields.limit} />
                <TextField {...fields.country} />
            </div>
            <div className="flex w-full flex-row justify-between gap-3">
                <TextField {...fields.address1} />
                <TextField {...fields.address2} />
            </div>

            <div className="flex flex-row items-center justify-between gap-3">
                <TextField {...fields.province} />
                <TextField {...fields.city} />
                <TextField {...fields.street} />
                <TextField {...fields.building} />
                <TextField {...fields.floor} />
            </div>
            <div className="flex flex-row items-center justify-between gap-3">
                <TextField {...fields.tax_number} />
                <TextField {...fields.phone1} />
                <TextField {...fields.phone2} />
                <TextField {...fields.email} />
                <TextField {...fields.website} />
            </div>
        </div>
    );

    function editData(field, value, confirm) {
        if (value !== temp) {
            if (confirm) {
                let url = "accounts/update_account/";
                const cbnf = (res) => {
                    if (res.data.info === "success") {
                        setdata((old) => {
                            let temp = { ...old };
                            temp[field] = res.data.data;
                            return temp;
                        });
                    }
                };
                let fdata = {
                    id: data.id,
                    field: field,
                    value: value,
                    confirm: confirm,
                };

                postdata(url, cbnf, fdata, false);
            } else {
                setdata((old) => {
                    let temp = { ...old };
                    temp[field] = value;
                    return temp;
                });
            }
        }
    }
    function enterHandler(to) {
        document.getElementById(to).focus();
    }
}
