import React from "react";
import Modal from "../../app/Modal";
import Button from "../../app/Button";
import TextField from "../../app/TextField";
export default function ExchangeRateModal(props) {
    const [show, setShow] = React.useState(false);
    const [positiveAccount, setPositiveAccount] = React.useState(localStorage.getItem("positiveAccount") || 0);
    const [negativeAccount, setNegativeAccount] = React.useState(localStorage.getItem("negativeAccount") || 0);
    const [exchangeRate, setExchangeRate] = React.useState(0);

    return (
        <>
            <Button onClick={() => setShow(true)} disabled={props.disabled}>
                Close Exchange Rate
            </Button>
            <Modal height="20rem" width="30rem" title="Different Exchange Rate" show={show} setshow={setShow}>
                <div className="h-ful flex w-full flex-col p-2">
                    <div className="flex h-full flex-col gap-2">
                        <TextField
                            size={"small"}
                            label="Positive Account"
                            value={positiveAccount}
                            onChange={(e) => {
                                localStorage.setItem("positiveAccount", e.target.value);
                                setPositiveAccount(e.target.value);
                            }}
                            type="number"
                        />
                        <TextField
                            size={"small"}
                            label="Negative Account"
                            value={negativeAccount}
                            onChange={(e) => {
                                localStorage.setItem("negativeAccount", e.target.value);
                                setNegativeAccount(e.target.value);
                            }}
                            type="number"
                        />
                        <TextField
                            size={"small"}
                            label="Exchange Rate"
                            value={exchangeRate}
                            onChange={(e) => {
                                setExchangeRate(parseFloat(e.target.value));
                            }}
                            type="number"
                        />
                        <div>{`${props.accData.balance} / ${exchangeRate} - ${props.accData.balance1} 
                        = ${props.accData.balance / exchangeRate - props.accData.balance1}`}</div>
                    </div>
                    <div className="flex w-full flex-row items-end justify-end gap-2">
                        <Button onClick={() => setShow(false)} theme={"error"}>
                            Close
                        </Button>
                        <Button onClick={() => closeExchangeRate()} theme="success">
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
    function closeExchangeRate() {
        const amount = props.accData.balance / exchangeRate - props.accData.balance1;
        console.log(amount);

        if (exchangeRate === 0) {
            console.log("Exchange Rate cannot be 0");
            return;
        }
        if (amount === undefined || amount === 0 || isNaN(amount) || amount === null || amount === Infinity || amount === -Infinity) {
            console.log("Invalid Amount");
            return;
        }
        const url = "vouchers/close_differnt_exchange/";
        const fdata = {
            positive_account: positiveAccount,
            negative_account: negativeAccount,
            rate: exchangeRate,
            amount: amount,
            account: props.accData.id,
        };
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setShow(false);
            }
        };
        props.postdata(url, cbnf, fdata, true);
    }
}
