import React, { useEffect, useState, useRef } from "react";
import AdvancedTable from "../../app/AdvancedTable";
import TextField from "../../app/TextField";
import { blurDoc, focusTo } from "../../app/globalfunctions";
import { Button } from "@mui/material";

export default function DefaultSettings({ postdata }) {
    useEffect(() => {
        getDefaultSettings();
    }, []);
    const tableCols = [
        {
            field: "id",
            headerName: "Id",
            editable: false,
            flex: 1,
        },
        {
            field: "name",
            headerName: "Name",
            editable: false,
            flex: 2,
        },
        {
            field: "group",
            headerName: "Group",
            editable: false,
            flex: 1.5,
        },
        {
            field: "sub_group",
            headerName: "Sub Group",
            editable: false,
            flex: 1,
        },
        {
            field: "level",
            headerName: "Level",
            editable: false,
            flex: 1,
        },
        {
            field: "value",
            headerName: "Value",
            editable: true,
            flex: 1.5,
        },
    ];

    const [data, setdata] = useState([]);

    // const [id, setid] = useState("");
    // const [name, setname] = useState("");
    // const [group, setgroup] = useState("");
    // const [sub_group, setsub_group] = useState("");
    // const [level, setlevel] = useState("");
    // const [value, setvalue] = useState("");

    const id = useRef("");
    const name = useRef("");
    const group = useRef("");
    const sub_group = useRef("");
    const level = useRef("");
    const value = useRef("");

    const commonAttr = {
        onFocus: (e) => {
            e.target.select();
        },
    };

    const fields = {
        id: {
            size: "small",
            placeholder: "Id",
            sx: { width: 150 },
            tfRef: id,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    focusTo("name");
                }
            },
            onChange: (e) => {
                id.current.value = e.target.value;
            },
            id: "id",
        },
        name: {
            size: "small",
            placeholder: "Name",
            sx: { width: 250 },
            tfRef: name,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    focusTo("group");
                }
            },
            onChange: (e) => {
                name.current.value = e.target.value;
            },
            id: "name",
        },
        group: {
            size: "small",
            placeholder: "Group",
            sx: { width: 200 },
            tfRef: group,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    focusTo("sub_group");
                }
            },
            onChange: (e) => {
                group.current.value = e.target.value;
            },
            id: "group",
        },
        sub_group: {
            size: "small",
            placeholder: "Sub Group",
            sx: { width: 150 },
            tfRef: sub_group,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    focusTo("level");
                }
            },
            onChange: (e) => {
                sub_group.current.value = e.target.value;
            },
            id: "sub_group",
        },
        level: {
            size: "small",
            placeholder: "Level",
            sx: { width: 120 },
            tfRef: level,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    focusTo("value");
                }
            },
            onChange: (e) => {
                level.current.value = e.target.value;
            },
            id: "level",
        },
        value: {
            size: "small",
            placeholder: "Value",
            sx: { width: 120 },
            tfRef: value,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    focusTo("table");
                }
            },
            onChange: (e) => {
                value.current.value = e.target.value;
            },
            id: "value",
        },
    };

    return (
        <div className="flex h-full w-full  flex-col ">
            <div className="h-[80%] p-1">
                <AdvancedTable
                    rows={data}
                    cols={tableCols}
                    getRowId={(e) => {
                        return e.id + e.name + e.group + e.level;
                    }}
                    onFieldEdit={editSettings}
                    onCellDoubleClick={(e) => {
                        id.current.value = e.row.id;
                        name.current.value = e.row.name;
                        group.current.value = e.row.group;
                        sub_group.current.value = e.row.sub_group;
                        level.current.value = e.row.level;
                        value.current.value = e.row.value;
                    }}
                />
            </div>
            <div className="flex h-[20%] w-full flex-row items-center justify-around gap-2 ">
                <TextField {...commonAttr} {...fields.id} />
                <TextField {...commonAttr} {...fields.name} />
                <TextField {...commonAttr} {...fields.group} />
                <TextField {...commonAttr} {...fields.sub_group} />
                <TextField {...commonAttr} {...fields.level} />
                <TextField {...commonAttr} {...fields.value} />
                <Button
                    variant="outlined"
                    onClick={() => {
                        getDefaultSettings();
                    }}
                >
                    Filter
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        createSettings();
                    }}
                >
                    Add
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        clearFields();
                    }}
                >
                    Clear
                </Button>
            </div>
        </div>
    );
    function clearFields() {
        id.current.value = "";
        name.current.value = "";
        group.current.value = "";
        sub_group.current.value = "";
        level.current.value = "";
        value.current.value = "";
    }
    function getDefaultSettings() {
        let url = "settings/get_default_settings/";
        const cbnf2 = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data);
            }
        };
        const fdata = {
            id: id.current.value,
            name: name.current.value,
            group: group.current.value,
            sub_group: sub_group.current.value,
            level: level.current.value,
            value: value.current.value,
        };

        postdata(url, cbnf2, fdata, false);
    }
    function createSettings() {
        let url = "settings/add_default_setting/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                getDefaultSettings();
            }
        };
        const fdata = {
            id: id.current.value,
            name: name.current.value,
            group: group.current.value,
            sub_group: sub_group.current.value,
            level: level.current.value,
            value: value.current.value,
        };
        postdata(url, cbnf, fdata, true);
    }
    function editSettings(selectedRow, newValue) {
        let url = "settings/edit_default_settings/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
            } else {
                getDefaultSettings();
            }
        };
        const fdata = {
            selectedRow: selectedRow,
            newValue: newValue,
        };
        postdata(url, cbnf, fdata, false);
    }
}
