import TextField2 from "../../../app/TextField2";
import Button from "../../../app/Button";
import React, { useState } from "react";
export default function AddDatabase(props: TAddDatabaseProps) {
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [databaseName, setDatabaseName] = useState("");

    return (
        <div className="flex flex-row gap-2">
            <TextField2
                label="Host"
                value={host}
                onChange={(e) => {
                    setHost(e.target.value);
                }}
            />
            <TextField2
                label="Port"
                value={port}
                onChange={(e) => {
                    setPort(e.target.value);
                }}
            />
            <TextField2
                label="Username"
                value={username}
                onChange={(e) => {
                    setUsername(e.target.value);
                }}
            />
            <TextField2
                label="Password"
                value={password}
                type="password"
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
            />
            <TextField2
                label="Database Name"
                value={databaseName}
                onChange={(e) => {
                    setDatabaseName(e.target.value);
                }}
            />
            <Button
                onClick={() => {
                    props.addDatabase(host, port, username, password, databaseName);
                }}
            >
                Add Data
            </Button>
            <Button
                onClick={() => {
                    props.validateDatabase(host, port, username, password, databaseName);
                }}
            >
                Validate Fields
            </Button>
            <Button
                color={"danger"}
                onClick={() => {
                    setHost("");
                    setPort("");
                    setUsername("");
                    setPassword("");
                    setDatabaseName("");
                }}
            >
                Reset
            </Button>
        </div>
    );
}

type TAddDatabaseProps = {
    validateDatabase: (host, port, username, dbpass, dbname) => any;
    addDatabase: (host, port, username, dbpass, dbname) => any;
};
