import React, { useState } from "react";
import { ModalDialog } from "react-bootstrap";
import Modal from "../../../../app/Modal";
import TextField from "../../../../app/TextField";
import Button from "../../../../app/Button";
export default function CreateOtherBarcodeModal(props) {
    const handleClose = () => props.setShow(false);
    const [otherCode, setOtherCode] = useState("");

    return (
        <>
            <Modal show={props.show} setshow={handleClose} height="20rem" width="40rem" title="Add Other Barcode">
                <div className="flex h-full flex-col justify-between p-2 ">
                    <div className="flex flex-row justify-between">
                        <TextField disabled value={props.maincode} label="Main Code" variant="outlined" />
                        <TextField
                            label="Item Code"
                            variant="outlined"
                            autoFocus
                            value={otherCode}
                            onChange={(e) => {
                                setOtherCode(e.target.value);
                            }}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    createCode();
                                }
                            }}
                        />
                    </div>
                    <div className="flex flex-row-reverse justify-start gap-2">
                        <Button onClick={createCode}>Create</Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </div>
                </div>
            </Modal>
            {/* <Modal
                show={props.show}
                onHide={handleClose}
                backdropClassName="bg-red-800"
                centered
                style={{ backgroundColor: "#959595ba" }}
            >
                <ModalDialog
                    bsPrefix=" h-[15rem]"
                    className="h-[15rem] rounded border-4 border-neutral-700 bg-zinc-800 !text-[#c7c7c7] shadow-2xl"
                >
                    <div className="flex h-[14.7rem] flex-col justify-between p-2 ">
                        <h4>Add Other Barcode</h4>
                        <div className="flex flex-row justify-between">
                            <TextField
                                disabled
                                value={props.maincode}
                                label="Original Number"
                                variant="outlined"
                                sx={{ color: "#c7c7c7 !important" }}
                                InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                            />
                            <TextField
                                sx={{ color: "#c7c7c7 !important" }}
                                InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                label="Item Code"
                                variant="outlined"
                                autoFocus
                                value={otherCode}
                                onChange={(e) => {
                                    setOtherCode(e.target.value);
                                }}
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                        createCode();
                                    }
                                }}
                            />
                        </div>
                        <div className="flex flex-row-reverse justify-start">
                            <Button onClick={createCode}>Create</Button>
                            <Button variant="danger" className="mr-5" onClick={handleClose}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </ModalDialog>
            </Modal> */}
        </>
    );
    function createCode() {
        const url = "goods/create_new_item/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                document.getElementById("cardRefresh").click();
                handleClose();
            }
        };
        const data = {
            data: {
                itemcode: otherCode,
                maincode: props.maincode,
                type: "4",
            },
        };

        props.postdata(url, cbnf, data, true);
    }
}
