import XButton from "../../app/XButton";
import Modal from "../../app/Modal";
import Button from "../../app/Button";
import React, { useState } from "react";
import StatementOfAccount from "../../../Pages/BackOffice/StatementOfAccount";
import StatementOfItem from "../../../Pages/BackOffice/StatementOfItem";
import TrailBalance from "../../../Pages/BackOffice/TrialBalance";
import StockOverview from "../../../Pages/BackOffice/StockOverview";
import GeneralSettingsModal from "../../Settings/Modal/GeneralSettingsModal";
import InventoryManagmentModal from "../InventoryManagment/InventoryManagmentModal";

export default function QuickAccessModal(props) {
    const [showModal, setshowModal] = useState(true);

    const [showStatementOfItem, setshowStatementOfItem] = useState(false);

    const [stockOverview, setstockOverview] = useState(false);

    const [showStatementOfAccount, setshowStatementOfAccount] = useState(false);

    const [showtrialBalance, setshowtrialBalance] = useState(false);

    const [generalSettingsModal, setgeneralSettingsModal] = useState(false);

    const [showInventoryManagmentModal, setshowInventoryManagmentModal] = useState(false);

    return (
        <>
            <Modal
                show={props.show && showModal}
                setshow={props.setshow}
                id={"QuickAccessModal"}
                title="Quick Access"
                width={"75vw"}
                height={"40rem"}
            >
                <div className="flex h-full w-full flex-col p-2">
                    <div className="items center flex h-full flex-row items-center justify-around">
                        <div className="flex h-full flex-col justify-start gap-4">
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setshowStatementOfItem(true);
                                    props.setshow(false);
                                }}
                            >
                                <div className=" my-2 whitespace-nowrap text-xl">Statement Of Item</div>
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setstockOverview(true);
                                    props.setshow(false);
                                }}
                            >
                                <div className=" my-2 whitespace-nowrap text-xl">Stock Overview</div>
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setshowStatementOfAccount(true);
                                    props.setshow(false);
                                }}
                            >
                                <div className=" my-2 whitespace-nowrap text-xl">Statement Of Account</div>
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setshowtrialBalance(true);
                                    props.setshow(false);
                                }}
                            >
                                <div className=" my-2 whitespace-nowrap text-xl">Trail Balance</div>
                            </Button>
                        </div>
                        <div className="flex h-full flex-col justify-start gap-4">
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setshowInventoryManagmentModal(true);
                                    props.setshow(false);
                                }}
                            >
                                <div className=" my-2 whitespace-nowrap text-xl">Inventory Managment</div>
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setgeneralSettingsModal(true);
                                    props.setshow(false);
                                }}
                            >
                                <div className=" my-2 whitespace-nowrap text-xl">General Settings</div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            {showStatementOfItem ? (
                <StatementOfItem
                    show={showStatementOfItem}
                    setshow={setshowStatementOfItem}
                    postdata={props.postdata}
                    defaultItemCode={
                        window.localStorage.getItem("itemcode") !== undefined ? window.localStorage.getItem("itemcode") : ""
                    }
                />
            ) : null}
            {stockOverview ? (
                <StockOverview
                    show={stockOverview}
                    setshow={setstockOverview}
                    postdata={props.postdata}
                    defaultItemCode={
                        window.localStorage.getItem("itemcode") !== undefined ? window.localStorage.getItem("itemcode") : ""
                    }
                />
            ) : null}

            {showStatementOfAccount ? (
                <StatementOfAccount
                    show={showStatementOfAccount}
                    setshow={setshowStatementOfAccount}
                    postdata={props.postdata}
                    defaultItemCode={
                        window.localStorage.getItem("account_id") !== undefined
                            ? window.localStorage.getItem("account_id")
                            : ""
                    }
                />
            ) : null}
            {showtrialBalance ? (
                <TrailBalance
                    show={showtrialBalance}
                    setshow={setshowtrialBalance}
                    postdata={props.postdata}
                    defaultItemCode={
                        window.localStorage.getItem("account_id") !== undefined
                            ? window.localStorage.getItem("account_id")
                            : ""
                    }
                />
            ) : null}
            {generalSettingsModal ? (
                <GeneralSettingsModal
                    generalSettingsModal={generalSettingsModal}
                    setgeneralSettingsModal={setgeneralSettingsModal}
                    postdata={props.postdata}
                />
            ) : null}
            {showInventoryManagmentModal ? (
                <InventoryManagmentModal
                    show={showInventoryManagmentModal}
                    setshow={setshowInventoryManagmentModal}
                    postdata={props.postdata}
                />
            ) : null}
        </>
    );
}
