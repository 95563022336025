import React, { useState } from "react";
import Select from "../../app/Select";
import Radio from "@mui/material/Radio";
import { TMultiData } from "../../Settings/OtherSettingsTabs/MultidataConfiguration/MultidataConfigurationModal";

export default function MultiDataSelect(props: TMultiDataSelectProps) {
    const [useMultiData, setuseMultiData] = useState(false);
    const [selectedData, setselectedData] = useState<TMultiData>({ dbname: "", host: "", port: "", id: 0, username: "", dbpass: "" });
    const otherData: TMultiData[] = JSON.parse(localStorage.getItem("settings"))?.lists?.other_databases || [];
    if (otherData.length === 0) {
        return <></>;
    }

    props.multiDataApiRef.current = {
        useMultiData,
        selectedId: selectedData.id,
    };

    return (
        <div className="flex w-full flex-row items-center justify-end gap-3">
            <div className="flex flex-row items-center">
                <Radio
                    checked={useMultiData}
                    onClick={() => {
                        setuseMultiData(!useMultiData);
                    }}
                />
                <div
                    className=" cursor-pointer"
                    onClick={() => {
                        setuseMultiData(!useMultiData);
                    }}
                >
                    Use Other Database
                </div>
            </div>
            {useMultiData && (
                <Select
                    value={selectedData.id}
                    onChange={(e) => {
                        setselectedData(otherData.find((item) => item.id.toString() === e.target.value.toString()));
                    }}
                    options={otherData}
                    menuValue={"id"}
                    menuLabels={["host", "port", "dbname"]}
                    allowNone={false}
                    width={300}
                />
            )}
        </div>
    );
}

type TMultiDataSelectProps = {
    multiDataApiRef: React.MutableRefObject<TMultiDataSelectApi>;
};

export type TMultiDataSelectApi = {
    useMultiData?: boolean;
    selectedId?: number;
};
