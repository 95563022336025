import React, { useEffect, useState } from "react";
import TextField from "../../app/TextField";
import XButton from "../../app/XButton";
import { accountStruct, AccountsFeilds } from "../../../Classes/AccountClass";
import Modal from "../../app/Modal";
import { useLanguage } from "../../app/LanguageContext";

export default function AccountProfile(props) {
    const { lang } = useLanguage();

    useEffect(() => {
        getData();
    }, []);

    const [data, setdata] = useState(accountStruct);

    return (
        <>
            <Modal
                show={props.modalShow}
                setshow={() => {
                    props.setModalShow(false);
                    props.onHideFunc();
                }}
                id="AccountProfile"
                height="100vh"
                width="100vw"
                title={lang["ACCOUNT_PROFILE"]}
                borderRadius="0.2rem"
            >
                <div className="flex h-full w-[100%] flex-col items-center gap-6">
                    <div className="my-2 flex flex-row">
                        <TextField disabled id="1" label={lang["ACCOUNT_ID"]} value={data.id} />
                    </div>
                    <AccountsFeilds postdata={props.postdata} data={data} setdata={setdata} />
                </div>
            </Modal>
        </>
    );
    function getData() {
        let url = "accounts/get_accounts/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data[0]);
            }
        };
        let fdata = {
            filters: [`id = '${props.selectedAcc.row.id}' `],
            active_only: false,
        };

        props.postdata(url, cbnf, fdata, false);
    }
}
