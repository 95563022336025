import Alerts from "./components/app/Alerts";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import AppTheme from "./components/app/AppTheme";
import NavContainer from "./components/app/NavContainer";
import { LanguageProvider } from "./components/app/LanguageContext";
import AlertProvider from "./providers/AlertProvider";

function App() {
    const [message, setMessage] = useState({ status: "", msg: "" });
    const [open, setopen] = useState(false);
    const [useDarkMode, setuseDarkMode] = useState(1);
    const setuseDarkModeHandler = useCallback(setuseDarkMode, []);
    const alertHandler = useCallback((status, msg) => {
        //success
        //info
        //warning
        //error
        setMessage({
            status: status,
            msg: msg,
        });
        setopen(true);
    }, []);
    return (
        <>
            <AppTheme theme={useDarkMode === 1 ? "dark" : "light"}>
                <LanguageProvider>
                    <AlertProvider>
                        <NavContainer alertHandler={alertHandler} setuseDarkMode={setuseDarkModeHandler} />
                        <Alerts open={open} setopen={setopen} message={message} />
                    </AlertProvider>
                </LanguageProvider>
            </AppTheme>
        </>
    );
}

export default App;
