import { useNavigate } from "react-router-dom";
import AdvancedTable from "../../app/AdvancedTable";
import DataTable from "../../app/DataTable";
import React from "react";

export default function DataTableContainer(props) {
    const navigate = useNavigate();
    const columns = [
        {
            field: "date",
            headerName: "Date",

            width: 120,
        },
        {
            field: "type",
            headerName: "Type",

            width: 90,
        },
        {
            field: "num",
            headerName: "Num",

            width: 80,
        },
        {
            field: "cur",
            headerName: "Cur",

            width: 70,
        },
        {
            field: "CRAmount",
            headerName: "CR Amount",

            width: 140,

            valueGetter: (params) => {
                if (params.value !== 0) {
                    return params.value.toLocaleString() + " " + params.row.symbol;
                }
                return "";
            },
        },
        {
            field: "DBAmount",
            headerName: "DB Amount",

            width: 140,

            valueGetter: (params) => {
                if (params.value !== 0) {
                    return params.value.toLocaleString() + " " + params.row.symbol;
                }
                return "";
            },
        },
        {
            field: "line_rate",
            headerName: "Line Rate",

            width: 80,

            valueGetter: (params) => {
                return params.value.toLocaleString();
            },
        },
        {
            field: "vch_rate",
            headerName: `${props.rateCur.code} Rate`,

            width: 80,

            valueGetter: (params) => {
                return params.value.toLocaleString();
            },
        },
        {
            field: "sec_amount",
            headerName: `${props.rateCur.code} Amount`,

            width: 120,

            valueGetter: (params) => {
                return params.value.toLocaleString();
            },
        },
        {
            field: "seq_balance",
            headerName: "Balance",

            width: 140,

            valueGetter: (params) => {
                return params.value.toLocaleString() + " " + params.row.symbol;
            },
        },
        {
            field: "total_net_price_1",
            headerName: "Inv. Amount 1",

            width: 130,

            valueGetter: (params) => {
                if (params.value !== 0) {
                    return (
                        (
                            parseFloat(params.row.total_net_price_1) + parseFloat(params.row.total_tax_amount_1)
                        ).toLocaleString() +
                        " " +
                        params.row.pri_symbol
                    );
                }
                return "";
            },
        },
        {
            field: "total_tax_amount_1",
            headerName: "Inv. Tax 1",

            width: 120,

            valueGetter: (params) => {
                if (params.value !== 0) {
                    return parseFloat(params.row.total_tax_amount_1).toLocaleString() + " " + params.row.pri_symbol;
                }
                return "";
            },
        },
        {
            field: "note",
            headerName: "Note",

            width: 400,
        },
        {
            field: "total_net_price_2",
            headerName: "Inv. Amount 2",

            width: 130,

            valueGetter: (params) => {
                if (params.value !== 0) {
                    return (
                        (
                            parseFloat(params.row.total_net_price_2) + parseFloat(params.row.total_tax_amount_2)
                        ).toLocaleString() +
                        " " +
                        params.row.sec_symbol
                    );
                }
                return "";
            },
        },
        {
            field: "total_tax_amount_2",
            headerName: "Inv. Tax 2",

            width: 120,

            valueGetter: (params) => {
                if (params.value !== 0) {
                    return parseFloat(params.row.total_tax_amount_2).toLocaleString() + " " + params.row.sec_symbol;
                }
                return "";
            },
        },
        {
            field: "user",
            headerName: "User",

            width: 120,
        },
        {
            field: "sman",
            headerName: "Sman",

            width: 100,
        },
        {
            field: "branch",
            headerName: "Br.",

            width: 60,
        },
        {
            field: "job",
            headerName: "Job",

            width: 150,
        },
    ];

    return (
        <div className=" h-[90%] w-full" id="accDataTableCon">
            <DataTable
                rows={props.rows}
                columns={columns}
                getRowClassName={(e) => {
                    if (e.row.dbcr === "C") {
                        return " text-red-500    ";
                    }
                    return "  text-green-500 ";
                }}
                hideFooter={props.rows.length < 100}
                getRowId={(e) => {
                    return e.id.toString() + e.line.toString();
                }}
                slotProps={{
                    cell: { id: "datagridcell", className: " !border-gray-500 " },
                }}
                onPaginationModelChange={(e) => {
                    props.selectedPageRef.current = e.page;
                }}
                apiRef={props.gridRef}
                onCellDoubleClick={(e) => {
                    if (e.field === "type") {
                        window.localStorage.setItem("statementofaccount", "true");
                        window.localStorage.setItem("tempAccId", e.row.id);
                        props.setTempHide(true);
                        props.setTempHideTrial(true);
                        if (e.value[0] === "P") {
                            window.localStorage.setItem("useTemp", "true");
                            window.localStorage.setItem("tType", e.value);
                            window.localStorage.setItem("tNum", e.row.num);
                            nav("/purchase2");
                        } else if (e.value[0] === "S") {
                            window.localStorage.setItem("useTemp", "true");
                            window.localStorage.setItem("tType", e.value);
                            window.localStorage.setItem("tNum", e.row.num);
                            nav("/sales2");
                        } else if (e.value[0] === "J") {
                            window.localStorage.setItem("useTempAccounting", "true");
                            window.localStorage.setItem("tempAccType", e.value);
                            window.localStorage.setItem("tempAccNum", e.row.num);
                            nav("/vouchers");
                        } else if (e.value[0] === "D") {
                            window.localStorage.setItem("useTempAccounting", "true");
                            window.localStorage.setItem("tempAccType", e.value);
                            window.localStorage.setItem("tempAccNum", e.row.num);
                            nav("/vouchers");
                        } else if (e.value[0] === "C") {
                            window.localStorage.setItem("useTempAccounting", "true");
                            window.localStorage.setItem("tempAccType", e.value);
                            window.localStorage.setItem("tempAccNum", e.row.num);
                            nav("/vouchers");
                        } else if (e.value[0] === "T") {
                            window.localStorage.setItem("useTempAccounting", "true");
                            window.localStorage.setItem("tempAccType", e.value);
                            window.localStorage.setItem("tempAccNum", e.row.num);
                            nav("/vouchers");
                        }
                    } else if (e.field === "account_id") {
                        props.setaccountProfileModal(true);
                    }
                }}
            />
        </div>
    );
    function nav(to) {
        if (to === document.location.pathname) {
            document.location.pathname = to;
        } else {
            navigate(to);
        }
    }
}
