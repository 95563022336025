import Modal from "@mui/material/Modal";
import React, { useState, useEffect } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/material/Button";
import XButton from "../../app/XButton";
import backarrow from "../../../media/backArrow1.png";
import IconButton from "@mui/material/IconButton";
import Consignment from "./Consignment";

export default function InventoryManagmentModal(props) {
    const [shownScreenName, setshownScreenName] = useState("main");

    useEffect(() => {}, []);

    return (
        <Modal
            open={props.show}
            onTransitionEnter={() => {}}
            onClose={(e, r) => {
                props.setshow(false);
            }}
            disableRestoreFocus
            disableEnforceFocus
            disableEscapeKeyDown
            disableAutoFocus
            id={"InventoryManagment"}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "85vw",
                    height: "45rem",
                    border: "2px solid #000",
                    boxShadow: 24,
                    borderRadius: "0.8rem",
                    p: 0,
                    backgroundColor: "#27272a",
                }}
            >
                <div className="flex h-full flex-col justify-between rounded-xl bg-zinc-800  !text-[#c7c7c7]">
                    <div className=" m-1 flex flex-row justify-between border-b-2 border-gray-500">
                        <div className=" text-2xl font-semibold">Inventory Managment</div>
                        <div>
                            <XButton onClickFunc={props.setshow} />
                        </div>
                    </div>
                    <div className="flex h-full w-full flex-row flex-wrap items-start justify-start gap-4 p-2 text-lg  rounded-xl ">
                        {shownScreenName === "main" ? (
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setshownScreenName("ConsignmentItems");
                                    }}
                                >
                                    Consignment Items
                                </Button>
                            </>
                        ) : (
                            <div className="flex h-full w-full flex-col rounded-lg bg-zinc-800 border-zinc-900 border-2 ">
                                <div className="flex flex-row items-center gap-4 border-b-2 border-gray-600">
                                    <div>
                                        <IconButton
                                            style={{ width: "2.5rem", height: "2.5rem" }}
                                            variant="text"
                                            onClick={() => {
                                                setshownScreenName("main");
                                            }}
                                        >
                                            <img draggable={false} src={backarrow} alt="" />
                                        </IconButton>
                                    </div>
                                    <div>{"Consignment Items"}</div>
                                </div>
                                {shownScreenName === "ConsignmentItems" ? (
                                    <Consignment postdata={props.postdata} setshow={props.setshow} />
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
