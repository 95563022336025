import React, { useState, useEffect } from "react";

import Modal from "../../../app/Modal";
import SelectionMenu from "./SelectionMenu";
import ConfigurationPage from "./ConfigurationPage";

export default function PrinterConfigModal({ show, setshow, postdata }) {
    const [isSelected, setisSelected] = useState(false);
    const [selectedConf, setselectedConf] = useState();
    return (
        <Modal show={show} setshow={setshow} title="Printer Configuration" height="90vh" width="90vw">
            <>
                {!isSelected ? (
                    <SelectionMenu
                        selectedConf={selectedConf}
                        setselectedConf={setselectedConf}
                        setisSelected={setisSelected}
                        postdata={postdata}
                    />
                ) : (
                    <ConfigurationPage postdata={postdata} selectedConf={selectedConf} />
                )}
            </>
        </Modal>
    );
}
