import AdvancedTable from "../../app/AdvancedTable";
import React from "react";

export default function DataTable(props) {
  

    return (
        <>
            <AdvancedTable
                rows={props.rows}
                cols={props.multicolumns}
                showCellVerticalBorder
                showColumnVerticalBorder
                columnHeaderHeight={40}
                rowHeight={30}
                getRowId={(e) => {
                    return e[props.selectedGroupBy.value]
                }}
                hideFooter={props.rows.length < 100}
                onCellDoubleClick={(e) => {
                    if (e.field === "itemcode") {
                        props.settempItemCode(e.value);
                        props.setItemCardModal(true);
                    }
                }}
            />
        </>
    );
}
