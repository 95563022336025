import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/joy/Box";
import XButton from "../../app/XButton";

export default function Consignment(props) {
    const navigate = useNavigate();

    const [consignmentItems, setconsignmentItems] = useState([]);

    const [consignmentAccountRecord, setconsignmentAccountRecord] = useState({
        invs_list: [],
        accs_list: [],
        items_list: [],
    });

    const [recordView, setrecordView] = useState(true);

    const [selectedLine, setselectedLine] = useState("");

    const [showDeletetrans, setshowDeletetrans] = useState(false);

    useEffect(() => {
        getItems();
    }, []);

    return (
        <>
            <div className="h-full w-full rounded-lg bg-zinc-800 p-2">
                {recordView ? (
                    <>
                        <div className="flex w-full flex-row items-center justify-between gap-4 border-b-2 border-zinc-900 p-2">
                            <div className="flex flex-row  items-center gap-4 ">
                                <div>Selected Id: {selectedLine.id}</div>
                                <Button
                                    onClick={(e) => {
                                        if (selectedLine !== "") {
                                            setshowDeletetrans(true);
                                        }
                                    }}
                                    variant={"outlined"}
                                    color={"error"}
                                >
                                    <div className=" whitespace-nowrap">Delete Line</div>
                                </Button>
                            </div>
                            <div className=" w-fit">
                                <Button
                                    onClick={(e) => {
                                        setrecordView(false);
                                        selectConsignmentAccountRecord();
                                    }}
                                    variant={"outlined"}
                                >
                                    <div className=" whitespace-nowrap"> Detailed Record</div>
                                </Button>
                            </div>
                        </div>
                        <div className="flex h-[33.3rem] flex-col">
                            <div
                                className="mt-2 flex    flex-row items-center justify-around rounded bg-neutral-800 py-1  "
                                style={{ boxShadow: "black 0px 0px 2px 0px" }}
                            >
                                <div className=" w-[40%] text-center">Invoice Details</div>
                                <div className=" w-[15%] text-center">Transaction Id</div>
                                <div className=" w-[15%] text-center">Item Code</div>
                                <div className=" w-[30%] text-center">Item Name</div>
                            </div>
                            <div className="mt-2 flex h-full w-full flex-col gap-3 overflow-y-auto rounded border-2 border-zinc-900 p-2">
                                {consignmentItems.map((el) => {
                                    return (
                                        <div
                                            key={el.id}
                                            className="flex   select-none  flex-row items-center justify-around rounded bg-neutral-900 py-1 hover:bg-[#202020] "
                                            style={{
                                                boxShadow: "black 0px 0px 2px 0px",
                                                color: el.returned === "" ? "#ff3030" : "#62b45c",
                                                borderWidth: el.id === selectedLine.id ? "2px" : "2px",
                                                borderColor: el.id === selectedLine.id ? "#2f2f2f" : "#161616",
                                            }}
                                            onClick={() => {
                                                setselectedLine(el);
                                            }}
                                        >
                                            <div className="w-[40%] text-center">
                                                {el.type} - {el.num} - {el.debit_acc} - {el.name1}
                                            </div>
                                            <div className=" h-full border-[1px] border-zinc-900"></div>
                                            <div className="w-[15%] text-center">{el.id}</div>
                                            <div className=" h-full border-[1px] border-zinc-900"></div>
                                            <div className="w-[15%] text-center">{el.itemcode}</div>
                                            <div className=" h-full border-[1px] border-zinc-900"></div>
                                            <div className="w-[30%] text-center">{el.itemname1}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex w-full flex-row items-center justify-between gap-4 border-b-2 border-zinc-900 p-2">
                            <div className="flex w-full flex-row justify-between ">
                                <div></div>
                                <Button
                                    onClick={(e) => {
                                        setrecordView(true);
                                        getItems();
                                    }}
                                    variant={"outlined"}
                                >
                                    <div className=" whitespace-nowrap">All Transactions</div>
                                </Button>
                            </div>
                        </div>
                        <div className="h-[33.3rem] ">
                            <Tabs
                                defaultActiveKey="invrec"
                                id="justify-tab-example"
                                className="mb-3 bg-zinc-800 !text-[#c7c7c7]"
                                justify
                            >
                                <Tab
                                    tabClassName=" !bg-zinc-800 !text-[#c7c7c7]"
                                    eventKey="invrec"
                                    title="Record By Invoice"
                                >
                                    <div className="flex h-[30rem] w-[100%] flex-col">
                                        <div className="mt-2 flex h-full w-full flex-col gap-3 overflow-y-auto rounded  p-2">
                                            {consignmentAccountRecord.invs_list.map((el) => {
                                                return (
                                                    <div
                                                        key={el.inv_id}
                                                        className="flex  select-none flex-col  rounded bg-neutral-900  p-1 hover:bg-[#202020] "
                                                        style={{
                                                            boxShadow: "black 0px 0px 2px 0px",
                                                        }}
                                                        onClick={() => {
                                                            window.localStorage.setItem("useTemp", "true");
                                                            window.localStorage.setItem("tType", el.type);
                                                            window.localStorage.setItem("tNum", el.num);
                                                            navigate(el.type[0] === "P" ? "/purchase" : "/sales");
                                                        }}
                                                    >
                                                        <div className="flex cursor-pointer select-none flex-row items-center justify-evenly border-b-2 border-zinc-900">
                                                            <div className="w-[20%] text-center">
                                                                {el.type} - {el.num} - {el.name1}
                                                            </div>
                                                            <div className="w-[20%] whitespace-nowrap text-center">
                                                                {el.date}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {el.rec_list.map((rec) => {
                                                                return (
                                                                    <div
                                                                        key={rec.id}
                                                                        className="flex flex-row items-center justify-around rounded   "
                                                                        style={{
                                                                            color:
                                                                                rec.returned === "" ? "#ff3030" : "#62b45c",
                                                                        }}
                                                                    >
                                                                        <div className="w-[20%] border-r-2 border-zinc-900 text-center">
                                                                            {rec.id}
                                                                        </div>
                                                                        <div className="w-[20%] border-r-2 border-zinc-900 text-center">
                                                                            {rec.itemcode}
                                                                        </div>
                                                                        <div className="w-[60%] text-center">
                                                                            {rec.itemname1}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    tabClassName=" !bg-zinc-800 !text-[#c7c7c7]"
                                    eventKey="accrec"
                                    title="Record By Account"
                                >
                                    <div className="flex h-[30rem] w-[100%] flex-col">
                                        <div className="mt-2 flex h-full w-full flex-col gap-3 overflow-y-auto  p-2">
                                            {consignmentAccountRecord.accs_list.map((el) => {
                                                return (
                                                    <div
                                                        key={el.acc_id}
                                                        className="flex  select-none flex-col  rounded bg-neutral-900 p-1 hover:bg-[#202020] "
                                                        style={{
                                                            boxShadow: "black 0px 0px 2px 0px",
                                                        }}
                                                    >
                                                        <div className="flex cursor-pointer select-none flex-row items-center justify-evenly border-b-2 border-zinc-900">
                                                            <div className="w-[20%] text-center">
                                                                {el.acc_id} - {el.name1}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {el.rec_list.map((rec) => {
                                                                return (
                                                                    <div
                                                                        key={rec.id}
                                                                        className="flex flex-row items-center justify-around rounded   "
                                                                        style={{
                                                                            color:
                                                                                rec.returned === "" ? "#ff3030" : "#62b45c",
                                                                        }}
                                                                    >
                                                                        <div className="w-[20%] border-r-2 border-zinc-900 text-center">
                                                                            {rec.id}
                                                                        </div>
                                                                        <div className="w-[20%] border-r-2 border-zinc-900 text-center">
                                                                            {rec.itemcode}
                                                                        </div>
                                                                        <div className="w-[60%] text-center">
                                                                            {rec.itemname1}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab tabClassName=" !bg-zinc-800 !text-[#c7c7c7]" eventKey="itemsrec" title="Record By Item">
                                    <div className="flex h-[30rem] w-[100%]  flex-col rounded bg-zinc-800 p-2">
                                        <div className="mt-2 flex h-full w-full flex-col gap-3 overflow-y-auto  p-2">
                                            {consignmentAccountRecord.items_list.map((el) => {
                                                return (
                                                    <div
                                                        key={el.itemcode}
                                                        className="flex   select-none  flex-row items-center justify-around rounded bg-neutral-900 py-1 hover:bg-[#202020] "
                                                        style={{
                                                            boxShadow: "black 0px 0px 2px 0px",
                                                        }}
                                                    >
                                                        <div className="w-[20%] text-center">{el.itemcode}</div>
                                                        <div className=" h-full border-[1px] border-zinc-900"></div>
                                                        <div className="w-[50%] text-center">{el.itemname1}</div>
                                                        <div className=" h-full border-[1px] border-zinc-900"></div>
                                                        <div className="w-[10%] text-green-400 text-center">{el.returned}</div>
                                                        <div className=" h-full border-[1px] border-zinc-900"></div>
                                                        <div className="flex w-[20%] flex-row justify-center gap-4 text-center">
                                                            <div className=" text-blue-400">{el.whqty}</div>
                                                            <div>-</div>
                                                            <div className=" text-red-400">{el.not_returned}</div>
                                                            <div>{"="}</div>
                                                            <div>{el.whqty - el.not_returned}</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </>
                )}
            </div>
            <Modal
                open={showDeletetrans}
                onTransitionEnter={() => {}}
                onClose={(e, r) => {
                    setshowDeletetrans(false);
                }}
                disableRestoreFocus
                disableEnforceFocus
                disableEscapeKeyDown
                disableAutoFocus
                id={"DeleteConsignmentTrnasactionModal"}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "45vw",
                        height: "15rem",
                        border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: "0.8rem",
                        p: 0,
                        backgroundColor: "#27272a",
                    }}
                >
                    <div className="flex h-full flex-col justify-between rounded-xl bg-zinc-800  !text-[#c7c7c7]">
                        <div className=" m-1 flex flex-row justify-between border-b-2 border-gray-500">
                            <div className=" text-2xl font-semibold">Are You Sure You Want To Delete Transaction:</div>
                            <div>
                                <XButton
                                    onClickFunc={() => {
                                        setshowDeletetrans(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex h-full w-full flex-col flex-wrap items-start justify-between gap-4 rounded-xl p-2  text-lg ">
                            <div
                                className="flex   w-full select-none flex-row items-center justify-around rounded bg-neutral-900 py-1 hover:bg-[#202020] "
                                style={{
                                    boxShadow: "black 0px 0px 2px 0px",
                                    borderWidth: "2px",
                                    borderColor: "#ff7070",
                                }}
                            >
                                <div className="w-[40%] text-center">
                                    {selectedLine.type} - {selectedLine.num} - {selectedLine.debit_acc} -{" "}
                                    {selectedLine.name1}
                                </div>
                                <div className=" h-full border-[1px] border-zinc-900"></div>
                                <div className="w-[15%] text-center">{selectedLine.id}</div>
                                <div className=" h-full border-[1px] border-zinc-900"></div>
                                <div className="w-[15%] text-center">{selectedLine.itemcode}</div>
                                <div className=" h-full border-[1px] border-zinc-900"></div>
                                <div className="w-[30%] text-center">{selectedLine.itemname1}</div>
                            </div>
                            <div className=" flex w-full flex-row justify-between ">
                                <div className=""></div>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        deleteLine();
                                    }}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
    function getItems() {
        let url = "invoice/select_consignments/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setconsignmentItems(res.data.data);
            }
        };

        props.postdata(url, cbnf, { filter: ` ` }, false);
    }

    function selectConsignmentAccountRecord() {
        let url = "invoice/select_consignment_account_record/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setconsignmentAccountRecord({
                    invs_list: res.data.data.invs_record,
                    accs_list: res.data.data.acc_record,
                    items_list: res.data.data.items,
                });
            }
        };
        const fdata = {
            filter: ` `,
        };
        props.postdata(url, cbnf, fdata, false);
    }
    function deleteLine() {
        let url = "invoice/delete_consignment_record/";
        const cbnf = (res) => {
            getItems();
            setshowDeletetrans(false);
        };
        const fdata = {
            trans_id: selectedLine.id,
        };
        props.postdata(url, cbnf, fdata, true);
    }
}
