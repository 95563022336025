import React, { createContext, useState, useMemo, useRef } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { MutableRefObject } from "react";
import { asyncPostData, postData } from "../lib/Axios";

// Create a context for the alert provider
export const AlertContext = createContext<AlertAPI>(null);

// Create the AlertProvider component
const AlertProvider: React.FC<any> = ({ children }) => {
    const alertAPI = useRef<AlertAPI>({ showAlert: () => {}, hideAlert: () => {} });

    return (
        <AlertContext.Provider value={alertAPI.current}>
            {children}
            <Alert alertAPI={alertAPI} />
        </AlertContext.Provider>
    );
};

export default AlertProvider;

function Alert({ alertAPI }: { alertAPI: MutableRefObject<AlertAPI | null> }) {
    function handleSeverty() {
        if (alertStatus === "authurized") {
            return "success";
        } else if (alertStatus === "unauthurized") {
            return "warning";
        } else {
            return "error";
        }
    }
    const [show, setshow] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const [alertStatus, setAlertStatus] = useState<string>("");

    // Function to display the alert
    const showAlert = (status, message: string) => {
        setAlertMessage(message);
        setAlertStatus(status);
        setshow(true);
    };

    // Function to hide the alert
    const hideAlert = () => {
        setshow(false);
    };

    async function asyncpostData(url: string, data: any, cbnf: (res) => void, showAlert, onError: (res) => void = (res) => {}) {
        return await asyncPostData(url, cbnf, data, showAlert, alertAPI.current, onError);
    }
    async function postdata(url: string, cbnf: (res) => void, data: any, showAlert, onError: (res) => void = (res) => {}) {
        return await postData(url, cbnf, data, showAlert, alertAPI.current, onError);
    }

    alertAPI.current = { showAlert, hideAlert, asyncPostData: asyncpostData, postdata: postdata };
    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
                open={show}
                autoHideDuration={5000}
                onClose={() => {
                    hideAlert();
                }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        hideAlert();
                    }}
                    severity={handleSeverty()}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </Stack>
    );
}

export const useAlert = () => {
    const alertAPI = React.useContext(AlertContext);
    if (!alertAPI) {
        throw new Error("useAlert must be used within a AlertProvider");
    }
    return alertAPI;
};

export type AlertAPI = {
    showAlert: (status, message: string) => void;
    hideAlert: () => void;
    asyncPostData?: (url: string, data: any, cbnf: (res) => void, showAlert, onError?: (res) => void) => Promise<any>;
    postdata?: (url: string, cbnf: (res) => void, data: any, showAlert, onError?: (res) => void) => Promise<any>;
};
