import Button from "../../app/Button";
import React, { useState } from "react";
import LoadingSpinner from "../../app/LoadingSpinner";
import { Box } from "@mui/material";
import Select from "../../app/Select";
import FilterSelectionModal from "../../Common/FilterSelectionModal/FilterSelectionModal";
import DateField from "../../app/DateField";

export default function MultiItem(props) {
    const [showModal, setshowModal] = useState(false);

    const [selectedFilter, setselectedFilter] = useState("");

    const filterOptions = [
        {
            title: "Inv. Types",
            filter: "inv_type",
        },
        {
            title: "Goods Types",
            filter: "goods_type",
        },
        {
            title: "Branches",
            filter: "branch",
        },
        {
            title: "S.Men",
            filter: "sman",
        },
        {
            title: "Jobs",
            filter: "job",
        },
        {
            title: "Categories",
            filter: "category",
        },
        {
            title: "Sets",
            filter: "set",
        },
        {
            title: "Brands",
            filter: "brand",
        },
        {
            title: "Colors",
            filter: "color",
        },
        {
            title: "Units",
            filter: "unit",
        },
        {
            title: "Families",
            filter: "family",
        },
    ];
    const dataOptions = [
        { label: "Average" },
        { label: "Last" },
        // { label: "Relative" }
    ];
    const limitOptions = [{ value: 0 }, { value: 10 }, { value: 100 }, { value: 1000 }, { value: 10000 }, { value: 50000 }];

    const [selectedOrderBy, setselectedOrderBy] = useState("itemcode");
    const [orderType, setorderType] = useState("Desc");
    const [limit, setLimit] = useState(0);

    return (
        <>
            <div className="top  flex h-[30%] flex-row justify-evenly gap-2 ">
                <Box
                    sx={{ backgroundColor: "modal.secondaryBG" }}
                    className="flex w-[10%] flex-row items-center justify-around rounded p-2 "
                >
                    <div className=" flex w-[10rem] flex-col gap-4 ">
                        <Button
                            onClick={(e) => {
                                props.getData();
                            }}
                            size="small"
                            disabled={props.isLoading}
                        >
                            Get Data - F5
                            <LoadingSpinner show={props.isLoading} />
                        </Button>
                        <Button
                            onClick={() => {
                                props.setshowPrintModal(true);
                            }}
                            disabled={props.allowEdit}
                            size="small"
                        >
                            Print Report
                        </Button>

                        <Button
                            size="small"
                            theme="error"
                            onClick={(e) => {
                                props.clearData();
                            }}
                        >
                            Clear Data - F8
                        </Button>
                    </div>
                </Box>

                <Box
                    sx={{ backgroundColor: "modal.secondaryBG" }}
                    className="flex w-[70%] flex-col flex-wrap justify-evenly gap-1 overflow-x-auto rounded p-1 "
                >
                    {filterOptions.map((item, index) => {
                        return (
                            <Box
                                sx={{ borderColor: "modal.borderColor" }}
                                key={index}
                                className="flex flex-col gap-2 rounded border-2 p-1 "
                            >
                                <div className=" text-xl font-semibold">Selected {item.title}:</div>
                                <div>{props.filters[item.filter].toString() || "All"}</div>
                                <Button
                                    onClick={(e) => {
                                        setselectedFilter(item.filter);
                                        setshowModal(true);
                                    }}
                                    disabled={!props.allowEdit}
                                >
                                    {item.title}
                                </Button>
                            </Box>
                        );
                    })}
                </Box>
                <Box
                    sx={{ backgroundColor: "modal.secondaryBG" }}
                    className="flex w-[20%] flex-col justify-around gap-2 rounded p-2"
                >
                    <div className="flex  flex-row gap-1">
                        <DateField
                            value={props.startDate}
                            label="Start Date"
                            confirmFunc={props.setstartDate}
                            nextFocus={() => {
                                props.endDateFieldRef.current.focus();
                            }}
                            inputRef={props.startDateFieldRef}
                            disabled={!props.allowEdit}
                        />
                        <DateField
                            value={props.endDate}
                            label="End Date"
                            confirmFunc={props.setendDate}
                            inputRef={props.endDateFieldRef}
                            disabled={!props.allowEdit}
                        />
                    </div>
                    <div className="flex  flex-row gap-1">
                        <Select
                            allowNone={false}
                            value={props.selectedCur.code}
                            label="Currency"
                            id="currency"
                            onChange={(e) => {
                                props.currencyList.forEach((cur) => {
                                    if (cur.code === e.target.value) {
                                        props.setselectedCur(cur);
                                    }
                                });
                            }}
                            options={props.currencyList}
                            menuValue={"code"}
                            menuLabels={["code", "name"]}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                        />
                        <Select
                            allowNone={false}
                            value={limit}
                            label="Row Count"
                            id="limit"
                            onChange={(e) => {
                                setLimit(e.target.value);
                            }}
                            sRef={props.limitFieldRef}
                            options={limitOptions}
                            menuValue={"value"}
                            menuLabels={["value"]}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                        />
                    </div>
                    <div className="flex  flex-row gap-1">
                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedBy}
                            label="Calc. By"
                            id="By"
                            onChange={(e) => {
                                props.setselectedBy(e.target.value);
                            }}
                            options={dataOptions}
                            menuValue={"label"}
                            menuLabels={["label"]}
                        />
                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={props.selectedGroupBy.value}
                            label="Group By"
                            id="Group By"
                            name="Group By"
                            onChange={(e) => {
                                props.groupByOptions.forEach((item) => {
                                    if (item.value === e.target.value) {
                                        props.setselectedGroupBy(item);
                                        setselectedOrderBy(item.value);
                                    }
                                });
                            }}
                            options={props.groupByOptions}
                            menuValue={"value"}
                            menuLabels={["label"]}
                        />
                    </div>
                    <div className="flex  flex-row gap-1">
                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            label="Order By"
                            id="Order By"
                            sRef={props.orderByFieldRef}
                            value={selectedOrderBy}
                            onChange={(e) => {
                                props.multicolumns.forEach((item) => {
                                    if (item.field === e.target.value) {
                                        setselectedOrderBy(item.field);
                                    }
                                });
                            }}
                            options={[
                                ...props.multicolumns
                                    .filter((e) => !e.hidden)
                                    .map((item) => {
                                        return { value: item.field, label: item.headerName };
                                    }),
                            ]}
                            menuValue={"value"}
                            menuLabels={["label"]}
                        />
                        <Select
                            allowNone={false}
                            sx={{ width: "100%" }}
                            disabled={!props.allowEdit}
                            value={orderType}
                            label="Order Type"
                            id="Order Type"
                            sRef={props.orderTypeFieldRef}
                            onChange={(e) => {
                                setorderType(e.target.value);
                            }}
                            options={[{ value: "Asc" }, { value: "Desc" }]}
                            menuValue={"value"}
                            menuLabels={["value"]}
                        />
                    </div>
                </Box>
            </div>
            {/* -------------------------------- Select Attribute Modal Modal -------------------------------- */}
            {showModal ? (
                <FilterSelectionModal
                    show={showModal}
                    setshow={setshowModal}
                    options={props.filterOptionsLists}
                    selectedFilter={selectedFilter}
                    setSelectedOptions={props.setfilters}
                    selectedOptions={props.filters}
                />
            ) : null}
        </>
    );
}
