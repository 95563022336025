import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ConfirmModal(props) {
    return (
        <>
            <Modal
                show={props.show}
                onHide={() => {
                    props.handleClose(false);
                }}
                centered
                className=" bg-[#0000002e]"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.data.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.data.text}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.handleClose(false);
                        }}
                    >
                        No
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            props.callBackFun();
                        }}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
