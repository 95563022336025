import React, { useEffect, useState } from "react";
import Tabs from "../../app/Tabs";
import CurrenciesTab from "../SystemSettingsTabs/CurrenciesTab";
import InvTypesTab from "../SystemSettingsTabs/InvTypesTab";
import VchTypesTab from "../SystemSettingsTabs/VchTypesTab";
import SmanTab from "../SystemSettingsTabs/SmanTab";
import BranchesTab from "../SystemSettingsTabs/BranchesTab";
import CompanyInfo from "../SystemSettingsTabs/CompanyInfo";
import JobsTab from "../SystemSettingsTabs/JobTab";
import ShippersTab from "../SystemSettingsTabs/ShippersTab";
import DeliverymenTab from "../SystemSettingsTabs/DeliverymenTab";
import DefaultSettings from "../SystemSettingsTabs/DefaultSettings";
import DepartmentTab from "../SystemSettingsTabs/DepartmentTab";

export default function SystemSettings({ postdata, show }) {
    const [currencySettings, setcurrencySettings] = useState({
        data: [],
    });
    const [invTypes, setinvTypes] = useState({
        data: [],
    });
    const [vchTypes, setvchTypes] = useState({
        data: [],
    });
    const [companySettings, setcompanySettings] = useState({
        id: 1,
        comp_name: "",
        comp_name_2: "",
        tax_number: "",
        tax_percentage: 0,
        enforce_tax: "",
        address: "",
        country: "",
        province: "",
        city: "",
        steet: "",
        building: "",
        floor: "",
        year_from: "2023-01-01",
        year_to: "2023-12-31",
        use_tax_rate_pos: "",
        pos_switch_cur: 0,
        pos_round_on: 0,
        pos_round_con: "AUTO",
    });
    const [branchList, setbranchList] = useState([{ num: 1, name: "Main Branch" }]);
    const [departmentList, setdepartmentList] = useState([]);
    const [smanList, setsmanList] = useState([{ name: "No Sman", description: "" }]);
    const [jobList, setjobList] = useState([{ name: "No Job", description: "" }]);
    const [shippers, setshippers] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        getSystemSettings();
    }, []);
    return (
        <Tabs
            value={value}
            setvalue={setValue}
            tabsList={[
                { value: 1, label: "Default Settings" },
                { value: 2, label: "Company Info" },
                { value: 3, label: "Branches" },
                { value: 4, label: "Departments" },
                { value: 5, label: "Sales Man" },
                { value: 6, label: "Jobs" },
                { value: 7, label: "Voucher Types" },
                { value: 8, label: "Invoice Types" },
                { value: 9, label: "Currencies" },
                { value: 10, label: "Shippers" },
                { value: 11, label: "Deliverymen" },
            ]}
        >
            {value === 1 ? (
                <DefaultSettings postdata={postdata} data={companySettings} setdata={setcompanySettings} getSystemSettings={getSystemSettings} />
            ) : null}
            {value === 2 ? (
                <CompanyInfo postdata={postdata} data={companySettings} setdata={setcompanySettings} getSystemSettings={getSystemSettings} />
            ) : null}
            {value === 3 ? <BranchesTab branches={branchList} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 4 ? <DepartmentTab branches={departmentList} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 5 ? <SmanTab sman={smanList} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 6 ? <JobsTab sman={jobList} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 7 ? <VchTypesTab vchTypes={vchTypes} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 8 ? <InvTypesTab invTypes={invTypes} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 9 ? <CurrenciesTab currencySettings={currencySettings} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 10 ? <ShippersTab sman={shippers} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
            {value === 11 ? <ShippersTab sman={shippers} getSystemSettings={getSystemSettings} postdata={postdata} /> : null}
        </Tabs>
    );

    function getSystemSettings() {
        let url = "settings/get_system_settings/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setcurrencySettings(res.data.data.currencies);
                setinvTypes(res.data.data.inv_types);
                setvchTypes(res.data.data.vch_types);
                setcompanySettings(res.data.data.company_settings);
            }
        };

        postdata(url, cbnf, {}, false);

        url = "invoice/get_branches/";
        const cbnf1 = (res) => {
            if (res.data.info === "success") {
                setbranchList(res.data.data);
            }
        };

        postdata(url, cbnf1, {}, false);

        url = "invoice/get_sman/";
        const cbnf2 = (res) => {
            if (res.data.info === "success") {
                setsmanList(res.data.data);
            }
        };

        postdata(url, cbnf2, {}, false);

        url = "invoice/get_jobs/";
        const cbnf3 = (res) => {
            if (res.data.info === "success") {
                setjobList(res.data.data);
            }
        };

        postdata(url, cbnf3, {}, false);

        url = "settings/get_departments/";
        const cbnf4 = (res) => {
            if (res.data.info === "success") {
                setdepartmentList(res.data.data);
            }
        };

        postdata(url, cbnf4, {}, false);

        url = "settings/get_shippers/";
        const cbnf5 = (res) => {
            if (res.data.info === "success") {
                setshippers(res.data.data);
            }
        };

        postdata(url, cbnf5, {}, false);
    }
}
