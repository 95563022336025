import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./output.css";
import ErrorHandler from "./components/app/ErrorHandler";


const root = createRoot(document.getElementById("root"));
root.render(
    <ErrorHandler>
        <App />
    </ErrorHandler>
);
