import React, { createContext, useState, useContext } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts(props) {
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        props.setopen(false);
    };
    function handleSeverty() {
        if (props.message.status === "authurized") {
            return "success";
        } else if (props.message.status === "unauthurized") {
            return "warning";
        } else {
            return "error";
        }
    }
    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
                open={props.open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert  onClose={handleClose} severity={handleSeverty()} sx={{ width: "100%" }}>
                    {props.message.msg}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
