import TextField from "../../app/TextField";
import React, { useState } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers";
import Button from "../../app/Button";
import Select from "../../app/Select";
import { Box } from "@mui/material";
import FilterSelectionModal from "../../Common/FilterSelectionModal/FilterSelectionModal";
import { useLanguage } from "../../app/LanguageContext";

export default function AccountsFilters(props) {
    const {lang} = useLanguage()
    const [showModal, setshowModal] = useState(false);
    const [selectedFilter, setselectedFilter] = useState("");

    const conditionOptions = [
        { name: "Equals" },
        { name: "Contains" },
        { name: "Greater Than" },
        { name: "Less Than" },
        { name: "Start With" },
        { name: "End With" },
        { name: "Not Equal" },
        { name: "Not Like" },
    ];
    const filterList = [
        {
            num: 1,
            conName: lang['FILTER_1'],
            conChange: (e) => {
                props.setcon1(e);
            },
            conValue: props.con1,
            filName: lang['VALUE_1'],
            filChange: (e) => {
                props.setfilter1(e);
            },
            filValue: props.filter1,
        },
        {
            num: 2,
            conName: lang['FILTER_2'],
            conChange: (e) => {
                props.setcon2(e);
            },
            conValue: props.con2,
            filName: lang['VALUE_2'],
            filChange: (e) => {
                props.setfilter2(e);
            },
            filValue: props.filter2,
        },
        {
            num: 3,
            conName: lang['FILTER_3'],
            conChange: (e) => {
                props.setcon3(e);
            },
            conValue: props.con3,
            filName: lang['VALUE_3'],
            filChange: (e) => {
                props.setfilter3(e);
            },
            filValue: props.filter3,
        },
        {
            num: 4,
            conName: lang['FILTER_4'],
            conChange: (e) => {
                props.setcon4(e);
            },
            conValue: props.con4,
            filName: lang['VALUE_4'],
            filChange: (e) => {
                props.setfilter4(e);
            },
            filValue: props.filter4,
        },
    ];

    return (
        <>
            <div className=" flex h-[100%] flex-col justify-between gap-2 rounded-lg  p-2">
                <Box
                    sx={{ backgroundColor: "modal.secondaryBG" }}
                    className="flex h-full flex-col items-center rounded  p-2  "
                >
                    <div className=" h-[3rem] w-full border-b-2  text-center  text-2xl">{lang['ACCOUNTS_FILTERS']}:</div>
                    <div className="flex h-full flex-col justify-around gap-1 ">
                        {filterList.map((el) => {
                            return (
                                <div className="flex flex-row justify-around gap-1" key={el.num}>
                                    <Select
                                        value={el.conValue}
                                        onChange={(e) => {
                                            el.conChange(e.target.value);
                                        }}
                                        options={conditionOptions}
                                        menuValue={"name"}
                                        menuLabels={["name"]}
                                        label={el.conName}
                                        id={el.conName}
                                        width={"40%"}
                                        disabled={!props.allowEdit}
                                        allowNone={false}
                                    />

                                    <TextField
                                        sx={{ width: "30%" }}
                                        onChange={(e) => {
                                            el.filChange(e.target.value);
                                        }}
                                        size="small"
                                        label={el.filName}
                                        value={el.filValue}
                                        id={el.filName}
                                        disabled={!props.allowEdit}
                                    />
                                    <Button
                                        sx={{ width: "20%" }}
                                        type={"number"}
                                        onClick={(e) => {
                                            props.settempAcc(el.filValue);
                                            props.settempNum(el.num);
                                            props.setshowDataGridModal(true);
                                        }}
                                        disabled={!props.allowEdit}
                                    >
                                        {lang['FIND']}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </Box>
                <Box
                    sx={{ backgroundColor: "modal.secondaryBG" }}
                    className=" h-[100%]  gap-3 overflow-y-auto  rounded  p-2  "
                >
                    <div className=" h-[3rem] w-full border-b-2  text-center text-2xl">{lang['OTHER_FILTERS']}:</div>

                    <div className="flex flex-col gap-2">
                        <div className=" text-xl font-semibold">{lang['DATE']}</div>
                        <div className="flex w-full flex-row gap-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    label={lang['START_DATE']}
                                    size="small"
                                    value={dayjs(props.startDate)}
                                    format="DD/MM/YYYY"
                                    id="startDate"
                                    onChange={(newValue) => {
                                        if (newValue.isValid()) {
                                            props.setstartDate(newValue.format("YYYY-MM-DD"));
                                        }
                                    }}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("endDate").focus();
                                        }
                                    }}
                                />
                                <DateField
                                    label={lang['END_DATE']}
                                    value={dayjs(props.endDate)}
                                    id="endDate"
                                    size="small"
                                    format="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        if (newValue.isValid()) {
                                            props.setendDate(newValue.format("YYYY-MM-DD"));
                                        }
                                    }}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("startDate").focus();
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 p-2 ">
                        <div className=" text-xl font-semibold">{lang['SELECTED_VOUCHER_TYPES']}:</div>
                        <div>{props.filters.type.toString() || "All"}</div>
                        <Button
                            onClick={(e) => {
                                setselectedFilter("type");
                                setshowModal(true);
                            }}
                            disabled={!props.allowEdit}
                        >
                            {lang['VOUCHER_TYPES']}
                        </Button>
                    </div>
                    <div className="flex flex-col gap-2 p-2 ">
                        <div className=" text-xl font-semibold">{lang['SELECTED_BRANCHES']}:</div>
                        <div>{props.filters.branch.toString() || "All"}</div>
                        <Button
                            onClick={(e) => {
                                setselectedFilter("branch");
                                setshowModal(true);
                            }}
                            disabled={!props.allowEdit}
                        >
                            {lang['BRANCHES']}
                        </Button>
                    </div>
                    <div className="flex flex-col gap-2 p-2 ">
                        <div className=" text-xl font-semibold">{lang['SELECTED_SALESMAN']}:</div>
                        <div>{props.filters.sman.toString() || "All"}</div>
                        <Button
                            onClick={(e) => {
                                setselectedFilter("sman");
                                setshowModal(true);
                            }}
                            disabled={!props.allowEdit}
                        >
                            {lang['SALES_MAN']}
                        </Button>
                    </div>
                    <div className="flex flex-col gap-2 p-2 ">
                        <div className=" text-xl font-semibold">{lang['SELECTED_JOBS']}:</div>
                        <div>{props.filters.job.toString() || "All"}</div>
                        <Button
                            onClick={(e) => {
                                setselectedFilter("job");
                                setshowModal(true);
                            }}
                            disabled={!props.allowEdit}
                        >
                            {lang['JOBS']}
                        </Button>
                    </div>
                </Box>
                <Box
                    sx={{ backgroundColor: "modal.secondaryBG" }}
                    className=" flex h-[3rem] flex-row items-center justify-around rounded  p-2"
                >
                    <Button
                        theme="error"
                        onClick={(e) => {
                            props.resetFilters();
                        }}
                    >
                        {lang['CLEAR_DATA']}
                    </Button>
                    <Button
                        onClick={(e) => {
                            props.getData();
                        }}
                    >
                        {lang['GET_DATA']}
                    </Button>
                </Box>
            </div>
            {showModal && (
                <FilterSelectionModal
                    show={showModal}
                    setshow={setshowModal}
                    options={props.filterOptions}
                    selectedOptions={props.filters}
                    setSelectedOptions={props.setfilters}
                    selectedFilter={selectedFilter}
                />
            )}
        </>
    );
}
