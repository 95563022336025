import TextField from "../../app/TextField";
import React,{ useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateField } from "@mui/x-date-pickers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/material";

export default function CompanyInfo(props) {
    const [temp, settemp] = useState("");
    return (
        <Box sx={{ backgroundColor: "accordions.primaryBG" }} className="flex h-full w-full flex-col  p-2">
            <div className="flex h-full w-full flex-col overflow-y-auto  ">
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <div className=" font-semibold ">General Information</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-col rounded-xl  py-1" id={"DiscountTab"}>
                            <div className="my-2 flex flex-row justify-around">
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"Company Name 1"}
                                    sx={{ marginX: 3, width: "50%" }}
                                    value={document.activeElement.id === "cpn1" ? temp : props.data.comp_name}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("cpn2").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("comp_name", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="cpn1"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"Company Name 2"}
                                    sx={{ marginX: 3, width: "50%" }}
                                    value={document.activeElement.id === "cpn2" ? temp : props.data.comp_name_2}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("tnum").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("comp_name_2", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="cpn2"
                                />
                            </div>
                            <div className=" my-2 flex flex-row justify-start">
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "30%" }}
                                    label={"Tax Number"}
                                    value={document.activeElement.id === "tnum" ? temp : props.data.tax_number}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("dtax").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("tax_number", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="tnum"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "10rem" }}
                                    label={"Default Tax %"}
                                    value={document.activeElement.id === "dtax" ? temp : props.data.tax_percentage}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("enf").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("tax_percentage", parseFloat(e.target.value));
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="dtax"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "10rem" }}
                                    label={"Enforce Tax"}
                                    value={document.activeElement.id === "enf" ? temp : props.data.enforce_tax}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("email1").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("enforce_tax", e.target.value === "" ? "" : "Y");
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="enf"
                                />
                            </div>
                            <div className=" my-2 flex flex-row justify-start">
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "50%" }}
                                    label={"Email 1"}
                                    value={document.activeElement.id === "email1" ? temp : props.data.email1}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("email2").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("email1", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="email1"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "50%" }}
                                    label={"Email2"}
                                    value={document.activeElement.id === "email2" ? temp : props.data.email2}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("phone1").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("email2", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="email2"
                                />
                            </div>
                            <div className=" my-2 flex flex-row justify-start">
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "30%" }}
                                    label={"Phone Number 1"}
                                    value={document.activeElement.id === "phone1" ? temp : props.data.phone1}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("phone2").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("phone1", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="phone1"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "30%" }}
                                    label={"Phone Number 2"}
                                    value={document.activeElement.id === "phone2" ? temp : props.data.phone2}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("phone3").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("phone2", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="phone2"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "30%" }}
                                    label={"Phone Number 3"}
                                    value={document.activeElement.id === "phone3" ? temp : props.data.phone3}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("website1").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("phone3", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="phone3"
                                />
                            </div>
                            <div className=" my-2 flex flex-row justify-start">
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "50%" }}
                                    label={"Web Site 1"}
                                    value={document.activeElement.id === "website1" ? temp : props.data.website1}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("website2").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("website1", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="website1"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    sx={{ marginX: 3, width: "50%" }}
                                    label={"Web Site 2"}
                                    value={document.activeElement.id === "website2" ? temp : props.data.website2}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("cpn1").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("website2", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="website2"
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <div className=" font-semibold ">Address</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-col rounded-xl  py-1" id={"DiscountTab"}>
                            <div className="my-2 flex flex-row justify-around">
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"Country"}
                                    sx={{ marginX: 3, width: "20rem" }}
                                    value={document.activeElement.id === "country" ? temp : props.data.country}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("province").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("country", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="country"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"Province"}
                                    sx={{ marginX: 3, width: "20rem" }}
                                    value={document.activeElement.id === "province" ? temp : props.data.province}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("city").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("province", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="province"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"City"}
                                    sx={{ marginX: 3, width: "20rem" }}
                                    value={document.activeElement.id === "city" ? temp : props.data.city}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("cpn2").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("city", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="city"
                                />
                            </div>
                            <div className="my-2 flex flex-row justify-around">
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"Street"}
                                    sx={{ marginX: 3, width: "20rem" }}
                                    value={document.activeElement.id === "street" ? temp : props.data.street}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("building").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("street", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="street"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"Building"}
                                    sx={{ marginX: 3, width: "20rem" }}
                                    value={document.activeElement.id === "building" ? temp : props.data.building}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("floor").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("building", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="building"
                                />
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"Floor"}
                                    sx={{ marginX: 3, width: "20rem" }}
                                    value={document.activeElement.id === "floor" ? temp : props.data.floor}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("country").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("floor", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="floor"
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <div className=" font-semibold ">Accounting</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-col rounded-xl  py-1" id={"DiscountTab"}>
                            <div className="my-2 flex flex-row items-center justify-start text-[#c7c7c7]">
                                <div className="mx-3 text-lg">Financial Year</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateField
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        label="From"
                                        size="small"
                                        sx={{ maxWidth: 150, marginX: 2 }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("todate").focus();
                                            }
                                        }}
                                        value={dayjs(props.data.year_from)}
                                        onFocus={(e) => {
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onBlur={(e) => {
                                            if (temp !== e.target.value) {
                                                const [day, month, year] = e.target.value.toString().split("/");

                                                const transformedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
                                                    2,
                                                    "0"
                                                )}`;

                                                editGS("year_from", transformedDate);
                                            }
                                        }}
                                        format="DD/MM/YYYY"
                                        id="fromdate"
                                    />
                                    <DateField
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        label="To"
                                        size="small"
                                        sx={{ maxWidth: 150, marginX: 2 }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("fromdate").focus();
                                            }
                                        }}
                                        value={dayjs(props.data.year_to)}
                                        onFocus={(e) => {
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onBlur={(e) => {
                                            if (temp !== e.target.value) {
                                                const [day, month, year] = e.target.value.toString().split("/");

                                                const transformedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
                                                    2,
                                                    "0"
                                                )}`;

                                                editGS("year_to", transformedDate);
                                            }
                                        }}
                                        format="DD/MM/YYYY"
                                        id="todate"
                                        onChange={(newValue) => {}}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <div className=" font-semibold ">Other</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-col rounded-xl  py-1" id={"Other"}>
                            <div className="my-2 flex flex-row items-center justify-start text-[#c7c7c7]">
                                <div className="flex flex-row items-center">
                                    <div className=" mx-2 font-semibold">Use System Rate In POS:</div>
                                    <TextField
                                        InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        size="small"
                                        inputProps={{ className: "p-2" }}
                                        sx={{ marginX: 3, width: "5rem" }}
                                        value={document.activeElement.id === "utip" ? temp : props.data.use_system_rate_pos}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("email1").focus();
                                            }
                                        }}
                                        onBlur={(e) => {
                                            editGS("use_system_rate_pos", e.target.value === "" ? "" : "Y");
                                        }}
                                        onFocus={(e) => {
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            settemp(e.target.value);
                                        }}
                                        id="utip"
                                    />
                                </div>
                                <div className="flex flex-row items-center">
                                    <div className=" mx-2 font-semibold">POS Flip Cur:</div>
                                    <TextField
                                        InputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        InputLabelProps={{ sx: { color: "#c7c7c7 !important" } }}
                                        size="small"
                                        inputProps={{ className: "p-2" }}
                                        sx={{ marginX: 3, width: "5rem" }}
                                        value={props.data.pos_switch_cur === 0 ? "" : "Y"}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("pos_round_on").focus();
                                            }
                                        }}
                                        onFocus={(e) => {
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onChange={(e) => {
                                            editGS("pos_switch_cur", e.target.value === "" ? 0 : 1);
                                        }}
                                        id="pos_switch_cur"
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    inputProps={{ className: "p-2" }}
                                    label={"POS Rounding On"}
                                    sx={{ marginX: 3, width: "20rem" }}
                                    value={document.activeElement.id === "pos_round_on" ? temp : props.data.pos_round_on}
                                    onKeyDownCapture={(e) => {
                                        if (e.key === "Enter") {
                                            document.getElementById("pos_round_con").focus();
                                        }
                                    }}
                                    onBlur={(e) => {
                                        editGS("pos_round_on", e.target.value);
                                    }}
                                    onFocus={(e) => {
                                        settemp(e.target.value);
                                        e.target.select();
                                    }}
                                    onChange={(e) => {
                                        settemp(e.target.value);
                                    }}
                                    id="pos_round_on"
                                />
                                <div className="flex flex-row items-center">
                                    <FormControl sx={{ margin: 2, width: 218 }}>
                                        <InputLabel sx={{ color: "#c7c7c7 !important" }} id="demo-simple-select-label">
                                            POS Rounding Condition
                                        </InputLabel>
                                        <Select
                                            sx={{ color: "#c7c7c7 !important" }}
                                            MenuProps={{
                                                sx: { color: "#c7c7c7 !important" },
                                                PaperProps: {
                                                    style: {
                                                        backgroundColor: "#212529",
                                                    },
                                                },
                                            }}
                                            slotProps={{ sx: { color: "#c7c7c7 !important", backgroundColor: "#212529" } }}
                                            inputProps={{ sx: { color: "#c7c7c7 !important" } }}
                                            value={props.data.pos_round_con}
                                            label="POS Rounding Condition"
                                            id="pos_round_con"
                                            name="pos_round_con"
                                            onChange={(e, f) => {
                                                editGS("pos_round_con", e.target.value);
                                            }}
                                            size="small"
                                        >
                                            <MenuItem
                                                sx={{
                                                    color: "#c7c7c7 !important",
                                                    backgroundColor: "#212529",
                                                    ":hover": { backgroundColor: "#30353a" },
                                                }}
                                                value={"AUTO"}
                                            >
                                                {"AUTO"}
                                            </MenuItem>
                                            <MenuItem
                                                sx={{
                                                    color: "#c7c7c7 !important",
                                                    backgroundColor: "#212529",
                                                    ":hover": { backgroundColor: "#30353a" },
                                                }}
                                                value={"UP"}
                                            >
                                                {"UP"}
                                            </MenuItem>
                                            <MenuItem
                                                sx={{
                                                    color: "#c7c7c7 !important",
                                                    backgroundColor: "#212529",
                                                    ":hover": { backgroundColor: "#30353a" },
                                                }}
                                                value={"DOWN"}
                                            >
                                                {"DOWN"}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Box>
    );
    function editGS(field, value) {
        if (props.data[field] !== value) {
            props.setdata((old) => {
                let ttemp = { ...old };
                ttemp[field] = value;
                return ttemp;
            });
            let url = "settings/edit_general_settings/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                } else {
                    props.getSystemSettings();
                }
            };
            const fdata = { field: field, value: value };
            setTimeout(() => {
                props.postdata(url, cbnf, fdata, false);
            }, 200);
        }
    }
}
