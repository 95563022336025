import { useState, useEffect } from "react";
import AdvancedTable from "../../../app/AdvancedTable";
import Button from "../../../app/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { blurDoc, focusTo } from "../../../app/globalfunctions";
import TextField from "../../../app/TextField";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function ConfigurationPage({ selectedConf, postdata }) {
    const cols = [
        {
            field: "id",
            headerName: "Id",
            editable: false,
            width: 50,
        },
        {
            field: "name",
            headerName: "Name",
            editable: false,
            width: 120,
        },
        {
            field: "is_active",
            headerName: "Is Active",
            editable: true,
            width: 70,
        },
        {
            field: "for_client",
            headerName: "For Client",
            editable: true,
            width: 70,
        },
        {
            field: "filter",
            headerName: "Filter",
            editable: true,
            width: 120,
        },
        {
            field: "silent",
            headerName: "Silent",
            editable: true,
            width: 60,
        },
        {
            field: "print_background",
            headerName: "Print BG",
            editable: true,
            width: 80,
        },
        {
            field: "margins",
            headerName: "Margins",
            editable: true,
            width: 200,
        },
        {
            field: "landscape",
            headerName: "Landscape",
            editable: true,
            width: 80,
        },
        {
            field: "scale_factor",
            headerName: "Scale Factor",
            editable: true,
            width: 80,
        },
        {
            field: "pages_per_sheet",
            headerName: "Pages Per Sheet",
            editable: true,
            width: 90,
        },
        {
            field: "copies",
            headerName: "Copies",
            editable: true,
            width: 80,
        },
        {
            field: "duplex_mode",
            headerName: "Duplex Mode",
            editable: true,
            width: 100,
        },
        {
            field: "dpi",
            headerName: "DPI",
            editable: true,
            width: 60,
        },
        {
            field: "page_size",
            headerName: "Page Size",
            editable: true,
            width: 150,
        },
        {
            field: "header",
            headerName: "Header",
            editable: true,
            width: 350,
        },
        {
            field: "footer",
            headerName: "Footer",
            editable: true,
            width: 350,
        },
    ];

    useEffect(() => {
        getPrinters();
    }, []);

    const [data, setdata] = useState({
        config: {
            id: 0,
            name: "",
            row_count: 0,
            column_count: 0,
        },
        items: [],
    });
    const [id, setid] = useState("");
    const [name, setname] = useState("");

    const [printerList, setprinterList] = useState([]);
    const commonAttr = {
        onFocus: (e) => {
            e.target.select();
        },
    };
    const fields = {
        id: {
            size: "small",
            label: "Id",
            sx: { width: 150 },
            value: id,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    focusTo("name");
                }
            },
            onChange: (e) => {
                setid(e.target.value);
            },
            id: "id",
        },
        name: {
            size: "small",
            label: "Name",
            sx: { width: 250 },
            value: name,
            onKeyDownCapture: (e) => {
                if (e.key === "Enter") {
                    blurDoc();
                }
            },
            onChange: (e) => {
                setname(e.target.value);
            },
            id: "name",
        },
    };
    return (
        <div className="flex h-full w-full flex-col ">
            <div className="flex h-[80%] flex-row gap-1 p-1 ">
                <div className="h-full w-[80%] ">
                    <DataGrid
                        rows={data}
                        columns={cols}
                        getRowId={(e) => {
                            return e.id + "-" + e.config_id;
                        }}
                        onFieldEdit={editPrinter}
                    />
                </div>
                <Box
                    className="flex h-full w-[20%] flex-col rounded "
                    sx={{ border: "2px solid", borderColor: "system.borderColor" }}
                >
                    <Box
                        sx={{ borderBottom: "2px solid", borderColor: "system.borderColor" }}
                        className=" p-2 text-xl font-semibold "
                    >
                        System Printers List
                    </Box>
                    <div className="flex h-full w-full flex-col overflow-y-auto overflow-x-hidden">
                        {printerList.length !== 0 ? (
                            <>
                                {printerList.map((el, idx) => {
                                    return (
                                        <Box
                                            key={idx}
                                            sx={{ borderColor: "system.borderColor" }}
                                            className=" text-wrap border-y-2 p-2 font-semibold "
                                        >
                                            <div>'Name' : {el.Name}</div>
                                            <div>'Caption' : {el.Caption}</div>
                                            <div>'DeviceID' : {el.DeviceID}</div>
                                            <div>'DriverName' : {el.DriverName}</div>
                                            <div>'PortName' : {el.PortName}</div>
                                            <div>'Location' : {el.Location}</div>
                                            <div>'Default' : {el.Default ? "true" : "false"}</div>
                                            <div>'DriverName' : {el.DriverName}</div>
                                            <div>'PrintProcessor' : {el.PrintProcessor}</div>
                                            <div>'Network' : {el.Network ? "true" : "false"}</div>
                                            <div>'DetectedErrorState' : {el.DetectedErrorState}</div>
                                        </Box>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="flex h-full w-full flex-row items-center justify-center">
                                <Button onClick={getSystemPrinters}>Get System Printers</Button>
                            </div>
                        )}
                    </div>
                </Box>
            </div>
            <div className="flex h-[20%] w-full flex-col pb-1 ">
                <Box
                    sx={{ backgroundColor: "accordions.primaryBG" }}
                    className="flex h-full w-full flex-col  rounded-lg p-2"
                >
                    <div className="flex h-full w-full flex-col overflow-y-auto  ">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className=" font-semibold ">Add Printer</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="flex flex-row gap-2 rounded-xl  py-1" id={"addPrinter"}>
                                    <TextField {...commonAttr} {...fields.id} />
                                    <TextField {...commonAttr} {...fields.name} />
                                    <Button onClick={addPrinter}>Add Printer</Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Box>
            </div>
        </div>
    );
    function getPrinters() {
        let url = "settings/get_printers/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data);
            }
        };

        let fdata = {
            config_id: selectedConf,
        };

        postdata(url, cbnf, fdata, false);
    }
    function getSystemPrinters() {
        let url = "core/get_system_printers/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setprinterList(res.data.data);
            }
        };

        let fdata = {
            config_id: selectedConf,
        };

        postdata(url, cbnf, fdata, false);
    }
    function editPrinter(selectedRow, newValue) {
        let url = "settings/edit_printer/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
            } else {
                getPrinters();
            }
        };
        const fdata = {
            selectedRow: selectedRow,
            newValue: newValue,
        };
        postdata(url, cbnf, fdata, false);
    }
    function addPrinter() {
        let url = "settings/add_printer/";
        const cbnf = (res) => {
            getPrinters();
        };
        const fdata = {
            config_id: selectedConf,
            printer_id: id,
            name: name,
        };
        postdata(url, cbnf, fdata, true);
    }
}
