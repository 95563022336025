import { useNavigate } from "react-router-dom";
import AdvancedTable from "../../app/AdvancedTable";
import React from "react";

export default function DataTable(props) {
    const navigate = useNavigate();

    const columns = [
        {
            field: "inv_date",
            headerName: "Inv Date",
            width: 120,
            editable: false,
        },
        {
            field: "inv_type",
            headerName: "Inv Type",
            width: 80,
            editable: false,
        },
        {
            field: "num",
            headerName: "Inv Num",
            width: 70,
            editable: false,
        },
        {
            field: "qty",
            headerName: "Qty",
            width: 80,
            editable: false,
            type: "number",
        },
        {
            field: "price",
            headerName: "Price",
            width: 120,
            editable: false,
            type: "number",
            valueGetter: (params) => {
                return params.value + " " + params.row.symbol;
            },
        },
        {
            field: "discount",
            headerName: "Disc %",
            width: 80,
            editable: false,
        },
        {
            field: "expences",
            headerName: "Exp %",
            width: 80,
            editable: false,
        },
        {
            field: "tax",
            headerName: "Tax %",
            width: 70,
            editable: false,
        },
        {
            field: "rate",
            headerName: "Rate",
            width: 80,
            editable: false,
            valueGetter: (params) => {
                return (parseFloat(params.row.pri_rate) === 1 ? params.row.sec_rate : params.row.pri_rate).toLocaleString();
            },
        },

        {
            field: "branch",
            headerName: "Br.",
            width: 60,
            editable: false,
        },
        {
            field: "sman",
            headerName: "Sman.",
            width: 100,
            editable: false,
        },
        {
            field: "job",
            headerName: "Job",
            width: 150,
            editable: false,
        },
        {
            field: "note",
            headerName: "Note",
            width: 250,
            editable: false,
        },
    ];

    return (
        <>
            <AdvancedTable
                rows={props.rows}
                cols={columns}
                getRowClassName={(e) => {
                    if (e.row.line_color === "green") {
                        return " text-green-500  ";
                    } else if (e.row.line_color === "orange") {
                        return "  text-orange-500   ";
                    } else {
                        return "  text-red-500   ";
                    }
                }}
                columnHeaderHeight={40}
                rowHeight={30}
                getRowId={(e) => {
                    return e.itemcode.toString() + e.id.toString() + e.line.toString();
                }}
                onCellDoubleClick={(e) => {
                    if (e.field === "inv_type") {
                        props.setTempHide(true);

                        window.localStorage.setItem("useTemp", "true");
                        window.localStorage.setItem("tType", e.value);
                        window.localStorage.setItem("tNum", e.row.num);

                        window.localStorage.setItem("statementofitem", "true");
                        window.localStorage.setItem("itemcode", e.row.itemcode);
                        if (e.value[0] === "P") {
                            navigate("/purchase");
                        } else if (e.value[0] === "S") {
                            navigate("/sales");
                        } else {
                            navigate("/proforma");
                        }
                    } else if (e.field === "itemcode") {
                        props.settempItemCode(e.value);
                        props.setItemCardModal(true);
                    }
                }}
            />
        </>
    );
}
