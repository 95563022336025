import React, { useEffect, useState } from "react";
import Modal from "../../../../app/Modal";
import StatementOfItem from "../../../../../Pages/BackOffice/StatementOfItem";
import Select from "../../../../app/Select";
import XButton from "../../../../app/XButton";
import Button from "../../../../app/Button";
import TextField from "../../../../app/TextField";
import ConfirmModal from "../../../../app/ConfirmModal";
import ItemCardTabs from "./ItemCardTabs";

export default function ItemCard(props) {
    const [data, setdata] = useState({
        itemcode: "",
        originalnumber: "",
        itemname1: "",
        itemname2: "",
        tax: 0,
        sale1: 0,
        sale2: 0,
        sale3: 0,
        sale4: 0,
        disc1: 0,
        disc2: 0,
        disc3: 0,
        disc4: 0,
        qty_in: 1,
        qty_out: 1,
        category: "",
        set: "",
        brand: "",
        supplier: "",
        size: "",
        color: "",
        unit: "",
        type: "",
        family: "",
        note: "",
        tax_included: "",
        final_sale1: 0,
        final_sale2: 0,
        final_sale3: 0,
        final_sale4: 0,
        mul_rate: 1,
        web_url: "",
        image_url: "",
        image_url2: "",
        image_url3: "",
    });

    const [otherCodeData, setOtherCodeData] = useState([]);

    const [attributes, setattributes] = useState({
        Categories: [],
        Sets: [],
        Brands: [],
        Colors: [],
        Units: [],
        Families: [],
        Types: [],
    });

    const [showconfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState({});

    const [activeElementId, setactiveElementId] = useState({});

    const [temp, settemp] = useState("");

    const [showStatementOfItem, setshowStatementOfItem] = useState(false);

    useEffect(() => {
        getItemAttributesTables();
        getItemByCode(props.itemcode);
    }, []);
    return (
        <>
            <Modal
                show={props.modalShow}
                setshow={() => {
                    props.setModalShow(false);
                    props.onHideFunc();
                }}
                height="100vh"
                width="100vw"
                borderRadius="0.2rem"
                defaultHeader={false}
                CustomHeader={
                    <div className="flex w-full flex-row justify-between">
                        <div className=" text-3xl font-semibold">Item Card </div>
                        <div className="flex flex-row">
                            <Button
                                sx={{ marginX: 2 }}
                                variant="outlined"
                                onClick={(e) => {
                                    setshowStatementOfItem(true);
                                }}
                            >
                                Statement Of Item
                            </Button>
                        </div>
                        <div>
                            <XButton
                                onClickFunc={() => {
                                    props.setModalShow(false);
                                    props.onHideFunc();
                                }}
                            />
                        </div>
                    </div>
                }
                id="itemCard"
            >
                <div className="flex h-[99%] w-[100%] flex-col items-center">
                    <>
                        <div className="flex h-[60%] w-full flex-col items-center  overflow-auto ">
                            <div className="mt-2 flex flex-row">
                                <TextField disabled id="1" label="ITEM CODE" value={data.itemcode} />
                            </div>
                            <div>
                                <div>
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            e.target.select();
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("itemname1").focus();
                                            }
                                        }}
                                        autoFocus
                                        label="ORIGINAL NUMBER"
                                        value={data.originalnumber}
                                        id="originalnumber"
                                        onChange={(e) => {
                                            editItem(e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        onFocus={(e) => {
                                            e.target.select();
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                        }}
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("itemname2").focus();
                                            }
                                        }}
                                        label="ITEM NAME1"
                                        value={data.itemname1}
                                        id="itemname1"
                                        onChange={(e) => {
                                            editItem(e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        onFocus={(e) => {
                                            e.target.select();
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                        }}
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("tax").focus();
                                            }
                                        }}
                                        label="ITEM NAME 2"
                                        value={data.itemname2}
                                        id="itemname2"
                                        onChange={(e) => {
                                            editItem(e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        onFocus={(e) => {
                                            e.target.select();
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                        }}
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("size").focus();
                                            }
                                        }}
                                        label="TAX"
                                        type="number"
                                        value={data.tax}
                                        id="tax"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        onFocus={(e) => {
                                            e.target.select();
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                        }}
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("sale1").focus();
                                            }
                                        }}
                                        label="SIZE "
                                        value={data.size}
                                        id="size"
                                        onChange={(e) => {
                                            editItem(e.target.value, e.target.id, false);
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("sale2").focus();
                                            }
                                        }}
                                        label="SALE 1"
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);

                                            setTimeout(() => {
                                                e.target.select();
                                            }, 100);
                                        }}
                                        value={activeElementId === "sale1" ? data.sale1 : data.sale1.toLocaleString()}
                                        id="sale1"
                                        type={activeElementId === "sale1" ? "number" : "string"}
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);

                                            setTimeout(() => {
                                                e.target.select();
                                            }, 100);
                                        }}
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("sale3").focus();
                                            }
                                        }}
                                        label="SALE 2"
                                        value={activeElementId === "sale2" ? data.sale2 : data.sale2.toLocaleString()}
                                        id="sale2"
                                        type={activeElementId === "sale2" ? "number" : "string"}
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);

                                            setTimeout(() => {
                                                e.target.select();
                                            }, 100);
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("sale4").focus();
                                            }
                                        }}
                                        label="SALE 3"
                                        value={activeElementId === "sale3" ? data.sale3 : data.sale3.toLocaleString()}
                                        id="sale3"
                                        type={activeElementId === "sale3" ? "number" : "string"}
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);

                                            setTimeout(() => {
                                                e.target.select();
                                            }, 100);
                                        }}
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("tax_included").focus();
                                            }
                                        }}
                                        label="SALE 4"
                                        value={activeElementId === "sale4" ? data.sale4 : data.sale4.toLocaleString()}
                                        id="sale4"
                                        type={activeElementId === "sale4" ? "number" : "string"}
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value === "" ? "" : "Y", e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("disc1").focus();
                                            }
                                        }}
                                        label="Sale Prices Include Tax"
                                        value={data.tax_included === "" ? "" : "Y"}
                                        id="tax_included"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? "" : "Y", e.target.id, true);
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("disc2").focus();
                                            }
                                        }}
                                        label="DISC 1"
                                        value={data.disc1}
                                        id="disc1"
                                        type="number"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("disc3").focus();
                                            }
                                        }}
                                        label="DISC 2"
                                        value={data.disc2}
                                        id="disc2"
                                        type="number"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("disc4").focus();
                                            }
                                        }}
                                        label="DISC 3"
                                        value={data.disc3}
                                        id="disc3"
                                        type="number"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("mul_rate").focus();
                                            }
                                        }}
                                        label="DISC 4"
                                        value={data.disc4}
                                        id="disc4"
                                        type="number"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            margin: 2,

                                            borderRadius: "7px",

                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("disc1").focus();
                                            }
                                        }}
                                        label="Multiply Price By Inv. Rate"
                                        value={data.mul_rate}
                                        id="mul_rate"
                                        onChange={(e) => {
                                            editItem(e.target.value, e.target.id, false);
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        sx={{ margin: 2, marginX: 4 }}
                                        label="FINAL SALE 1"
                                        disabled
                                        value={data.final_sale1.toLocaleString()}
                                        id="sale1"
                                        type={"string"}
                                    />
                                    <TextField
                                        sx={{ margin: 2, marginX: 4 }}
                                        label="FINAL SALE 2"
                                        disabled
                                        value={data.final_sale2.toLocaleString()}
                                        id="sale2"
                                        type={"string"}
                                    />
                                    <TextField
                                        sx={{ margin: 2, marginX: 4 }}
                                        label="FINAL SALE 3"
                                        disabled
                                        value={data.final_sale3.toLocaleString()}
                                        id="sale3"
                                        type={"string"}
                                    />
                                    <TextField
                                        sx={{ margin: 2, marginX: 4 }}
                                        label="FINAL SALE 4"
                                        disabled
                                        value={data.final_sale4.toLocaleString()}
                                        id="sale4"
                                        type={"string"}
                                    />
                                </div>
                                <div className="flex w-full">
                                    <TextField
                                        sx={{
                                            flex: 1,
                                            margin: 2,
                                            borderRadius: "7px",
                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            settemp(e.target.value);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("image_url").focus();
                                            }
                                        }}
                                        label="Web Url"
                                        value={data.web_url}
                                        id="web_url"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            flex: 1,
                                            margin: 2,
                                            borderRadius: "7px",
                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("mul_rate").focus();
                                            }
                                        }}
                                        label="Image Url"
                                        value={data.image_url}
                                        id="image_url"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            flex: 1,
                                            margin: 2,
                                            borderRadius: "7px",
                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("mul_rate").focus();
                                            }
                                        }}
                                        label="Image Url 2"
                                        value={data.image_url2}
                                        id="image_url2"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                    <TextField
                                        sx={{
                                            flex: 1,
                                            margin: 2,
                                            borderRadius: "7px",
                                            marginX: 4,
                                        }}
                                        onBlur={(e) => {
                                            editItem(e.target.value, e.target.id, true);
                                            setactiveElementId("");
                                        }}
                                        onFocus={(e) => {
                                            setactiveElementId(e.target.id);
                                            e.target.select();
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                document.getElementById("mul_rate").focus();
                                            }
                                        }}
                                        label="Image Url 3"
                                        value={data.image_url3}
                                        id="image_url3"
                                        onChange={(e) => {
                                            editItem(e.target.value === "" ? 0 : e.target.value, e.target.id, false);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" h-[40vh] w-[100%] rounded-xl ">
                            <ItemCardTabs
                                otherCodeData={otherCodeData}
                                //updateItemData={updateItemData}
                                postdata={props.postdata}
                                maincode={data.itemcode}
                                ItemProperties={ItemProperties}
                                columns={props.columns}
                                attributes={attributes}
                                setOtherCodeData={setOtherCodeData}
                                getItemByCode={getItemByCode}
                                type={data.type}
                                setModalShow={(f) => {
                                    props.setModalShow(f);
                                    props.onHideFunc();
                                }}
                            />
                        </div>
                        {showconfirmModal ? (
                            <ConfirmModal
                                show={showconfirmModal}
                                handleClose={setShowConfirmModal}
                                data={confirmModalData}
                                //callBackFun={createNewItem}
                            />
                        ) : null}
                    </>
                </div>
            </Modal>

            {showStatementOfItem ? (
                <StatementOfItem
                    show={showStatementOfItem}
                    setshow={setshowStatementOfItem}
                    postdata={props.postdata}
                    defaultItemCode={data.itemcode}
                />
            ) : null}
        </>
    );
    function ItemProperties(props) {
        const selectItems = ["category", "set", "brand", "type", "unit", "color", "family"];
        const selectRows = [];

        // Split the select items into rows of 4
        for (let i = 0; i < selectItems.length; i += 4) {
            selectRows.push(selectItems.slice(i, i + 4));
        }

        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {selectRows.map((row, index) => (
                    <div key={index} style={{ display: "flex", flexWrap: "wrap" }}>
                        {row.map((el) => {
                            let optionsName = el.charAt(0).toUpperCase() + el.slice(1) + "s";
                            if (el === "category") {
                                optionsName = "Categories";
                            } else if (el === "family") {
                                optionsName = "Families";
                            }
                            return (
                                <Select
                                    key={el}
                                    label={el.toUpperCase()}
                                    id={el}
                                    name={el}
                                    onChange={(e, f) => {
                                        editItem(e.target.value, el, true);
                                    }}
                                    size="large"
                                    options={attributes[optionsName]}
                                    menuValue={"id"}
                                    width={220}
                                    value={data[el]}
                                    menuLabels={["id", "name"]}
                                    style={{ marginRight: "1rem", marginBottom: "1rem" }}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }

    function getItemByCode(itemCode) {
        let url = "goods/get_items/";

        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata((old) => {
                    let temp = { ...old };
                    res.data.data.goods.forEach((el) => {
                        url = "goods/get_items/";
                        const cbnf1 = (res) => {
                            setOtherCodeData(res.data.data.goods);
                        };
                        if (el.type === "Otherbarcode") {
                            fdata = { filters: [` and itemcode = '${el.maincode}' and type != 'Otherbarcode' `] };
                        } else {
                            fdata = { filters: [` and maincode = '${itemCode}' and type = 'Otherbarcode' `] };
                        }
                        props.postdata(url, cbnf1, fdata, false);
                        temp = el;
                    });
                    return temp;
                });
            }
        };
        let fdata = { filters: [` and itemcode = '${itemCode}' `] };
        props.postdata(url, cbnf, fdata, false);
    }

    function editItem(value, field, confirm) {
        if (confirm) {
            if (parseFloat(value).toLocaleString() === temp) {
                settemp("");
            }
            if (data[field] !== value || data[field] !== temp) {
                let url = "goods/edit_item/";
                const cbnf = (res) => {
                    getItemByCode(data.itemcode);
                };
                const fdata = {
                    id: data.itemcode,
                    field: field,
                    value: value,
                };
                props.postdata(url, cbnf, fdata, false);
            }
        } else {
            setdata((old) => {
                let temp = { ...old };
                temp[field] = value;
                return temp;
            });
        }
    }

    function getItemAttributesTables() {
        let url = "goods/get_item_attributes_tables/";
        const cbnf1 = (res) => {
            if (res.data.info === "success") {
                setattributes(res.data.data);
            }
        };

        props.postdata(url, cbnf1, { filters: [] }, false);
    }
}
