import React, { useState, useEffect } from "react";
import Button from "../../components/app/Button";
import CButton from "../../components/app/CButton";
import { useNavigate } from "react-router-dom";
import ItemCard from "../../components/BackOffice/Goods/itemCard/main/ItemCard";
import ItemAttributesModal from "../../components/BackOffice/Goods/ItemAttributes/ItemAttributesModal";
import CreateNewItemModal from "../../components/BackOffice/Goods/itemCard/createNewItem/CreateNewItemModal";
import QuickEditModal from "../../components/BackOffice/Goods/goodsTable/QuickEditModal";
import ScaleConfig from "../../components/BackOffice/Goods/ScaleConfig/ScaleConfig";
import SearchItemModal from "../../components/BackOffice/Goods/SearchItem";
import InventoryManagmentModal from "../../components/BackOffice/InventoryManagment/InventoryManagmentModal";
import Box from "@mui/material/Box";
import AdvancedTable from "../../components/app/AdvancedTable";
import { useLanguage } from "../../components/app/LanguageContext";
import DataTable from "../../components/app/DataTable";
import GoodsSyncModal from "../../components/BackOffice/Goods/GoodsSync/GoodsSyncModal";
import LabelExportModal from "../../components/BackOffice/Goods/LabelExport/LabelExportModal";
import MultiEditModal from "../../components/BackOffice/Goods/MultiEditModal/MultiEditModal";

export const columns = [
    { field: "itemcode", headerName: "Item Code", width: 150 },
    {
        field: "itemname1",

        headerName: "Item Name 1",
        width: 300,
        editable: true,
    },
    {
        field: "originalnumber",

        headerName: "Original Number",
        width: 150,
        editable: true,
    },

    {
        field: "tax",

        headerName: "Tax",
        description: "Item Tax ",
        width: 70,
        editable: true,
        valueFormatter: (params) => {
            if (!params.value) {
                return "";
            } else {
                return params.value + "%";
            }
        },
    },

    {
        field: "sale1",

        type: "number",
        headerName: "Sale 1",
        width: 120,
        editable: true,
        valueFormatter: (params) => {
            if (params.value == null) {
                return "";
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted}`;
        },
    },

    {
        field: "sale2",

        type: "number",
        headerName: "Sale 2",
        width: 120,
        editable: true,
        valueFormatter: (params) => {
            if (params.value == null) {
                return "";
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted}`;
        },
    },

    {
        field: "sale3",

        type: "number",
        headerName: "Sale 3",
        width: 120,
        editable: true,
        valueFormatter: (params) => {
            if (params.value == null) {
                return "";
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted}`;
        },
    },

    {
        field: "sale4",

        type: "number",
        headerName: "Sale 4",
        width: 120,
        editable: true,
        valueFormatter: (params) => {
            if (params.value == null) {
                return "";
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted}`;
        },
    },
    {
        field: "tax_included",

        headerName: "Tax Included",
        description: "Y is Taxed, Empty is not Taxed ",
        width: 70,
        editable: true,
        valueFormatter: (params) => {
            if (params.value == null) {
                return "";
            }
            if (params.value) {
                return "Y";
            } else {
                return "N";
            }
        },
    },

    {
        field: "itemname2",

        description: "itemname 2",
        headerName: "Item Name 2",
        width: 200,
        editable: true,
    },
    {
        field: "qty_in",

        description: "Defualt Quantity in",
        headerName: "Qty In",
        type: "number",
        width: 100,
        editable: true,
    },
    {
        field: "qty_out",

        description: "Defualt Quantity out",
        headerName: "Qty Out",
        type: "number",
        width: 100,
        editable: true,
    },
    {
        field: "type",

        description: "types",
        headerName: "Type",
        width: 150,
        editable: false,
    },

    {
        field: "category",

        description: "categories",
        headerName: "Category",
        width: 100,
        editable: false,
    },

    {
        field: "set",

        description: "sets",
        headerName: "Set",
        width: 100,
        editable: false,
    },

    {
        field: "brand",

        description: "brands",
        headerName: "Brand",
        width: 100,
        editable: false,
    },

    {
        field: "color",

        description: "colors",
        headerName: "Color",
        width: 100,
        editable: false,
    },

    {
        field: "unit",

        description: "units",
        headerName: "Unit",
        width: 100,
        editable: false,
    },

    {
        field: "family",

        description: "families",
        headerName: "Family",
        width: 100,
        editable: false,
    },
    {
        field: "notes",

        description: "Notes",
        headerName: "Notes",
        width: 300,
        editable: true,
    },
];

export default function Goods(props) {
    const navigate = useNavigate();
    const { lang } = useLanguage();

    const lists = props.settings.lists;
    const [data, setdata] = useState(props.settings.items.goods);
    const [goods_count, setgoods_count] = useState(props.settings.items.count);
    const [selectedPage, setselectedPage] = useState(1);

    const [attributes, setattributes] = useState({
        Sets: lists.sets,
        Categories: lists.categories,
        Units: lists.units,
        Brands: lists.brands,
        Colors: lists.colors,
        Families: lists.families,
        Types: lists.types,
    });

    const [itemCardModal, setItemCardModal] = useState(false);
    const [showQuickItemEdit, setshowQuickItemEdit] = useState(false);

    const [selectedRow, setselectedRow] = useState({});

    const [showInventoryManagmentModal, setshowInventoryManagmentModal] = useState(false);

    document.title = " PYX Technologies - BackOffice - Goods";

    useEffect(() => {
        // fetchItems();
        // getConfig();
        const keyDownHandler = (event) => {
            if (event.key === "F12") {
                event.preventDefault();
                if (document.getElementById("itemCard")) {
                    setItemCardModal(false);
                    fetchItems();
                } else if (document.getElementById("scaleConfig")) {
                } else if (document.getElementById("SearchItemModal")) {
                } else {
                    navigate(window.localStorage.getItem("exitTo"));
                }
            } else if (event.key === "F1") {
                document.getElementById("CreateNewItemButton").click();
            } else if (event.key === "Escape") {
                if (document.activeElement.tagName !== "BODY") {
                    document.activeElement.blur();
                } else {
                    setItemCardModal(false);
                }
            } else if (event.key === "F9") {
                document.getElementById("PageSettingsButton").click();
            }
        };
        document.addEventListener("keydown", keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);
    return (
        <>
            <div className=" h-[100%]">
                <div className=" h-[80%] p-1 ">
                    <Box sx={{ height: "100%", width: "100%" }}>
                        <DataTable
                            rows={data}
                            columns={columns}
                            headerHeight={40}
                            rowCount={goods_count}
                            selectedPage={selectedPage}
                            setselectedPage={(e) => {
                                fetchItems(e);
                                setselectedPage(e);
                            }}
                            rowHeight={35}
                            getRowId={(e) => {
                                return e.itemcode;
                            }}
                            onCellDoubleClick={(e) => {
                                quickEditHandler(e);
                            }}
                            experimentalFeatures={{ newEditingApi: true }}
                            setSelectedRow={setselectedRow}
                            onPaginationModelChange={(e) => {
                                if (e.page * 100 >= data.length) {
                                    fetchItems(e.page + 1);
                                }
                            }}
                            onCellFocusOut={() => {}}
                            processRowUpdate={(e) => {
                                editItem(e[selectedRow.field]);
                                return e;
                            }}
                            onProcessRowUpdateError={() => {
                                fetchItems();
                            }}
                            disableColumnSelector
                            onCellKeyDown={(e, f) => {
                                if (f.key === "Enter") {
                                    quickEditHandler(e);
                                    if (f.target.id !== "datagridcell") {
                                        f.nativeEvent = new KeyboardEvent("keydown", { key: "Tab" });
                                        f.key = "Tab";
                                        f.code = "Tab";
                                        document.activeElement.dispatchEvent(new KeyboardEvent("keydown", { key: "Tab" }));
                                    }
                                    return f;
                                } else if (f.key === "F2") {
                                    setselectedRow(e);

                                    // setItemCardModal(true);
                                }
                            }}
                        />
                    </Box>
                </div>
                <div className=" flex h-[20%] flex-row">
                    <div className="my-2 flex flex-row flex-wrap items-start  gap-3 px-2 align-middle  ">
                        <Button
                            onClick={() => {
                                navigate(window.localStorage.getItem("exitTo"));
                            }}
                            color="black"
                        >
                            {lang["BACK"]} - f12
                        </Button>

                        <CreateNewItemModal
                            postdata={props.postdata}
                            fetchItems={fetchItems}
                            setselectedRow={setselectedRow}
                            setShowItemCard={itemCardModalHandler}
                        />
                        <SearchItemModal setselectedRow={setselectedRow} postdata={props.postdata} itemCardModalHandler={itemCardModalHandler} />
                        <ItemAttributesModal postdata={props.postdata} fetchItems={fetchItems} />
                        <ScaleConfig postdata={props.postdata} />
                        <GoodsSyncModal />
                        <LabelExportModal />
                        <MultiEditModal />
                        {/* <Button
                            id="ScaleConfigButton"
                            onClick={() => {
                                // setshowInventoryManagmentModal(true);
                            }}
                        >
                            Inventory Managment
                        </Button> */}
                    </div>
                </div>
            </div>

            {itemCardModal ? (
                <ItemCard
                    modalShow={itemCardModal}
                    setModalShow={itemCardModalHandler}
                    postdata={props.postdata}
                    itemcode={selectedRow.row.itemcode}
                    columns={columns}
                    onHideFunc={fetchItems}
                />
            ) : null}

            {showQuickItemEdit ? (
                <QuickEditModal
                    show={showQuickItemEdit}
                    setShow={setshowQuickItemEdit}
                    postdata={props.postdata}
                    selectedRow={selectedRow}
                    attributes={attributes}
                    editItem={editItem}
                    setselectedRow={setselectedRow}
                />
            ) : null}
            {showInventoryManagmentModal ? (
                <InventoryManagmentModal show={showInventoryManagmentModal} setshow={setshowInventoryManagmentModal} postdata={props.postdata} />
            ) : null}
        </>
    );

    function fetchItems(selectedPage = 1) {
        let url = "goods/get_goods/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setdata(res.data.data.goods);
                // if (getJsonStorage("lastGoodsSyncDate")) {
                //     let new_goods = res.data.data;
                //     let old_goods = getJsonStorage("goods");
                //     new_goods.forEach((nl) => {
                //         old_goods.forEach((ol, idx) => {
                //             if (nl.itemcode === ol.itemcode) {
                //                 old_goods[idx] = nl;
                //                 return false
                //             }
                //         });
                //     });
                // } else {
                //     localStorage.setItem("goods", JSON.stringify(res.data.data));
                //     setdata(res.data.data);
                // }
            }
        };

        const fdata = {
            filters: {},
            limit: selectedPage * 100,
            orderby: "last_modified",
            ordertype: "desc",
            page: parseInt(selectedPage),
        };

        props.postdata(url, cbnf, fdata, false);
    }
    function itemCardModalHandler(e) {
        setItemCardModal(e);
        if (!e) {
            fetchItems();
        }
    }

    function editItem(newval) {
        let url = "goods/edit_item/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setshowQuickItemEdit(false);
                setdata((tdata) => {
                    let temp = [...tdata];
                    tdata.forEach((el, idx) => {
                        if (el.itemcode === selectedRow.row.itemcode) {
                            temp[idx] = res.data.item;
                        }
                        return temp;
                    });
                    return temp;
                });
                if (showQuickItemEdit) {
                }
            } else {
                fetchItems();
            }
        };
        const data = {
            id: selectedRow.row.itemcode,
            field: selectedRow.field,
            value: newval,
        };
        props.postdata(url, cbnf, data, false);
    }
    function quickEditHandler(e) {
        setselectedRow(e);
        if (e.field === "itemcode") {
            setItemCardModal(true);
        } else if (["type", "category", "set", "brand", "color", "unit", "family"].includes(e.field)) {
            setshowQuickItemEdit(true);
        }
    }
}
