import { Button as MUIButton } from "@mui/material";
import React from "react";
import { useLanguage } from "./LanguageContext";
import { ExtendButtonBase } from "@mui/material/ButtonBase";
import { ButtonTypeMap } from "@mui/material";

export default function Button({
    variant = "outlined",
    onClick = () => {},
    size = "small",
    children,
    theme = "primary",
    color = "black",
    sx = {},
    minWidth = "7rem",
    disabled = false,
    id = "",
    ...props
}: any | ExtendButtonBase<ButtonTypeMap>) {
    if (theme === "success") {
        color = "";
    }
    if (theme === "error") {
        color = "";
    }
    let tempSx = {};
    if (variant === "outlined") {
        tempSx = {
            color: `button.bgColor.${color}`,
            borderColor: `button.bgColor.${color}`,
            ":hover": { backgroundColor: "#00000017", borderWidth: "2px", borderColor: `button.bgColor.${color}` },
        };
    } else if (variant === "contained") {
        tempSx = { color: `button.color.primary`, backgroundColor: `button.bgColor.blue` };
    }
    const { lang } = useLanguage();
    const fontFamily = (lang) => {
        if (lang === "en") {
            return "revert";
        } else if (lang === "ar") {
            return "system-ui";
        }
    };
    return (
        <MUIButton
            id={id}
            variant={variant}
            size={size}
            color={theme}
            onClick={onClick}
            sx={{
                ...sx,
                minWidth: minWidth,
                ...tempSx,
                fontFamily: fontFamily(lang.lang),
                fontWeight: "500",
                borderWidth: "2px",
            }}
            disabled={disabled}
        >
            {children}
        </MUIButton>
    );
}
